export const addToLocalStorage = (name = 'aisync', data = {}) => {
  const stringifiedData = JSON.stringify(data);

  if (DESKTOP_APP) {
    window.electronStore?.set?.(name, stringifiedData);

    return;
  }

  localStorage.setItem(name, stringifiedData);
};

export const removeFromLocalStorage = (name = 'aisync') => {
  if (DESKTOP_APP) {
    window.electronStore?.delete?.(name);

    return;
  }

  localStorage.removeItem(name);
};

export const getFromLocalStorage = (name = 'aisync') => {
  let data = localStorage.getItem(name);

  if (DESKTOP_APP) {
    data = window.electronStore?.get?.(name);
  }

  if (!data) return;

  try {
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (e) {
    return data;
  }
};
