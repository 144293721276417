import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StatsigClient } from '@statsig/js-client';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';
import usePrevious from '@app/hooks/usePrevious';
import { getStatsigKey } from '@app/selectors/configSelectors';
import environments from '@utils/environment';
import { datadogRum } from '@datadog/browser-rum';

export const FeatureFlagContext = createContext(null);

const environmentMappings = {
  development: 'development',
  test: 'staging',
  production: 'production',
};

export default function FeatureFlagProvider({ children }) {
  const currentUser = useSelector(getCurrentUser());
  const statsigKey = useSelector(getStatsigKey());
  const [client, setClient] = useState(null);
  const statsigUser = useMemo(() => {
    return {
      userID: currentUser.id,
      custom: {
        organisationId: currentUser.organizationId,
      },
      customIDs: {
        organisationId: currentUser.organizationId,
      },
    };
  }, [currentUser.id, currentUser.organizationId]);
  const previousUser = usePrevious(statsigUser);

  const onGateEvaluation = useCallback(({ gate }) => {
    if (datadogRum.getInitConfiguration() !== undefined) {
      datadogRum.addFeatureFlagEvaluation(gate.name, gate.value);
    }
  }, []);

  useEffect(() => {
    if (!statsigKey || DESKTOP_APP) return;

    if (statsigUser !== previousUser) {
      if (!client) {
        const client = new StatsigClient(statsigKey, statsigUser, {
          disableLogging: false,
          environment: { tier: environmentMappings[environments.current] },
        });
        client.on('gate_evaluation', onGateEvaluation);
        setClient(client);
        client.initializeAsync();
      } else {
        client.updateUserAsync(statsigUser);
      }
    }
  }, [statsigUser, client, previousUser, statsigKey, onGateEvaluation]);

  useEffect(() => {
    const id = setInterval(() => {
      client?.updateUserAsync(statsigUser);
    }, 60000);
    return () => clearInterval(id);
  }, [client, statsigUser]);

  return (
    <FeatureFlagContext.Provider value={client}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.object,
};
