import actionTypes from '@actions';

export const toogleClipTool = () => ({
  type: actionTypes.TOGGLE_CLIP_TOOL,
});

export const resetClipTool = () => ({
  type: actionTypes.RESET_CLIP_TOOL,
});

export const selectClipToolOption = (option = '') => ({
  type: actionTypes.SELECT_CLIP_TOOL_OPTION,
  payload: {
    option,
  },
});

export const setShowSafetyCheckResults = (showSafetyCheckResults) => ({
  type: actionTypes.SET_SAFETY_CHECK_RESULTS,
  payload: { showSafetyCheckResults },
});

export const setClipToolRange = ({
  clipStep = 1,
  clipMinRange = 0,
  clipMaxRange = 100,
}) => ({
  type: actionTypes.SET_CLIP_TOOL_RANGE,
  payload: {
    clipStep,
    clipMinRange,
    clipMaxRange,
  },
});
export const resetClippedRangeAppliedToObject = () => ({
  type: actionTypes.RESET_CLIP_RANGE_APPLIED,
});

export const setClippedRangeAppliedToObject = ({
  clipStep = 1,
  clipMinRange = 0,
  clipMaxRange = 100,
}) => ({
  type: actionTypes.SET_CLIP_RANGE_APPLIED,
  payload: {
    clipStep,
    clipMinRange,
    clipMaxRange,
  },
});

export const updateClipToolMinMaxRanges = ({
  clipMinRangeValue = 0,
  clipMaxRangeValue = 100,
}) => ({
  type: actionTypes.UPDATE_CLIP_TOOL_MIN_MAX_RANGES,
  payload: {
    clipMinRangeValue,
    clipMaxRangeValue,
  },
});

export const setClipToolMode = (clipMode = '', clipOption = '') => ({
  type: actionTypes.SET_CLIP_TOOL_MODE,
  payload: { clipMode, clipOption },
});

export const setClipToolAvailability = (clipAvailable) => ({
  type: actionTypes.SET_CLIP_TOOL_AVAILABILITY,
  payload: { clipAvailable },
});
