import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import { getCurrentUser } from '@selectors/loginSelectors';
import { ROUTES } from '@constants/router';
import { sendEvent, EventCategory, EventAction } from '@utils/GaTracker';
import {
  KUKA_STREAMING,
  NIKON_AT,
} from '@constants/printers/postProcessorSettingsDefinitions';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { fetchToolpathOperatorInstructions } from '@app/actions/toolpathActions';
import usePrinterQueries from '../printers/usePrinterQueries';

export default function useDownloadStreamToolpath() {
  const user = useSelector(getCurrentUser());
  const dispatch = useDispatch();
  const { getSelectedWorkflow, getWorkflowPrinter } = useWorkflow();
  const { getOperator } = useOperator();
  const { streamToolpathOperatorToPrinterMutation } = useOperatorMutations();

  const workflow = getSelectedWorkflow();
  const printer = getWorkflowPrinter(workflow);
  const printerId = printer?.id;
  const { programFileTypeQuery } = usePrinterQueries({
    programType: printer?.programType,
  });
  const fileExtension = programFileTypeQuery.data;

  const downloadFile = useCallback((url = '', filename = '') => {
    if (!url) return;

    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => fileDownload(res?.data, filename));
  }, []);

  const downloadToolpath = useCallback(
    async (operatorId) => {
      const targetOperator = getOperator(operatorId);
      const extension = fileExtension ?? '.src';

      const { instructionsURL } = await dispatch(
        fetchToolpathOperatorInstructions(operatorId),
      );

      let fileName = `${workflow?.name}.${
        targetOperator?.tag || 'toolpath'
      }${extension}`;

      if (printer?.programType === KUKA_STREAMING) {
        fileName = `Toolpath${extension}`;
      } else if (printer?.programType === NIKON_AT) {
        // This postprocessor requires the explicit file name to be encoded in the program
        // We do not currently pass the workflow name to the program, logged in AIB-984
        // This is a temporary quick solution to avoid a large refactoring change just for this integration.
        fileName = `${targetOperator?.tag || 'toolpath'}${extension}`;
      }

      sendEvent(
        EventAction.toolpathDownloaded,
        EventCategory.engagement,
        fileName,
        user?.username,
      );

      downloadFile(instructionsURL, fileName);
    },
    [
      user?.username,
      workflow?.name,
      printer?.programType,
      downloadFile,
      dispatch,
      getOperator,
      fileExtension,
    ],
  );

  const streamToolpath = useCallback(
    async (operatorId) => {
      sendEvent(
        EventAction.toolpathStreamed,
        EventCategory.engagement,
        operatorId,
        user?.username,
      );

      const streamResponse =
        await streamToolpathOperatorToPrinterMutation.mutateAsync({
          printerId,
          operatorId,
        });

      if (!streamResponse?.printId) return;

      const navigationPath = generatePath(ROUTES.PRINTER, {
        printerId,
      });

      const win = window.open(navigationPath, '_blank');

      win.focus();
    },
    [user?.username, printerId, streamToolpathOperatorToPrinterMutation],
  );
  return { downloadToolpath, streamToolpath };
}
