import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDropDownMenu from '@hooks/useDropDownMenu';
import useOperator from '@hooks/operators/useOperator';
import useShadingTool from '@hooks/toolBar/useShadingTool';
import useLineDataTool from '@hooks/toolBar/useLineDataTool';
import useLineDataSelectionTool from '@hooks/toolBar/useLineDataSelectionTool';
import useDraftingTool from '@hooks/toolBar/useDraftingTool';
import { getClippingTool } from '@selectors/toolBarSelector';
import {
  resetClipTool,
  selectClipToolOption,
  toogleClipTool,
  updateClipToolMinMaxRanges,
} from '@actions/toolBarActions';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import {
  positiveDecimalRegExp,
  positiveIntegerInputPattern,
  positiveIntegerRegExp,
  positiveOptionalDecimalInputPattern,
} from '@utils/validation';
import {
  whenEnterButtonPressed,
  whenMatchPattern,
} from '@utils/interactionEvents';
import OperatorActionBar from '@components/2-molecules/OperatorActionBar';
import SceneClipBar from '@components/2-molecules/SceneClipBar';
import {
  DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
} from '@components/2-molecules/DropDownMenu';
import {
  LineTypeIcon,
  LineTypeIcons,
} from './WorkflowOperatorActionBar.styled';

const FIELD_UPDATE_DEBOUNCE_TIMEOUT = 500;

const WorkflowOperatorActionBar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hideDropDownMenu } = useDropDownMenu();
  const clippingTool = useSelector(getClippingTool);
  const { unselectSelectedOperator, getSelectedOperator } = useOperator();
  const selectedOperator = getSelectedOperator();
  const shadingTool = useShadingTool();
  const lineDataTool = useLineDataTool();
  const lineTypesTool = useLineDataSelectionTool();
  const draftingTool = useDraftingTool();
  const [showClippingTool, setShowClippingTool] = useState(false);
  const fieldUpdateTimeoutRef = useRef(null);

  const isClippingToolInitialized = clippingTool?.clipIsActive;
  const isClippingAxisMode = clippingTool?.clipMode === 'axis';
  const isClippingLayersMode = clippingTool?.clipMode === 'layers';

  const selectedOperatorId = useMemo(
    () => selectedOperator?.id,
    [selectedOperator],
  );

  const shadingToolButtonDropdown = useMemo(() => {
    const action = shadingTool.action;

    if (action.disabled) return;

    return {
      id: action.id,
      dataTestId: action.id,
      children: action.name,
      dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
      dropDownMenuItems: action.dropDownMenuItems,
    };
  }, [shadingTool.action]);

  const lineTypeDataButtonDropdown = useMemo(() => {
    const action = lineDataTool.action;

    if (action.disabled) return;

    return {
      id: action.id,
      dataTestId: action.id,
      children: action.name,
      dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
      dropDownMenuItems: action.dropDownMenuItems,
      endingIconName: isEmpty(action.dropDownMenuItems) ? null : undefined,
    };
  }, [lineDataTool.action]);

  const lineTypeColorRange = useMemo(() => {
    const range = lineDataTool.range;

    if (isEmpty(range)) return;

    return {
      minRangeLabel: range.minRange,
      maxRangeLabel: range.maxRange,
      colorType: range.colorType,
    };
  }, [lineDataTool.range]);

  const lineTypeDropDown = useMemo(() => {
    const action = lineTypesTool.action;

    if (action.disabled) return;

    return {
      id: action.id,
      dataTestId: action.id,
      leadingElement: (
        <LineTypeIcons>
          {action.selectedColors.map((color) => (
            <LineTypeIcon
              key={`line-type-action-selected-color-${color}`}
              color={color}
            />
          ))}
        </LineTypeIcons>
      ),
      children: action.name,
      dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
      dropDownMenuItems: action.dropDownMenuItems,
      endingIconName: isEmpty(action.dropDownMenuItems) ? null : undefined,
    };
  }, [lineTypesTool.action]);

  const toggleClipToolVisibility = useCallback(() => {
    setShowClippingTool((prev) => !prev);

    if (!isClippingToolInitialized) {
      dispatch(toogleClipTool());
    }
  }, [dispatch, isClippingToolInitialized]);

  const formatRange = useCallback(
    (range) => {
      const rangeValueType = isClippingAxisMode
        ? OperatorInputTypes.DECIMAL
        : OperatorInputTypes.INTEGER;

      switch (rangeValueType) {
        case OperatorInputTypes.DECIMAL:
          return Number(
            String(range)?.match(positiveDecimalRegExp)?.[1] || range,
          );
        case OperatorInputTypes.INTEGER:
          return Number(
            String(range)?.match(positiveIntegerRegExp)?.[0] || range,
          );

        default:
          return range;
      }
    },
    [isClippingAxisMode],
  );

  const isClippingApplied = useMemo(() => {
    const sameMinRange =
      formatRange(clippingTool?.clipMinRange) ===
      formatRange(clippingTool?.clipMinRangeValue);
    const sameMaxRange =
      formatRange(clippingTool?.clipMaxRange) ===
      formatRange(clippingTool?.clipMaxRangeValue);

    return isClippingToolInitialized && !(sameMinRange && sameMaxRange);
  }, [
    isClippingToolInitialized,
    clippingTool?.clipMinRange,
    clippingTool?.clipMaxRange,
    clippingTool?.clipMinRangeValue,
    clippingTool?.clipMaxRangeValue,
    formatRange,
  ]);

  const iconButtons = useMemo(() => {
    const visibleIconButtons = draftingTool.filter(({ disabled }) => !disabled);

    return visibleIconButtons.map((action) => {
      const iconButton = {
        id: action.id,
        title: action.name,
        selected: action.active,
        iconName: action.iconName,
        onClick: action.handler,
        turnToSwitchTypeWhenInMoreMenu: true,
      };

      if (action.isClipAction) {
        iconButton.turnToSwitchTypeWhenInMoreMenu = false;
        iconButton.selected = isClippingApplied;
        iconButton.onClick = toggleClipToolVisibility;
      }

      return iconButton;
    });
  }, [draftingTool, isClippingApplied, toggleClipToolVisibility]);

  const handleClipModeChange = useCallback(
    (mode) => () => dispatch(selectClipToolOption(mode)),
    [dispatch],
  );

  const clipModeDropDownMenuItems = useMemo(() => {
    if (isClippingAxisMode) {
      return [
        {
          id: `clip-axis-x`,
          label: intl.formatMessage({
            id: 'toolbar.clipping_tool.axis_mode.clip_x',
            defaultMessage: 'Clip X',
          }),
          selected: clippingTool?.clipOption === 'x',
          onClick: handleClipModeChange('x'),
        },
        {
          id: `clip-axis-y`,
          label: intl.formatMessage({
            id: 'toolbar.clipping_tool.axis_mode.clip_y',
            defaultMessage: 'Clip Y',
          }),
          selected: clippingTool?.clipOption === 'y',
          onClick: handleClipModeChange('y'),
        },
        {
          id: `clip-axis-z`,
          label: intl.formatMessage({
            id: 'toolbar.clipping_tool.axis_mode.clip_z',
            defaultMessage: 'Clip Z',
          }),
          selected: clippingTool?.clipOption === 'z',
          onClick: handleClipModeChange('z'),
        },
      ];
    }

    if (isClippingLayersMode) {
      return [
        {
          id: 0,
          label: intl.formatMessage({
            id: 'toolbar.clipping_tool.layers_mode.clip_layers',
            defaultMessage: 'Clip Layers',
          }),
          selected: clippingTool?.clipOption === 'layer',
          onClick: handleClipModeChange('layer'),
        },
        {
          id: 1,
          label: intl.formatMessage({
            id: 'toolbar.clipping_tool.layers_mode.clip_sequence',
            defaultMessage: 'Clip Sequence',
          }),
          selected: clippingTool?.clipOption === 'sequence',
          onClick: handleClipModeChange('sequence'),
        },
      ];
    }

    return [];
  }, [
    intl,
    clippingTool?.clipOption,
    isClippingAxisMode,
    isClippingLayersMode,
    handleClipModeChange,
  ]);

  const selectedClipMode = useMemo(
    () => clipModeDropDownMenuItems?.find(({ selected }) => selected),
    [clipModeDropDownMenuItems],
  );

  const handleRangeChange = useCallback(
    (range) => {
      dispatch(
        updateClipToolMinMaxRanges({
          clipMinRangeValue: range?.[0],
          clipMaxRangeValue: range?.[1],
        }),
      );
    },
    [dispatch],
  );

  const clipFormValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        clipMinRangeValue: Yup.string()
          .min(1, 'Too Short!')
          .max(50, 'Too Long!')
          .test(
            'is-valid-format',
            'Invalid format',
            (value) => !!value?.match(positiveDecimalRegExp),
          )
          .test(
            'is-in-valid-range',
            'Not in the range',
            (value) =>
              value >= clippingTool?.minRange &&
              value <= clippingTool?.maxRange,
          )
          .required('Required'),
        clipMaxRangeValue: Yup.string()
          .min(1, 'Too Short!')
          .max(50, 'Too Long!')
          .test(
            'is-valid-format',
            'Invalid format',
            (value) => !!value?.match(positiveDecimalRegExp),
          )
          .test(
            'is-in-valid-range',
            'Not in the range',
            (value) =>
              value >= clippingTool?.minRange &&
              value <= clippingTool?.maxRange,
          )
          .required('Required'),
      }),
    [clippingTool],
  );

  const clipForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(clipFormValidationSchema),
    defaultValues: {
      clipMinRangeValue: clippingTool?.clipMinRangeValue,
      clipMaxRangeValue: clippingTool?.clipMaxRangeValue,
    },
  });

  const {
    setValue: setClipFormValue,
    formState: { errors: clipFormErrors },
    getValues: getClipFormValues,
    reset: resetClipForm,
    watch,
  } = clipForm;

  const watchClipMinRangeValue = watch('clipMinRangeValue');
  const watchClipMaxRangeValue = watch('clipMaxRangeValue');

  useEffect(() => {
    resetClipForm({
      clipMinRangeValue: isClippingAxisMode
        ? parseFloat(clippingTool?.clipMinRangeValue)?.toFixed(1)
        : clippingTool?.clipMinRangeValue,
      clipMaxRangeValue: isClippingAxisMode
        ? parseFloat(clippingTool?.clipMaxRangeValue)?.toFixed(1)
        : clippingTool?.clipMaxRangeValue,
    });
  }, [
    resetClipForm,
    clippingTool?.clipMinRangeValue,
    clippingTool?.clipMaxRangeValue,
    isClippingAxisMode,
  ]);

  const resetClipToolValues = useCallback(() => {
    dispatch(
      updateClipToolMinMaxRanges({
        clipMinRangeValue: clippingTool?.clipMinRange,
        clipMaxRangeValue: clippingTool?.clipMaxRange,
      }),
    );
  }, [clippingTool?.clipMinRange, clippingTool?.clipMaxRange, dispatch]);

  const getRangeFieldValueMatchPattern = useCallback(() => {
    const rangeValueType = isClippingAxisMode
      ? OperatorInputTypes.DECIMAL
      : OperatorInputTypes.INTEGER;

    switch (rangeValueType) {
      case OperatorInputTypes.DECIMAL:
        return positiveOptionalDecimalInputPattern;

      case OperatorInputTypes.INTEGER:
        return positiveIntegerInputPattern;

      default:
        return 'DECIMAL';
    }
  }, [isClippingAxisMode]);

  const applyClipFormRanges = useCallback(() => {
    const { clipMinRangeValue, clipMaxRangeValue } = getClipFormValues();

    const minRangeValue = Number(clipMinRangeValue);
    const maxRangeValue = Number(clipMaxRangeValue);
    let nextRanges = [minRangeValue, maxRangeValue];

    if (minRangeValue > maxRangeValue) {
      nextRanges = [maxRangeValue, minRangeValue];
    }

    handleRangeChange(nextRanges);
  }, [getClipFormValues, handleRangeChange]);

  const handleFormRangeChange = useCallback(
    (fieldName) =>
      ({ target: { value } }) => {
        const isMinRangeField = fieldName === 'clipMinRangeValue';
        const isMaxRangeField = !isMinRangeField;
        let nextValue = value;

        clearTimeout(fieldUpdateTimeoutRef.current);

        setClipFormValue?.(fieldName, nextValue);

        const clipMaxRangeValue = getClipFormValues('clipMaxRangeValue');

        fieldUpdateTimeoutRef.current = setTimeout(() => {
          if (isMinRangeField && value < clippingTool?.clipMinRange) {
            nextValue = clippingTool?.clipMinRange;
          }

          if (isMinRangeField && value > +clipMaxRangeValue) {
            nextValue = clipMaxRangeValue;
          }

          if (isMinRangeField && isEmpty(value)) {
            nextValue = clippingTool?.clipMinRange;
          }

          if (
            isMaxRangeField &&
            (value > clippingTool?.clipMaxRange || isEmpty(value))
          ) {
            nextValue = clippingTool?.clipMaxRange;
          }

          setClipFormValue?.(fieldName, formatRange(nextValue));
          applyClipFormRanges();
        }, FIELD_UPDATE_DEBOUNCE_TIMEOUT);
      },
    [
      clippingTool?.clipMinRange,
      clippingTool?.clipMaxRange,
      getClipFormValues,
      formatRange,
      setClipFormValue,
      applyClipFormRanges,
    ],
  );

  const onRangeFieldChange = useCallback(
    (fieldName) =>
      whenMatchPattern(
        getRangeFieldValueMatchPattern(),
        handleFormRangeChange(fieldName),
      ),
    [getRangeFieldValueMatchPattern, handleFormRangeChange],
  );

  const clipMinRangeField = useMemo(
    () => ({
      autoFocus: true,
      value: watchClipMinRangeValue,
      error: !!clipFormErrors?.clipMinRangeValue,
      supportingText: clipFormErrors?.clipMinRangeValue,
      onChange: onRangeFieldChange('clipMinRangeValue'),
      onKeyDown: whenEnterButtonPressed(hideDropDownMenu),
    }),
    [
      watchClipMinRangeValue,
      clipFormErrors,
      onRangeFieldChange,
      hideDropDownMenu,
    ],
  );

  const clipMaxRangeField = useMemo(
    () => ({
      autoFocus: true,
      value: watchClipMaxRangeValue,
      error: !!clipFormErrors?.clipMaxRangeValue,
      supportingText: clipFormErrors?.clipMaxRangeValue,
      onChange: onRangeFieldChange('clipMaxRangeValue'),
      onKeyDown: whenEnterButtonPressed(hideDropDownMenu),
    }),
    [
      watchClipMaxRangeValue,
      clipFormErrors,
      onRangeFieldChange,
      hideDropDownMenu,
    ],
  );

  const clipMinRangeDropDownMenuItems = useMemo(
    () => [
      {
        id: 'clip-min-range-field',
        field: clipMinRangeField,
      },
    ],
    [clipMinRangeField],
  );

  const clipMaxRangeDropDownMenuItems = useMemo(
    () => [
      {
        id: 'clip-max-range-field',
        field: clipMaxRangeField,
      },
    ],
    [clipMaxRangeField],
  );

  useEffect(() => {
    setShowClippingTool(false);
    dispatch(resetClipTool());
  }, [dispatch, selectedOperatorId]);

  useEffect(() => {
    return () => {
      dispatch(resetClipTool());
    };
  }, [dispatch]);

  if (showClippingTool) {
    return (
      <SceneClipBar
        clipMaxRange={formatRange(clippingTool?.clipMaxRangeValue)}
        clipMaxRangeDropDownMenuItems={clipMaxRangeDropDownMenuItems}
        clipMinRange={formatRange(clippingTool?.clipMinRangeValue)}
        clipMinRangeDropDownMenuItems={clipMinRangeDropDownMenuItems}
        clipMode={selectedClipMode?.label}
        clipModeDropDownMenuItems={clipModeDropDownMenuItems}
        disableResetButton={!isClippingApplied}
        onBackIconButtonClick={toggleClipToolVisibility}
        onResetButtonClick={resetClipToolValues}
        slider={{
          range: true,
          min: clippingTool?.clipMinRange,
          max: clippingTool?.clipMaxRange,
          defaultValue: [
            clippingTool?.clipMinRange,
            clippingTool?.clipMaxRange,
          ],
          value: [
            clippingTool?.clipMinRangeValue,
            clippingTool?.clipMaxRangeValue,
          ],
          step: clippingTool?.clipStep,
          onChange: handleRangeChange,
        }}
      />
    );
  }

  return (
    <OperatorActionBar
      shadingToolButtonDropdown={shadingToolButtonDropdown}
      lineTypeDataButtonDropdown={lineTypeDataButtonDropdown}
      lineTypeColorRange={lineTypeColorRange}
      lineTypeDropDown={lineTypeDropDown}
      iconButtons={iconButtons}
      onCloseIconButtonClick={unselectSelectedOperator}
    />
  );
};

WorkflowOperatorActionBar.propTypes = {};

export default WorkflowOperatorActionBar;
