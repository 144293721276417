import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNozzleConfig } from '@selectors/printerSelectors';
import { Formik } from 'formik';
import { NozzleSettings } from '@components/Printers/NozzleLibrary/NozzleSettings';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { hideNozzleConfig } from '@actions/printerActions';
import useSnackbar from '@hooks/useSnackbar';
import useNozzleMutations from '@hooks/nozzle/useNozzleMutations';
import { logError } from '@utils/logs';

export const Nozzle = ({ selectedPrinter }) => {
  const nozzleConfig = useSelector(getNozzleConfig());
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const { createNozzleMutation, updateNozzleMutation } = useNozzleMutations();
  const { showDialog } = useDialog();

  const mapDbConfigToFormInitialValues = (nozzle) => {
    let initialValues = {
      name: '',
      nozzleDiameter: '6.0',
      calibrationXCoefficient: '20.0',
      calibrationX2Coefficient: '10.0',
      calibrationX3Coefficient: '0.0',
      calibrationYIntercept: '0.0',
    };

    if (nozzle && Object.keys(nozzle).length > 0) {
      initialValues = nozzle;
    }
    return initialValues;
  };

  const onFormSubmit = useCallback(
    async (
      {
        name,
        nozzleDiameter,
        calibrationXCoefficient,
        calibrationX2Coefficient,
        calibrationX3Coefficient,
        calibrationYIntercept,
      },
      { setSubmitting, setErrors, resetForm },
    ) => {
      setErrors({});

      if (nozzleConfig?.nozzle && nozzleConfig?.nozzle.id) {
        const nozzleToBeUpdated = {
          ...nozzleConfig?.nozzle,
          name: name,
          nozzleDiameter: nozzleDiameter,
          calibrationXCoefficient: calibrationXCoefficient,
          calibrationX2Coefficient: calibrationX2Coefficient,
          calibrationX3Coefficient: calibrationX3Coefficient,
          calibrationYIntercept: calibrationYIntercept,
        };
        showDialog(ModalDataTypes.PROMPT, {
          dataTestId: 'update-nozzle-dialog',
          title: intl.formatMessage({
            id: 'update_nozzle_dialog.title',
            defaultMessage: 'Update nozzle',
          }),
          subtitle: intl.formatMessage({
            id: 'update_nozzle_dialog.text',
            defaultMessage:
              'Are you sure you want to perform the update? Changes will not be reflected in workflows using this nozzle',
          }),
          primaryButtonLabel: intl.formatMessage({
            id: 'general.update',
            defaultMessage: 'Update',
          }),
          onPrimaryButtonClick: async () => {
            try {
              await updateNozzleMutation.mutateAsync({
                nozzle: nozzleToBeUpdated,
              });
            } catch (e) {
              logError(e);
            }
            dispatch(hideNozzleConfig());
          },
        });
      } else {
        const newNozzle = {
          name: name,
          nozzleDiameter: nozzleDiameter,
          calibrationXCoefficient: calibrationXCoefficient,
          calibrationX2Coefficient: calibrationX2Coefficient,
          calibrationX3Coefficient: calibrationX3Coefficient,
          calibrationYIntercept: calibrationYIntercept,
        };

        try {
          await createNozzleMutation.mutateAsync({
            nozzle: newNozzle,
            printerId: selectedPrinter.id,
          });

          showSnackbar({
            text: intl.formatMessage({
              id: 'create_nozzle_dialog.snackbar.label',
              defaultMessage: 'New Nozzle successfully created',
            }),
          });
        } catch (e) {
          logError(e);
        }
        dispatch(hideNozzleConfig());
      }

      setSubmitting(false);
      resetForm();
    },
    [
      updateNozzleMutation,
      createNozzleMutation,
      showSnackbar,
      intl,
      showDialog,
      selectedPrinter,
      dispatch,
      nozzleConfig,
    ],
  );
  // Generate formInitialValues using the map function
  const formInitialValues = useMemo(() => {
    return mapDbConfigToFormInitialValues(nozzleConfig?.nozzle);
  }, [nozzleConfig?.nozzle]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.name.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          )
          .min(
            5,
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.name.validation.min',
              defaultMessage: 'Name must be greater than 5 characters',
            }),
          )
          .max(
            128,
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.name.validation.max',
              defaultMessage: 'Name must be no longer than 128 characters',
            }),
          )
          .matches(
            /^(?=.*\S)[\w ]+$/,
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.name.validation.regex',
              defaultMessage: 'Only letters, numbers, spaces and underscores',
            }),
          ),
        nozzleDiameter: Yup.number()
          .required(
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.nozzleDiameter.validation.required',
              defaultMessage: 'Cannot be empty',
            }),
          )
          .moreThan(
            0,
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.nozzleDiameter.validation.min',
              defaultMessage: 'Value has to be greater than 0',
            }),
          )
          .max(
            500,
            intl.formatMessage({
              id: 'printers.category.nozzle-library.property.nozzleDiameter.validation.max',
              defaultMessage: 'Value has to be lower than 500',
            }),
          ),
        calibrationXCoefficient: Yup.number().required(
          intl.formatMessage({
            id: 'printers.category.nozzle-library.property.calibrationXCoefficient.validation.required',
            defaultMessage: 'Cannot be empty',
          }),
        ),
        calibrationX2Coefficient: Yup.number().required(
          intl.formatMessage({
            id: 'printers.category.nozzle-library.property.calibrationX2Coefficient.validation.required',
            defaultMessage: 'Cannot be empty',
          }),
        ),
        calibrationX3Coefficient: Yup.number().required(
          intl.formatMessage({
            id: 'printers.category.nozzle-library.property.calibrationX3Coefficient.validation.required',
            defaultMessage: 'Cannot be empty',
          }),
        ),
        calibrationYIntercept: Yup.number().required(
          intl.formatMessage({
            id: 'printers.category.nozzle-library.property.calibrationYIntercept.validation.required',
            defaultMessage: 'Cannot be empty',
          }),
        ),
      }),
    [intl],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, dirty }) => (
        <NozzleSettings
          handleSubmit={handleSubmit}
          dirty={dirty}
          nozzle={nozzleConfig?.nozzle}
        />
      )}
    </Formik>
  );
};

Nozzle.propTypes = {
  selectedPrinter: PropTypes.object.isRequired,
};
