import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual, omit, set } from 'lodash';
import useFile from '@hooks/files/useFile';
import usePrinter from '@hooks/printers/usePrinter';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import usePrevious from '@hooks/usePrevious';
import { getIsFetchesInProgress } from '@selectors/generalSelectors';
import {
  getCameraConfig,
  getFocusCameraOnObject,
} from '@selectors/sceneSelectors';
import {
  getCameraPosition,
  getIsShowingGrid,
  getSceneSettings,
  getSelectedOperatorOutputId,
  getSimulation,
} from '@selectors/conceptSelectors';
import { getUserPreference } from '@selectors/loginSelectors';
import {
  exitToolpathSimulation,
  resetFrozenOperatorOutputs,
  selectOperatorOutput,
  updateCameraPosition as updateCameraPositionAction,
  updateLegendRange,
} from '@actions/conceptActions';
import {
  startLoading3DModel,
  stopLoading3DModel,
} from '@actions/designActions';
import {
  focusCameraOnObject as focusCameraOnObjectAction,
  setCameraConfig,
} from '@actions/sceneActions';
import {
  isGLBModel,
  isObject3DModel,
  isOrthographicCameraMode,
} from '@utils/camera';
import {
  getLegendRange,
  getPrinterWithModels,
  updateModel,
} from '@utils/model';
import { DEFAULT_CAMERA_CONFIG } from '@constants/camera';

const contextInitiaState = {
  threeState: {},
  controls: {},
};

export const VisualizationContext = createContext(contextInitiaState);
export const useVisualizationContext = () => useContext(VisualizationContext);

export const VisualizationContextProvider = ({ children }) => {
  const dispatch = useDispatch();

  const {
    getSelectedWorkflow,
    getIsWorkflowComputing,
    getWorkflowOperators,
    getIsWorkflowEditable,
    getWorkflowPrinter,
  } = useWorkflow();
  const { getSelectedOperator, getSelectedOperatorOutputGeometryId } =
    useOperator();
  const { getProjectFiles, getSelectedDesignIds, getCachedGeometries } =
    useFile();
  const { getAllPrinterDefinitions } = usePrinter();

  const cameraConfig = useSelector(getCameraConfig);

  const workflow = getSelectedWorkflow();
  const worklowPrinter = getWorkflowPrinter(workflow);
  const designMetadata = getProjectFiles(workflow?.workspaceId);
  const selectedOperator = useMemo(
    () => getSelectedOperator(),
    [getSelectedOperator],
  );
  const {
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  } = getAllPrinterDefinitions();

  const simulation = useSelector(getSimulation());
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const selectedOperatorOutputDesignId = getSelectedOperatorOutputGeometryId();
  const sceneSettings = useSelector(getSceneSettings);
  const isShowingGrid = useSelector(getIsShowingGrid());
  const isFocusingCameraOnObject = useSelector(getFocusCameraOnObject);
  const cameraPosition = useSelector(getCameraPosition);

  const isFetching = useSelector(getIsFetchesInProgress());
  const workflowIsComputing = getIsWorkflowComputing(workflow);
  const workflowIsEditable = getIsWorkflowEditable(workflow);
  const isComputeAutomatically = useSelector(
    getUserPreference('isComputeAutomatically'),
  );

  const [threeState, setThreeState] = useState(null);
  const [camera, setCamera] = useState(null);
  const [canvasSelectionCamera, setCanvasSelectionCamera] = useState(null);
  const [objects, setObjects] = useState({});
  const [controls, setControls] = useState({});
  const [printer, setPrinter] = useState();
  const previousPrinter = usePrevious(printer);

  const orthographicCameraMode = isOrthographicCameraMode(cameraConfig?.mode);

  const previousWorklowPrinter = usePrevious(worklowPrinter);
  const previousSceneSettings = usePrevious(sceneSettings);
  const previousSimulation = usePrevious(simulation);

  const workflowOperators = getWorkflowOperators(workflow);
  const selectedDesigns = getSelectedDesignIds(workflowOperators);
  const selectedDesignIds = selectedDesigns.designIds;
  const cachedGeometries = getCachedGeometries(
    selectedDesignIds,
    workflow?.printerId,
  );

  const designs = useMemo(
    () =>
      Object.keys(cachedGeometries).reduce((acc, designId) => {
        const designGeometry = cachedGeometries?.[designId]?.displayData;
        const design = designMetadata.find(({ id }) => id === designId);

        if (!designGeometry || !design) return acc;

        return acc.concat({
          designId,
          designGeometry,
          design,
        });
      }, []),
    [cachedGeometries, designMetadata],
  );

  const selectedOutputDesign = useMemo(
    () =>
      designs.find(
        ({ design }) => design.id === selectedOperatorOutputDesignId,
      ),
    [designs, selectedOperatorOutputDesignId],
  );
  const previousSelectedOutputDesign = usePrevious(selectedOutputDesign);

  const designsToRender = useMemo(
    () =>
      designs.filter(({ designId }) => !objects[`operatorOutput-${designId}`]),
    [objects, designs],
  );

  const designsToRemove = useMemo(
    () =>
      Object.values(objects).reduce((acc, object) => {
        const isObjectDesignSelected = designs.find(
          ({ designId }) => object?.designId === designId,
        );

        if (isObjectDesignSelected) {
          return acc;
        }

        return acc.concat({ designId: object?.designId });
      }, []),
    [objects, designs],
  );

  const updateCameraPosition = useCallback(
    (cameraPosition = []) => {
      dispatch(updateCameraPositionAction(...cameraPosition));
    },
    [dispatch],
  );

  const focusCameraOnObject = useCallback(
    (focus = false) => {
      dispatch(focusCameraOnObjectAction(focus));
    },
    [dispatch],
  );

  const addObject = useCallback(
    (id, object) => {
      const nextObjects = {
        ...objects,
        [id]: object,
      };

      setObjects(nextObjects);
    },
    [objects],
  );

  const removeObject = useCallback(
    (object) => {
      const nextObjects = objects.filter((obj) => obj !== object);

      setObjects(nextObjects);
    },
    [objects],
  );

  const isAibDesign = useCallback(
    (designId) =>
      designs?.find(({ design }) => design && design.id === designId)?.design
        ?.filetype === 'aib',
    [designs],
  );

  const getDisplaySettings = useCallback(
    (design) => {
      const selectedOperatorOutputDesigns = workflowOperators
        .flatMap((operator) => operator.values)
        .filter((value) => selectedOperatorOutputId === value.id)
        .map(({ value }) => value);

      const selectedOperatorOutputDesignId = selectedOperatorOutputDesigns?.[0];

      if (selectedOperatorOutputDesignId === design.id) {
        return sceneSettings;
      }

      return {};
    },
    [workflowOperators, sceneSettings, selectedOperatorOutputId],
  );

  const updateModalWithDataProps = useCallback(
    (designId, model) => {
      model.userData = {
        ...(model?.userData || {}),
        designId: designId,
        isOutput: true,
        isLinesType: isAibDesign(designId),
      };
      model.designId = designId;

      return model;
    },
    [isAibDesign],
  );

  const observeModelsChanges = useCallback(async () => {
    const skip = !threeState || isEmpty(designsToRender);

    if (skip) return;

    const initialObjects = {};

    const models = designsToRender.map(({ design, designGeometry }) => ({
      id: design?.id,
      model: updateModel(
        design,
        designGeometry,
        getDisplaySettings(design),
        worklowPrinter,
      ),
    }));

    const notSuportedModels = models.filter(
      ({ model }) => !isObject3DModel(model) && !isGLBModel(model),
    );
    const object3DModles = models.filter(({ model }) => isObject3DModel(model));
    let glbModels = models.filter(({ model }) => isGLBModel(model));

    glbModels = await Promise.all(
      glbModels.map(async ({ id, model }) => {
        const gltfObject = await model.getLoadedModelPromise();

        return { id, model: gltfObject.scene };
      }),
    );

    if (!isEmpty(notSuportedModels)) {
      // eslint-disable-next-line no-console
      console.warn(
        'Not the instances of THREE.Object3D! : ',
        notSuportedModels,
      );
    }

    const resolvedModels = [...object3DModles, ...glbModels];

    const nextObjects = resolvedModels.reduce(
      (acc, { id, model }) => ({
        ...acc,
        [`operatorOutput-${id}`]: updateModalWithDataProps(id, model),
      }),
      initialObjects,
    );

    setObjects((prevObjecs) => ({
      ...prevObjecs,
      ...nextObjects,
    }));
  }, [
    threeState,
    designsToRender,
    worklowPrinter,
    getDisplaySettings,
    updateModalWithDataProps,
  ]);

  const observerSelectOutputRangeUpdates = useCallback(() => {
    const settingsChanged = !isEqual(
      previousSceneSettings?.lineData,
      sceneSettings?.lineData,
    );
    const outputDesignChanged =
      selectedOutputDesign &&
      previousSelectedOutputDesign?.designId !== selectedOutputDesign?.designId;
    const skip =
      !selectedOutputDesign || (!outputDesignChanged && !settingsChanged);

    if (skip) return;

    const { design, designGeometry } = selectedOutputDesign;

    const range = getLegendRange(
      design,
      designGeometry,
      sceneSettings?.lineData,
      sceneSettings?.customLineData,
    );

    const rangeAvaiable = range != null;

    if (rangeAvaiable) {
      dispatch(updateLegendRange(range[0], range[1]));
    }
  }, [
    selectedOutputDesign,
    previousSelectedOutputDesign,
    previousSceneSettings?.lineData,
    sceneSettings?.lineData,
    sceneSettings?.customLineData,
    dispatch,
  ]);

  const observerSelectOutputSettingsUpdates = useCallback(async () => {
    const settingsChanged = !isEqual(previousSceneSettings, sceneSettings);
    const skip = !selectedOutputDesign || !settingsChanged;

    if (skip) return;

    const { design, designGeometry } = selectedOutputDesign;

    const designId = design?.id;

    let model = updateModel(
      design,
      designGeometry,
      getDisplaySettings(design),
      worklowPrinter,
    );

    if (isGLBModel(model)) {
      model = await model.getLoadedModelPromise();

      return;
    }

    setObjects((prevObjects) => ({
      ...prevObjects,
      [`operatorOutput-${designId}`]: updateModalWithDataProps(designId, model),
    }));
  }, [
    selectedOutputDesign,
    previousSceneSettings,
    sceneSettings,
    worklowPrinter,
    getDisplaySettings,
    updateModalWithDataProps,
  ]);

  const observerModelsToRemove = useCallback(() => {
    const skip =
      isEmpty(designsToRemove) ||
      !threeState?.scene ||
      designsToRemove.every(
        ({ designId }) =>
          !threeState.scene.getObjectByProperty('designId', designId),
      );

    if (skip) return;

    setObjects(
      omit(
        objects,
        designsToRemove.map(({ designId }) => `operatorOutput-${designId}`),
      ),
    );
  }, [objects, designsToRemove, threeState?.scene]);

  const observePrinterSettingsChanges = useCallback(() => {
    if (!worklowPrinter) return;

    const renderedPrinterUpdated = !isEqual(previousPrinter, printer);

    const showingWorkspaceSettingUpdated = !isEqual(
      previousSceneSettings?.isShowingWorkspace,
      sceneSettings?.isShowingWorkspace,
    );
    const showingRobotSettingUpdated = !isEqual(
      previousSceneSettings?.isShowingRobot,
      sceneSettings?.isShowingRobot,
    );
    const showingEnclosureSettingUpdated = !isEqual(
      previousSceneSettings?.isShowingEnclosure,
      sceneSettings?.isShowingEnclosure,
    );
    const showingPrintingBedSettingUpdated = !isEqual(
      previousSceneSettings?.isShowingPrintingBed,
      sceneSettings?.isShowingPrintingBed,
    );

    if (renderedPrinterUpdated || showingWorkspaceSettingUpdated) {
      set(printer, 'workspace.visible', sceneSettings?.isShowingWorkspace);
    }

    if (renderedPrinterUpdated || showingRobotSettingUpdated) {
      set(printer, 'machine.visible', sceneSettings?.isShowingRobot);
      set(printer, 'plinth.visible', sceneSettings?.isShowingRobot);
    }

    if (renderedPrinterUpdated || showingEnclosureSettingUpdated) {
      set(printer, 'enclosure.visible', sceneSettings?.isShowingEnclosure);
    }

    if (renderedPrinterUpdated || showingPrintingBedSettingUpdated) {
      printer?.bed?.setVisibility?.(sceneSettings.isShowingPrintingBed);
    }
  }, [
    worklowPrinter,
    printer,
    previousPrinter,
    previousSceneSettings?.isShowingEnclosure,
    previousSceneSettings?.isShowingPrintingBed,
    previousSceneSettings?.isShowingRobot,
    previousSceneSettings?.isShowingWorkspace,
    sceneSettings?.isShowingEnclosure,
    sceneSettings.isShowingPrintingBed,
    sceneSettings?.isShowingRobot,
    sceneSettings?.isShowingWorkspace,
  ]);

  const observerPrinterComponentsToRestore = useCallback(async () => {
    const isSimulationActiveChanged = !isEqual(
      previousSimulation?.isActive,
      simulation?.isActive,
    );
    const isPrinterChanges = !isEqual(previousWorklowPrinter, worklowPrinter);
    const needsToRestorePrinterComponents =
      worklowPrinter &&
      (isSimulationActiveChanged || isPrinterChanges) &&
      extruderDefinitions;

    if (!needsToRestorePrinterComponents) return;

    dispatch(startLoading3DModel());

    const printerWithModels = await getPrinterWithModels(
      worklowPrinter,
      machineDefinitions,
      extruderDefinitions,
      printingBedDefinitions,
      plinthDefinitions,
      enclosureDefinitions,
    );

    if (!printerWithModels) {
      dispatch(stopLoading3DModel());

      return;
    }

    setPrinter(printerWithModels);
    dispatch(stopLoading3DModel());
    dispatch(setCameraConfig(DEFAULT_CAMERA_CONFIG));
  }, [
    dispatch,
    previousWorklowPrinter,
    worklowPrinter,
    previousSimulation?.isActive,
    simulation?.isActive,
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  ]);

  const cleanUp = useCallback(() => {
    dispatch(selectOperatorOutput(null));
    dispatch(resetFrozenOperatorOutputs());
    dispatch(exitToolpathSimulation());
  }, [dispatch]);

  useEffect(() => {
    observeModelsChanges();
  }, [observeModelsChanges]);

  useEffect(() => {
    observerSelectOutputRangeUpdates();
  }, [observerSelectOutputRangeUpdates]);

  useEffect(() => {
    observerSelectOutputSettingsUpdates();
  }, [observerSelectOutputSettingsUpdates]);

  useEffect(() => {
    observerModelsToRemove();
  }, [observerModelsToRemove]);

  useEffect(() => {
    observePrinterSettingsChanges();
  }, [observePrinterSettingsChanges]);

  useEffect(() => {
    observerPrinterComponentsToRestore();
  }, [observerPrinterComponentsToRestore]);

  useEffect(
    () => () => {
      cleanUp();
    },
    [cleanUp],
  );

  const state = useMemo(
    () => ({
      worklowPrinter,
      printer,
      simulation,
      threeState,
      setThreeState,
      camera,
      setCamera,
      canvasSelectionCamera,
      setCanvasSelectionCamera,
      showGrid: isShowingGrid,
      isFocusingCameraOnObject,
      cameraPosition,
      cameraConfig,
      orthographicCameraMode,
      objects,
      addObject,
      removeObject,
      controls,
      setControls,
      updateCameraPosition,
      focusCameraOnObject,
      sceneSettings,
      selectedOperator,
      isFetching,
      workflowIsComputing,
      workflowIsEditable,
      isComputeAutomatically,
      selectedOperatorOutputDesignId,
    }),
    [
      worklowPrinter,
      printer,
      simulation,
      threeState,
      setThreeState,
      camera,
      setCamera,
      canvasSelectionCamera,
      setCanvasSelectionCamera,
      isShowingGrid,
      isFocusingCameraOnObject,
      cameraPosition,
      cameraConfig,
      orthographicCameraMode,
      objects,
      addObject,
      removeObject,
      controls,
      setControls,
      updateCameraPosition,
      focusCameraOnObject,
      sceneSettings,
      selectedOperator,
      isFetching,
      workflowIsComputing,
      workflowIsEditable,
      isComputeAutomatically,
      selectedOperatorOutputDesignId,
    ],
  );

  return (
    <VisualizationContext.Provider value={state}>
      {children}
    </VisualizationContext.Provider>
  );
};

VisualizationContextProvider.propTypes = {
  children: PropTypes.any,
};
