import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useDialog from '@hooks/useDialog';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import useLeanUpon from '@hooks/learnUpon/useLeanUpon';
import { ROUTES } from '@constants/router';
import NavigationDrawer from '@components/2-molecules/NavigationDrawer';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';
import useApplication from '@hooks/application/useApplication';

import { ModalDataTypes } from '@constants/modalDataTypes';
import { b } from '@utils/render';
import { isFeatureEnabled } from '@selectors/configSelectors';
import { OverlayWrapper } from '@components/NavigationBar/NavigationBar.styled';
import useFeatureFlags from '@hooks/featureflags/useFeatureFlags';
import {
  LIBRARY_EXAMPLES,
  TOOL_LIBRARY_FEATURE,
  PRINTER_MONITOR_FEATURE,
  KNOWLEDGE_CENTRE,
} from '@constants/featureFlagConstants';

const NavigationBar = ({
  onProjectsItemClick,
  onPrintersItemClick,
  onToolsItemClick,
  withLogo,
}) => {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isToolLibraryEnabled = isFeatureFlagEnabled(TOOL_LIBRARY_FEATURE);
  const isLibraryExamplesEnabled = isFeatureFlagEnabled(LIBRARY_EXAMPLES);

  const showKnowledgeCentre =
    useSelector(isFeatureEnabled(KNOWLEDGE_CENTRE)) && !DESKTOP_APP;
  const { onLearnUponLinkClick } = useLeanUpon();
  const isPrinterMonitorEnabled = useFeatureFlagValue(PRINTER_MONITOR_FEATURE);
  const [helpVisibility, setHelpVisibility] = useState(false);
  const user = useSelector(getCurrentUser());
  const { getThemeLogoUrl } = useApplication();
  const logoUrl = getThemeLogoUrl();

  const onHelpClick = useCallback(() => {
    if (DESKTOP_APP) {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'contact-support-dialog',
        title: intl.formatMessage({
          id: 'dialogs.title.contact_support',
          defaultMessage: 'Contact Support',
        }),
        subtitle: intl.formatMessage(
          {
            id: 'dialogs.subtitle.contact_support',
            defaultMessage:
              'If you have any questions or need help, please contact our support team at <b>support@ai-build.com</b>',
          },
          { b },
        ),
        secondaryButtonLabel: '',
        primaryButtonLabel: intl.formatMessage({
          id: 'dialogs.button.close',
          defaultMessage: 'Close',
        }),
      });
    } else {
      if (!helpVisibility) zE.activate();
      if (helpVisibility) zE.hide();

      setHelpVisibility(!helpVisibility);
    }
  }, [helpVisibility, intl, showDialog]);

  useEffect(() => {
    if (!user || DESKTOP_APP || !zE) return;

    zE('webWidget', 'prefill', {
      name: {
        value: user?.name,
        readOnly: true,
      },
      email: {
        value: user?.username,
        readOnly: false,
      },
    });
  }, [user]);

  useEffect(() => {
    if (DESKTOP_APP || !zE) return;
    zE('webWidget:on', 'close', () => setHelpVisibility(false));
  }, []);

  const itemList = [
    {
      id: '1',
      iconName: 'home_0',
      label: intl.formatMessage({
        id: 'navigation.link.home',
        defaultMessage: 'Home',
      }),
      to: ROUTES.HOME,
      exact: true,
      className: helpVisibility ? 'active' : '',
    },
    {
      id: '2',
      iconName: 'category_0',
      label: intl.formatMessage({
        id: 'navigation.link.project',
        defaultMessage: 'Projects',
      }),
      to: ROUTES.PROJECTS,
      renderAs: onProjectsItemClick ? 'span' : undefined,
      onClick: onProjectsItemClick,
    },
    ...(isLibraryExamplesEnabled
      ? [
          {
            id: '6',
            iconName: 'space_dashboard_0',
            label: intl.formatMessage({
              id: 'navigation.link.library',
              defaultMessage: 'Library',
            }),
            to: ROUTES.LIBRARY,
          },
        ]
      : []),
    {
      id: '7',
      label: intl.formatMessage({
        id: 'navigation.link.setup',
        defaultMessage: 'Setup',
      }),
      items: [
        {
          id: '7.1',
          iconName: 'precision_manufacturing_0',
          label: intl.formatMessage({
            id: 'navigation.link.printers',
            defaultMessage: 'Printers',
          }),
          to: ROUTES.PRINTERS,
          renderAs: onPrintersItemClick ? 'span' : undefined,
          onClick: onPrintersItemClick,
        },
        {
          id: '7.2',
          iconName: 'spool_0',
          label: intl.formatMessage({
            id: 'navigation.link.materials',
            defaultMessage: 'Materials',
          }),
          to: ROUTES.MATERIALS,
        },
        ...(isToolLibraryEnabled
          ? [
              {
                id: '7.3',
                iconName: 'tool_0',
                label: intl.formatMessage({
                  id: 'navigation.link.tools',
                  defaultMessage: 'Tools',
                }),
                to: ROUTES.TOOLS,
                renderAs: onToolsItemClick ? 'span' : undefined,
                onClick: onToolsItemClick,
              },
            ]
          : []),
      ],
    },
    {
      id: '8',
      label: intl.formatMessage({
        id: 'navigation.link.account',
        defaultMessage: 'Account',
      }),
      items: [
        {
          id: '8.1',
          iconName: 'settings_0',
          label: intl.formatMessage({
            id: 'navigation.link.settings',
            defaultMessage: 'Settings',
          }),
          to: ROUTES.SETTINGS,
        },
        ...(showKnowledgeCentre
          ? [
              {
                id: '8.2',
                iconName: 'school_0',
                label: intl.formatMessage({
                  id: 'navigation.link.knowledge_center',
                  defaultMessage: 'Knowledge Center',
                }),
                renderAs: 'span',
                onClick: onLearnUponLinkClick,
              },
            ]
          : []),
        ...(showKnowledgeCentre
          ? [
              {
                id: '8.3',
                iconName: 'help_0',
                label: intl.formatMessage({
                  id: 'navigation.link.help_and_support',
                  defaultMessage: 'Help and Support',
                }),
                renderAs: 'span',
                onClick: onHelpClick,
                className: helpVisibility ? 'active' : '',
              },
            ]
          : []),
      ],
    },
  ];

  if (isPrinterMonitorEnabled) {
    itemList.splice(2, 0, {
      id: '3',
      iconName: 'live_tv_0',
      label: intl.formatMessage({
        id: 'navigation.link.monitor',
        defaultMessage: 'Monitor',
      }),
      to: ROUTES.MONITOR,
    });
  }

  return (
    <>
      <NavigationDrawer
        items={itemList}
        withLogo={withLogo}
        logoUrl={logoUrl}
      />
      {helpVisibility && <OverlayWrapper onClick={onHelpClick} />}
    </>
  );
};

NavigationBar.propTypes = {
  onProjectsItemClick: PropTypes.func,
  onPrintersItemClick: PropTypes.func,
  onToolsItemClick: PropTypes.func,
  withLogo: PropTypes.bool,
};

export default NavigationBar;
