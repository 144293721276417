import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import useDesign from '@hooks/useDesign';
import {
  updateBrimLineVisibility,
  updateCustomLineDataSelection,
  updateInfillLineVisibility,
  updateInnerWallLineVisibility,
  updateMillingLineVisibility,
  updateOuterWallLineVisibility,
  updateSkinLineVisibility,
  updateSupportInterfaceLineVisibility,
  updateSupportLineVisibility,
} from '@actions/conceptActions';
import {
  getCustomLineData,
  getCustomLineDataSelection,
  getIsShowingBrim,
  getIsShowingInfill,
  getIsShowingInnerWall,
  getIsShowingMilling,
  getIsShowingOuterWall,
  getIsShowingSkin,
  getIsShowingSupport,
  getIsShowingSupportInterface,
  getLineData,
} from '@selectors/conceptSelectors';
import { LineData } from '@constants/lineData';
import { FileTypes } from '@constants/fileTypes';
import { MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX } from '@components/1-atoms/MenuListItem';

const OUTER_LINE_TYPE = 'OUTER';
const INNER_LINE_TYPE = 'INNER';
const SKIN_LINE_TYPE = 'SKIN';
const FILL_LINE_TYPE = 'FILL';
const SUPPORT_LINE_TYPE = 'SUPPORT';
const SUPPORT_INTERFACE_LINE_TYPE = 'SUPPORT_INTERFACE';
const BRIM_LINE_TYPE = 'BRIM';
const MILLING_LINE_TYPE = 'MILLING';
const colorPanel = [
  '#00BEBE',
  '#D200D2',
  '#5A64FF',
  '#d59715',
  '#03C700',
  '#026E00',
  '#D54600',
  '#9356E4',
];

export default function useLineDataSelectionTool() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const lineData = useSelector(getLineData());
  const customLineData = useSelector(getCustomLineData());
  const customLineDataSelection = useSelector(getCustomLineDataSelection());
  const isShowingOuterWall = useSelector(getIsShowingOuterWall());
  const isShowingInnerWall = useSelector(getIsShowingInnerWall());
  const isShowingSkin = useSelector(getIsShowingSkin());
  const isShowingInfill = useSelector(getIsShowingInfill());
  const isShowingSupport = useSelector(getIsShowingSupport());
  const isShowingSupportInterface = useSelector(getIsShowingSupportInterface());
  const isShowingBrim = useSelector(getIsShowingBrim());
  const isShowingMilling = useSelector(getIsShowingMilling());

  const { design, designGeometryData } = useDesign();

  const customSelectionValues = customLineData?.selectionValues;
  const isDesignSelectionType = useCallback(
    (lineType) => {
      return designGeometryData?.displayData?.includes?.(lineType);
    },
    [designGeometryData],
  );

  const dropDownMenuItems = useMemo(() => {
    const itemsList = [];

    if (customSelectionValues?.length) {
      return customSelectionValues.map((selectionValue, idx) => {
        return {
          id: `line-types-tool-${selectionValue}-action`,
          label: selectionValue,
          leadingIconColor: colorPanel[idx % colorPanel.length],
          leadingIconName: 'Dot_1',
          endingButton: {
            type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
            checked: customLineDataSelection.includes(selectionValue),
            onChange: () =>
              dispatch(updateCustomLineDataSelection(selectionValue)),
          },
        };
      });
    }

    if (isDesignSelectionType(OUTER_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${OUTER_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.outerwall',
          defaultMessage: 'Outer Wall',
        }),
        leadingIconColor: '#00BEBE',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingOuterWall,
          onChange: () => dispatch(updateOuterWallLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(INNER_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${INNER_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.innerwall',
          defaultMessage: 'Inner Wall',
        }),
        leadingIconColor: '#D200D2',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingInnerWall,
          onChange: () => dispatch(updateInnerWallLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(SKIN_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${SKIN_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.skin',
          defaultMessage: 'Skin',
        }),
        leadingIconColor: '#5A64FF',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingSkin,
          onChange: () => dispatch(updateSkinLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(FILL_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${FILL_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.infill',
          defaultMessage: 'Infill',
        }),
        leadingIconColor: '#d59715',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingInfill,
          onChange: () => dispatch(updateInfillLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(SUPPORT_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${SUPPORT_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.support',
          defaultMessage: 'Support',
        }),
        leadingIconColor: '#03C700',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingSupport,
          onChange: () => dispatch(updateSupportLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(SUPPORT_INTERFACE_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${SUPPORT_INTERFACE_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.supportinterface',
          defaultMessage: 'Support Interface',
        }),
        leadingIconColor: '#026E00',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingSupportInterface,
          onChange: () => dispatch(updateSupportInterfaceLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(BRIM_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${BRIM_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.brimraft',
          defaultMessage: 'Brim/Raft',
        }),
        leadingIconColor: '#D54600',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingBrim,
          onChange: () => dispatch(updateBrimLineVisibility()),
        },
      });
    }

    if (isDesignSelectionType(MILLING_LINE_TYPE)) {
      itemsList.push({
        id: `line-types-tool-${MILLING_LINE_TYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.text.milling',
          defaultMessage: 'Milling',
        }),
        leadingIconColor: '#9356E4',
        leadingIconName: 'Dot_1',
        endingButton: {
          type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          checked: isShowingMilling,
          onChange: () => dispatch(updateMillingLineVisibility()),
        },
      });
    }
    return itemsList;
  }, [
    intl,
    dispatch,
    isDesignSelectionType,
    isShowingOuterWall,
    isShowingInnerWall,
    isShowingSkin,
    isShowingInfill,
    isShowingSupport,
    isShowingSupportInterface,
    isShowingBrim,
    isShowingMilling,
    customSelectionValues,
    customLineDataSelection,
  ]);

  const action = useMemo(() => {
    const hasLineTypes = [
      isDesignSelectionType(OUTER_LINE_TYPE),
      isDesignSelectionType(INNER_LINE_TYPE),
      isDesignSelectionType(SKIN_LINE_TYPE),
      isDesignSelectionType(FILL_LINE_TYPE),
      isDesignSelectionType(SUPPORT_LINE_TYPE),
      isDesignSelectionType(SUPPORT_INTERFACE_LINE_TYPE),
      isDesignSelectionType(BRIM_LINE_TYPE),
      isDesignSelectionType(MILLING_LINE_TYPE),
    ].includes(true);
    const isSelectionTypeDesign =
      (lineData === LineData.LINETYPE ||
        customLineData?.selectionValues?.length) &&
      (design?.filetype === FileTypes.gcode ||
        design?.filetype === FileTypes.aib);
    const disableAction = !(hasLineTypes && isSelectionTypeDesign);

    const selectedColors = dropDownMenuItems
      ?.filter(({ endingButton }) => endingButton?.checked)
      .map(({ leadingIconColor }) => leadingIconColor);

    return {
      id: 'line-types-tool-action',
      disabled: disableAction,
      name: isEmpty(selectedColors)
        ? intl.formatMessage({
            id: 'displaymodepanel.text.select_type',
            defaultMessage: 'Select type',
          })
        : undefined,
      selectedColors,
      dropDownMenuItems,
    };
  }, [
    dropDownMenuItems,
    intl,
    lineData,
    design,
    isDesignSelectionType,
    customLineData,
  ]);

  return {
    action,
  };
}
