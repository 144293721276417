export const checkPredecessorsRecursively = (
  graph,
  nodeId,
  targetId,
  visited = new Set(),
) => {
  if (!graph) return false;

  //THE GRAPH IS REVERSED, that's why we are technically checking the successors
  const successors = graph.getSuccessors(nodeId);
  if (!successors) return false;

  const successorIds = successors.map((successor) => successor.getId());

  if (successorIds.includes(targetId)) {
    return true;
  }

  visited.add(nodeId);

  for (const successorId of successorIds) {
    if (!visited.has(successorId)) {
      if (checkPredecessorsRecursively(graph, successorId, targetId, visited)) {
        return true;
      }
    }
  }

  return false;
};

export const getPredecessorsRecursively = (
  graph,
  operatorId,
  visited = new Set(),
  isInitialCall = true,
) => {
  if (!graph) return visited;

  if (visited.has(operatorId)) return visited;

  if (!isInitialCall) {
    visited.add(operatorId); // Add only if it's not the initial node
  }

  const predecessors = graph.getSuccessors(operatorId); // Since the graph is reversed, successors are predecessors

  if (!predecessors || predecessors.length === 0) return visited;

  predecessors.forEach((predId) => {
    getPredecessorsRecursively(graph, predId, visited, false);
  });

  return visited;
};
