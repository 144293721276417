import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import useSnackbar from '@hooks/useSnackbar';
import { logError } from '@utils/logs';

export default function useOrganizationList() {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const { deleteUserMutation } = useOrganizationMutations();
  const { showSnackbar } = useSnackbar();

  const getOrganizationDropDownMenuActions = useCallback(
    (user = {}) => {
      const actions = [
        {
          leadingIconName: 'info_0',
          label: intl.formatMessage({
            id: 'general.more.info',
            defaultMessage: 'More info',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.USER_INFO, {
              user: user,
            }),
        },
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.edit',
            defaultMessage: 'Edit',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.EDIT_USER, {
              user: user,
            }),
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-user-dialog',
              title: intl.formatMessage({
                id: 'deleteuser.dialog.title',
                defaultMessage: 'Delete User',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'deleteuser.dialog.subtitle',
                  defaultMessage:
                    'The user named <b>{userDisplayName}</b> will be permanently deleted and will no longer have access to this organization. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  userDisplayName: user?.name,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: async () => {
                try {
                  await deleteUserMutation.mutateAsync({
                    username: user?.username,
                    id: user?.id,
                  });
                  showSnackbar({
                    text: intl.formatMessage({
                      id: 'deleteuser.dialog.snackbar.label',
                      defaultMessage: 'User successful deleted',
                    }),
                  });
                } catch (e) {
                  logError(e);
                }
              },
            });
          },
        },
      ];

      return actions;
    },
    [showSnackbar, deleteUserMutation, showDialog, intl],
  );

  return {
    getOrganizationDropDownMenuActions,
  };
}
