import styled, { css } from 'styled-components';
import SVG from '@components/1-atoms/SVG';
import {
  TOOLBAR_CONTENT_ALIGN_CENTER,
  TOOLBAR_CONTENT_ALIGN_RIGHT,
} from './Toolbar';
import { flexCenterVertical, flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  ${flexColumn}
  align-items: flex-start;

  ${({ alignContent, theme: { colors, spacing, borderRadius } }) => css`
    border-radius: ${borderRadius.large};
    background-color: ${colors.surfaceContainerLow};
    padding: ${spacing.level4};

    ${when(
      alignContent === TOOLBAR_CONTENT_ALIGN_CENTER,
      `
        align-items: center;
      `,
    )}

    ${when(
      alignContent === TOOLBAR_CONTENT_ALIGN_RIGHT,
      `
        align-items: flex-end;
      `,
    )}
  `}
`;

export const LogoButton = styled.span`
  width: 48px;
  height: 48px;
  ${flexCenterVertical}
  flex-shrink: 0;
  cursor: pointer;

  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.level4};
  `}
`;

export const Logo = styled(SVG).attrs(({ theme: { isLightTheme } }) => ({
  name: isLightTheme ? 'logo-white' : 'logo',
}))``;

export const ImgLogo = styled.img`
  max-height: 48px;
  flex-shrink: 0;
`;

const iconButtonsList = css`
  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
  `}
`;

export const Header = styled.div`
  ${flexColumn}
  ${iconButtonsList}

  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.level4};
  `}
`;

export const Body = styled.div`
  ${flexColumn}
  ${iconButtonsList}
  overflow: auto;
  scrollbar-gutter: stable;
  scrollbar-width: none;
`;

export const PreFooter = styled.div`
  ${flexColumn}
  ${iconButtonsList}
  margin-bottom: auto;

  ${({ theme: { spacing } }) => css`
    margin-top: ${spacing.level3};
  `}
`;

export const Footer = styled.div`
  ${flexColumn}
  ${iconButtonsList}
  margin-top: auto;
`;

export const DraggableIconButtonWrapper = styled.div``;

export const DraggableIconButtonWrapperClone = styled.div`
  transform: none !important;
  pointer-events: none;

  & ~ div {
    transform: none !important;
  }
`;

export const DndPlaceholder = styled.div`
  display: none;
`;
