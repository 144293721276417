import {
  ContentWrapper,
  Frame,
  Wrapper,
} from '@pages/Preferences/Preferences.styled';
import React, { useCallback, useMemo } from 'react';
import SettingSwitch from '@components/2-molecules/SettingSwitch';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserPreference } from '@actions/loginActions';
import { getCurrentUser } from '@selectors/loginSelectors';
import { useIntl } from 'react-intl';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import useSnackbar from '@hooks/useSnackbar';
import { Formik } from 'formik';
import { logError } from '@utils/logs';

const Preferences = () => {
  const currentUser = useSelector(getCurrentUser());
  const { updateUserPreferenceMutation } = useOrganizationMutations();
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const intl = useIntl();

  const formInitialValues = useMemo(() => {
    return currentUser?.userPreferences || {};
  }, [currentUser]);

  const onFormSubmit = useCallback(
    async (values) => {
      try {
        await updateUserPreferenceMutation.mutateAsync({
          userPreference: {
            preferenceKey: 'USE_FULL_SCREEN_OPERATORS',
            preferenceValue: values['USE_FULL_SCREEN_OPERATORS'],
          },
        });

        showSnackbar({
          text: intl.formatMessage({
            id: 'settings.preferences.operator.view.snackbar.label',
            defaultMessage:
              'Full-Screen operator view was successfully updated',
          }),
        });
      } catch (e) {
        logError(e);
      }
    },
    [showSnackbar, intl, updateUserPreferenceMutation],
  );

  return (
    <Wrapper>
      <ContentWrapper>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          onSubmit={onFormSubmit}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Frame>
              <SettingSwitch
                dataTestId={`settings-page__preferences__full-screen-operator-view`}
                label={intl.formatMessage({
                  id: 'settings.preferences.operator.view.label',
                  defaultMessage: 'Full-screen operator view',
                })}
                supportingText={intl.formatMessage({
                  id: 'settings.preferences.operator.view.description',
                  defaultMessage:
                    'Automatically Display Expanded Operator Settings on Addition',
                })}
                switchProps={{
                  enabled: values['USE_FULL_SCREEN_OPERATORS'],
                  onChange: () => {
                    const fieldValue = values['USE_FULL_SCREEN_OPERATORS'];

                    dispatch(
                      setCurrentUserPreference(
                        'USE_FULL_SCREEN_OPERATORS',
                        !fieldValue,
                      ),
                    );
                    setFieldValue('USE_FULL_SCREEN_OPERATORS', !fieldValue);
                    handleSubmit();
                  },
                }}
              />
            </Frame>
          )}
        </Formik>
      </ContentWrapper>
    </Wrapper>
  );
};

Preferences.propTypes = {};

export default Preferences;
