import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Field as FormikField } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import useDialog from '@hooks/useDialog';
import usePrinter from '@hooks/printers/usePrinter';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { ROUTES } from '@constants/router';
import { Printer } from '@app/lib/Printer';
import { getAllowedRobotDefinitionsByBrand } from '@components/Printers/SettingsUtils';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import Field from '@components/1-atoms/Field';
import DropDownField from '@components/1-atoms/DropDownField';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { Fields } from './CreatePrinterDialog.styled';
import { CREATE_PRINTER_SUCCEEDED } from '@constants/actionTypes';

const MODAL_ID = ModalDataTypes.CREATE_PRINTER;

const CreatePrinterDialog = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { hideDialog } = useDialog();
  const { createPrinterMutation } = usePrinterMutations();

  const { getAllPrinterDefinitions, getRobotBrandDefinitions } = usePrinter();
  const {
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  } = getAllPrinterDefinitions();
  const robotBrandDefinitions = getRobotBrandDefinitions();

  const formInitialValues = useMemo(
    () => ({
      name: 'Untitled printer',
      machine: null,
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(
            intl.formatMessage({
              id: 'createprinter.printername.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          )
          .min(
            5,
            intl.formatMessage({
              id: 'createprinter.printername.validation.min',
              defaultMessage: 'Name must be at least 5 characters long',
            }),
          )
          .max(
            256,
            intl.formatMessage({
              id: 'createprinter.printername.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          ),
        machine: Yup.object()
          .nullable()
          .required(
            intl.formatMessage({
              id: 'createprinter.machine.validation.required',
              defaultMessage: 'Please select an option',
            }),
          ),
      }),
    [intl],
  );

  const getMachineDropDownMenuItems = useCallback(
    (values) =>
      Object.keys(robotBrandDefinitions)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .reduce((acc, robotBrand) => {
          const robots = getAllowedRobotDefinitionsByBrand(
            machineDefinitions,
            robotBrand,
          );

          const menutItems = robots.map((robot) => ({
            label: robot.displayName,
            description: robotBrand,
            selected: values?.machine?.value === robot.displayName,
            formFieldValue: {
              label: robot.displayName,
              value: robot.displayName,
            },
          }));

          return [...acc, ...menutItems];
        }, []),
    [machineDefinitions, robotBrandDefinitions],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values) =>
      (async () => {
        const { name, machine } = values;

        const printerModel = Printer.createFromMachineType(
          machine?.value,
          machineDefinitions,
          extruderDefinitions,
          printingBedDefinitions,
          plinthDefinitions,
          enclosureDefinitions,
        );
        const printer = await createPrinterMutation.mutateAsync({
          ...printerModel.printerProps,
          name,
        });

        dispatch({
          type: CREATE_PRINTER_SUCCEEDED,
          payload: printer,
        });

        const navigationPath = generatePath(ROUTES.PRINTER, {
          printerId: printer?.id,
        });

        history.push(navigationPath);
      })(),
    [
      createPrinterMutation,
      dispatch,
      enclosureDefinitions,
      extruderDefinitions,
      history,
      machineDefinitions,
      plinthDefinitions,
      printingBedDefinitions,
    ],
  );

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <DialogPortal
          renderAsForm
          dataTestId="create-machine"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'general.add',
            defaultMessage: 'Add',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'createprinter.header.title',
              defaultMessage: 'Add Printer',
            })}
          />

          <Fields>
            <FormikField
              autoFocus
              component={Field}
              dataTestId="create-printer-name-field"
              disabled={isSubmitting}
              name="name"
              label={intl.formatMessage({
                id: 'createprinter.printername.label',
                defaultMessage: 'Name',
              })}
              placeholder={intl.formatMessage({
                id: 'createprinter.printername.placeholder',
                defaultMessage: 'Untitled Printer',
              })}
            />

            <FormikField
              component={DropDownField}
              dataTestId="create-machine__machine-dropdown-field"
              disabled={isSubmitting}
              name="machine"
              label={intl.formatMessage({
                id: 'createprinter.machine.label',
                defaultMessage: 'Machine',
              })}
              placeholder={intl.formatMessage({
                id: 'createprinter.machine.placeholder',
                defaultMessage: 'Select Machine',
              })}
              dropDownMenuItems={getMachineDropDownMenuItems(values)}
            />
          </Fields>
        </DialogPortal>
      )}
    </Formik>
  );
};

CreatePrinterDialog.propTypes = {};

export default CreatePrinterDialog;
