import React, { useMemo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import useSnackbar from '@hooks/useSnackbar';
import { b, br } from '@utils/render';
import { useDispatch } from 'react-redux';
import { deletePrinterSuccess } from '@app/actions/printerActions';

const MODAL_ID = ModalDataTypes.DELETE_PRINTER;
const DeletePrinterDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { deletePrinterMutation } = usePrinterMutations();
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const { printerInUseWorkflows } = usePrinterQueries({
    printerId: dialogData?.printerId,
  });
  const { refetch: workflowsQueryRefetch } = printerInUseWorkflows;
  useEffect(() => {
    workflowsQueryRefetch();
  }, [workflowsQueryRefetch]);

  const workflows = printerInUseWorkflows?.data;
  const inUseDialog = workflows?.length > 0;
  const formattedWorkflows = workflows?.map((workflow) => (
    <li key={workflow}>{workflow}</li>
  ));

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const handlePrinterDeleted = useCallback(() => {
    showSnackbar({
      text: intl.formatMessage({
        id: 'deleteprinter.dialog.snackbar.label',
        defaultMessage: 'Machine successfully deleted',
      }),
    });
    if (workflows?.length === 0) {
      history.push(generatePath(ROUTES.PRINTERS));
    }
    dispatch(deletePrinterSuccess(dialogData?.printerId));
    handleClose();
  }, [
    intl,
    showSnackbar,
    handleClose,
    workflows,
    history,
    dispatch,
    dialogData?.printerId,
  ]);

  const handleConfirm = useCallback(() => {
    if (workflows?.length === 0) {
      return deletePrinterMutation
        .mutateAsync(dialogData?.printerId)
        .then(handlePrinterDeleted);
    } else {
      return handleClose();
    }
  }, [
    workflows,
    dialogData?.printerId,
    deletePrinterMutation,
    handlePrinterDeleted,
    handleClose,
  ]);

  const dialogTitle = intl.formatMessage({
    id: `deleteprinter.dialog.${inUseDialog ? 'in.use.' : ''}title`,
  });
  const primaryButtonText = intl.formatMessage({
    id: inUseDialog ? 'dialog.message.ok' : 'general.confirm',
  });
  const secondaryButtonLabel = inUseDialog
    ? ''
    : intl.formatMessage({
        id: 'general.cancel',
      });
  const dialogText = intl.formatMessage(
    {
      id: `deleteprinter.dialog.${inUseDialog ? 'in.use.' : ''}subtitle`,
    },
    {
      printerName: dialogData?.printerName,
      workflows: formattedWorkflows,
      b,
      br,
    },
  );

  return (
    !printerInUseWorkflows.isFetching && (
      <DialogPortal
        dataTestId="delete-printer-dialog"
        dialogId={MODAL_ID}
        subtitle={dialogText}
        primaryButtonLabel={primaryButtonText}
        secondaryButtonLabel={secondaryButtonLabel}
        onPrimaryButtonClick={handleConfirm}
        onClose={handleClose}
        loading={deletePrinterMutation?.isLoading}
      >
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={dialogTitle}
          subtitle={dialogText}
        />
      </DialogPortal>
    )
  );
};

export default DeletePrinterDialog;
