export default {
  baseUrl: window.apiServerUrl,
  baseUrlws: window.apiWsServerUrl,
  minimumBackwardsCompatibleVersion: '999.999.999',
  statsigClientKey: 'client-frTDu288IZWqaGpYOqpzwUm8BXPFxY3IfjUZeal48nO',

  /* Feature Flags */
  features: {
    talkToAi: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    workflowAnnotations: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    knowledgeCentre: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    quickStart: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    analyticsDefectSensitivity: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    clippingTool: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    fullScreenOperator: {
      enabled: false,
      users: '*',
      organizations: '*',
      roles: '*',
    },
    operatorCategories: {
      enabled: true,
      users: '*',
      organizations: '*',
      roles: '*',
    },
  },
  /* End of Feature Flags */
};
