import { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ROUTES } from '@constants/router';
import client from '@api/client';
import endpoints from '@api/endpoints';

const PING_SUCCESS_STATUS_CODE = 204;

const useHostAvailabilityCheck = (
  endpoint = endpoints.statusPing,
  interval = 6000,
) => {
  const intl = useIntl();
  const [isHostAvailable, setIsHostAvailable] = useState(true);
  const [previousRoute, setPreviousRoute] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const intervalIdRef = useRef(null);
  const isNotFoundPage = location.pathname === ROUTES.NOT_FOUND;

  const checkHostAvailability = useCallback(async () => {
    try {
      if (DESKTOP_APP) {
        const response = await client.get(endpoint, {
          ignoreGlobalErrorHandler: true,
        });

        if (response.status !== PING_SUCCESS_STATUS_CODE) {
          throw new Error('Host not responding');
        }
      }

      setIsHostAvailable(true);

      if (isNotFoundPage && previousRoute) {
        history.replace(previousRoute);
        setPreviousRoute(null);
      }
    } catch (error) {
      setIsHostAvailable(false);

      if (!isNotFoundPage) {
        setPreviousRoute(location.pathname);
      }

      history.replace(ROUTES.NOT_FOUND, {
        icon: 'help_0',
        title: intl.formatMessage({
          id: 'notfoundpage.offline.title',
          defaultMessage: 'Something went wrong',
        }),
        description: intl.formatMessage({
          id: 'notfoundpage.offline.description',
          defaultMessage:
            "We're sorry, but it looks like something didn't go as planned. You can: refresh the page or contact our support team if the issue persists.",
        }),
        primaryButtonLabel: intl.formatMessage({
          id: 'notfoundpage.offline.button.title',
          defaultMessage: 'Try again',
        }),
        reloadOnPrimaryButtonClick: true,
        previousRoute: previousRoute,
      });
    }
  }, [
    isNotFoundPage,
    previousRoute,
    endpoint,
    history,
    intl,
    location.pathname,
  ]);

  useEffect(() => {
    checkHostAvailability();

    intervalIdRef.current = setInterval(checkHostAvailability, interval);

    return () => clearInterval(intervalIdRef.current);
  }, [checkHostAvailability, interval]);

  return isHostAvailable;
};

export default useHostAvailabilityCheck;
