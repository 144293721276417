import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { machineFiles } from '@constants/printers/componentFiles';
import {
  defaultGantryProperties,
  defaultSixAxisRobotProperties,
  robotTypeDefinitions,
} from '@constants/printers/machineDefaultProperties';
import * as Yup from 'yup';
import { gantryComponentTypeDefinitions } from '@constants/printers/gantryComponentTypeDefinitions';
import { DiagramRowComponent } from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { Axis6Settings } from '@components/Printers/GeneralPrinterSetting/MachineTypeSetting/Axis6Settings';
import { Axis3Settings } from '@components/Printers/GeneralPrinterSetting/MachineTypeSetting/Axis3Settings';
import { getFormattedOption } from '@components/Printers/SettingsUtils';
import useDialog from '@hooks/useDialog';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { generateSingleFileUpload } from '@utils/filesUpload';
import usePrinter from '@app/hooks/printers/usePrinter';

export const MachineTypeSetting = () => {
  const intl = useIntl();
  const { values, initialValues, setFieldValue } = useFormikContext();
  const machineSettingValues = values['machineTypeSetting'];
  const { showDialog } = useDialog();
  const { getRobotBrandDefinitions } = usePrinter();
  const robotBrandDefinitions = getRobotBrandDefinitions();

  const [numberOfRows, setNumberOfRows] = useState(
    machineSettingValues?.machineType?.number || 0,
  );

  const setValue = useMemo(
    () => (field, value) => {
      setFieldValue(`machineTypeSetting.${field}`, value);
    },
    [setFieldValue],
  );

  const changeMachineType = useCallback(
    (option) => {
      const machineType = robotTypeDefinitions[option.value];
      if (machineSettingValues.machineType) {
        showDialog(ModalDataTypes.PROMPT, {
          dataTestId: 'change-machine-type-warning-dialog',
          title: intl.formatMessage({
            id: 'dialogs.title.attention',
            defaultMessage: 'Attention',
          }),
          subtitle: intl.formatMessage({
            id: 'printers.settings.machine.type.warning',
            defaultMessage: 'printers.settings.machine.type.warning',
          }),
          secondaryButtonLabel: '',
        });
      }

      const reinitializedFiles = Object.fromEntries(
        machineFiles(machineType.number + 1).map(({ value, label }) => [
          value,
          {
            label,
            file: null,
            fileName: null,
          },
        ]),
      );
      setValue('files', reinitializedFiles);
      if (machineType.number === 3) {
        //gantry
        setValue('machineProperties', defaultGantryProperties);
        setValue('brand', {
          value: 'GENERIC',
          label: 'GENERIC',
        });
      }
      if (machineType.number === 6) {
        //robot
        setValue('machineProperties', defaultSixAxisRobotProperties);
        setValue('brand', null);
      }
      setNumberOfRows(machineType.number);
    },
    [showDialog, intl, machineSettingValues, setValue],
  );

  const getFormattedOptions = useMemo(
    () => (definitions, changeMachineType) =>
      Object.values(definitions).map((option) =>
        getFormattedOption(option, changeMachineType),
      ),
    [],
  );

  const options = getFormattedOptions(robotTypeDefinitions, changeMachineType);
  const brandOptions =
    machineSettingValues.machineType &&
    Object.keys(robotBrandDefinitions)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .filter((key) => robotBrandDefinitions[key].isRobotBrand === true)
      .map((option) => ({
        label: option,
        formFieldValue: {
          label: option,
          value: option,
        },
      }));

  const renderUploadRows = () => {
    if (machineSettingValues.files) {
      return Object.keys(machineSettingValues.files).map((axisKey) => {
        const entry = machineSettingValues.files[axisKey];
        return generateSingleFileUpload(
          entry,
          'printer-components-machine-type__category-upload',
          axisKey,
          (file) => {
            setValue(`files.${axisKey}.file`, file);
            setValue(`files.${axisKey}.fileName`, file.name + new Date());
          },
          {
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              `machineTypeSetting.files.${axisKey}.fileName`,
            ),
          },
        );
      });
    }
  };

  const renderKinematicsComponent = () => {
    switch (numberOfRows) {
      case 3:
        return <Axis3Settings />;

      case 6:
        return <Axis6Settings />;

      default:
        return null;
    }
  };

  const renderDiagram = (machineType) => {
    switch (machineType) {
      case robotTypeDefinitions.SIX_AXIS_ROBOT.value:
        return (
          <DiagramRowComponent
            src={`/img/digitalTwin/machine/diagram_${machineType}kinematics.svg`}
            description_key={`printers.settings.machine.diagram.kinematics.description.label`}
          />
        );
    }
  };

  return (
    <>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'printers.settings.information.label',
        })}
        dataTestId={`printer-components-machine-type__category-information`}
        expand
        withDividerBottom={
          !!(
            machineSettingValues.machineType &&
            machineSettingValues.machineType?.value !==
              robotTypeDefinitions.SIX_AXIS_ROBOT.value
          )
        }
      >
        <FormikField
          component={SettingDropDown}
          label={intl.formatMessage({
            id: 'printers.settings.machine.type.label',
          })}
          dataTestId={`printer-components-machine-type__category-information__setting-machine-type`}
          name={'machineTypeSetting.machineType'}
          dropDownField={{
            dropDownMenuItems: options,
            fullWidthDropDownMenu: false,
            placeholder: intl.formatMessage({
              id: 'printers.settings.machine.type.placeholder',
              defaultMessage: 'Machine Type',
            }),
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              'machineTypeSetting.machineType',
            ),
          }}
        />
        {machineSettingValues.machineType?.value ===
          robotTypeDefinitions.SIX_AXIS_ROBOT.value && (
          <FormikField
            component={SettingDropDown}
            label={intl.formatMessage({
              id: 'printers.settings.brand.label',
            })}
            dataTestId={`printer-components-machine-type__category-information__setting-brand`}
            name={'machineTypeSetting.brand'}
            dropDownField={{
              dropDownMenuItems: brandOptions,
              fullWidthDropDownMenu: false,
              placeholder: intl.formatMessage({
                id: 'printers.settings.brand.label',
                defaultMessage: 'Brand',
              }),
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                'machineTypeSetting.brand',
              ),
            }}
          />
        )}
      </SettingsCategory>
      {machineSettingValues.machineType && (
        <>
          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.upload.title',
            })}
            dataTestId={`printer-components-machine-type__category-upload`}
            expand
            withDividerBottom
          >
            <DiagramRowComponent
              src={`/img/digitalTwin/machine/diagram_${machineSettingValues.machineType.value}.svg`}
              description_key={`printers.settings.machine.diagram.${machineSettingValues.machineType.value}.description.label`}
              second_description_key={'printers.settings.upload.export.label'}
            />
            {renderUploadRows()}
          </SettingsCategory>

          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.machine.type.kinematics.title',
            })}
            dataTestId={`printer-components-machine-type__category-kinematics`}
            expand
          >
            {renderDiagram(machineSettingValues.machineType.value)}
            {renderKinematicsComponent()}
          </SettingsCategory>
        </>
      )}
    </>
  );
};

export const MachineTypeSettingValidationSchema = Yup.object().shape({
  machineType: Yup.object().shape({
    value: Yup.string().required('Machine Type is required'),
  }),
  brand: Yup.object().shape({
    value: Yup.string().required('Brand is required'),
  }),
  files: Yup.object().test(
    'areFilesValid',
    'All files must be provided',
    (files) => {
      return Object.values(files).every((axis) => axis.file !== null);
    },
  ),
  machineProperties: Yup.object().when(['machineType'], {
    is: (machineType) => machineType?.value,
    then: Yup.object().test(
      'arePropertiesValid',
      'All properties must be provided',
      (machineProperties, { parent }) => {
        let expectedProperties = {};
        const machineType = parent.machineType.value;
        if (machineType === robotTypeDefinitions.SIX_AXIS_ROBOT.value) {
          expectedProperties = Object.keys(defaultSixAxisRobotProperties);
        }
        if (machineType === robotTypeDefinitions.GANTRY.value) {
          expectedProperties = Object.keys(defaultGantryProperties);
        }
        if (expectedProperties) {
          return expectedProperties.every((property) =>
            Object.hasOwn(machineProperties, property),
          );
        }
        return false;
      },
    ),
  }),
});

const getAxisAttachment = (machineDefinitionResponse, axis) => {
  if (machineDefinitionResponse.BED.includes(axis))
    return gantryComponentTypeDefinitions.BED;
  if (machineDefinitionResponse.EXTRUDER.includes(axis))
    return gantryComponentTypeDefinitions.EXTRUDER;
  if (machineDefinitionResponse.AXIS_1.componentParents.includes(axis))
    return gantryComponentTypeDefinitions.AXIS_1;
  if (machineDefinitionResponse.AXIS_2.componentParents.includes(axis))
    return gantryComponentTypeDefinitions.AXIS_2;
  if (machineDefinitionResponse.AXIS_3.componentParents.includes(axis))
    return gantryComponentTypeDefinitions.AXIS_3;
  return {
    value: 'WORLD',
    label: 'WORLD',
  };
};

export const initDataMachineType = (
  machineDefinitionResponse,
  initialValues,
) => {
  let machineProperties = {};
  if (
    machineDefinitionResponse.machineType === robotTypeDefinitions.GANTRY.value
  ) {
    machineProperties = {
      basisVector1X: machineDefinitionResponse.AXIS_1.direction.x.toString(),
      basisVector2X: machineDefinitionResponse.AXIS_2.direction.x.toString(),
      basisVector3X: machineDefinitionResponse.AXIS_3.direction.x.toString(),
      basisVector1Y: machineDefinitionResponse.AXIS_1.direction.y.toString(),
      basisVector2Y: machineDefinitionResponse.AXIS_2.direction.y.toString(),
      basisVector3Y: machineDefinitionResponse.AXIS_3.direction.y.toString(),
      basisVector1Z: machineDefinitionResponse.AXIS_1.direction.z.toString(),
      basisVector2Z: machineDefinitionResponse.AXIS_2.direction.z.toString(),
      basisVector3Z: machineDefinitionResponse.AXIS_3.direction.z.toString(),
      axis1Attachment: getAxisAttachment(
        machineDefinitionResponse,
        gantryComponentTypeDefinitions.AXIS_1.value,
      ),
      axis2Attachment: getAxisAttachment(
        machineDefinitionResponse,
        gantryComponentTypeDefinitions.AXIS_2.value,
      ),
      axis3Attachment: getAxisAttachment(
        machineDefinitionResponse,
        gantryComponentTypeDefinitions.AXIS_3.value,
      ),
    };
  } else {
    const propertiesToExclude = [
      'machineType',
      'modelUrls',
      'name',
      'id',
      'brand',
    ];
    machineProperties = Object.keys(machineDefinitionResponse)
      .filter((key) => !propertiesToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = machineDefinitionResponse[key];
        return obj;
      }, {});
  }

  return {
    ...initialValues,
    brand: {
      label: machineDefinitionResponse.brand,
      value: machineDefinitionResponse.brand,
    },
    machineType: robotTypeDefinitions[machineDefinitionResponse.machineType],
    machineProperties: machineProperties,
    files: Object.fromEntries(
      machineFiles(
        robotTypeDefinitions[machineDefinitionResponse.machineType].number + 1,
      ).map(({ value, label }) => [
        value,
        {
          label,
          file: new File(
            [],
            machineDefinitionResponse.modelUrls[value].fileName,
          ),
          fileName: machineDefinitionResponse.modelUrls[value].fileName,
        },
      ]),
    ),
  };
};
