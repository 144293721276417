import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, pick, startCase } from 'lodash';
import {
  getSelectedOperatorOutputId,
  getLastAddedOperatorId,
} from '@selectors/conceptSelectors';
import {
  freezeOperatorOutput,
  unfreezeOperatorOutput,
  resetLastAddedOperator,
  exitToolpathSimulation,
} from '@actions/conceptActions';
import useSnackbar from '@hooks/useSnackbar';
import useDialog from '@hooks/useDialog';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import useOperator from '@hooks/operators/useOperator';
import useFile from '@hooks/files/useFile';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { TOOLPATH_GENERATOR_OPERATOR_NAME } from '@constants/operatorsConstants';
import { MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON } from '@components/1-atoms/MenuListItem';
import { WORKFLOW_SETTING_BAR_ID } from '@containers/WorkflowSetting';
import { resetActiveCanvasSelectionInput } from '@app/reducers/workflowSlice';
import useCanvasSelection from '../canvasselection/useCanvasSelection';
import { logError } from '@utils/logs';

export const CORE_OPERATORS_CATEGORY_NAME = 'Core';
export const CORE_OPERATORS_ORDER = [
  'Load',
  'Create',
  'Transform',
  'Slice',
  'Optimise',
  'Filter',
  'Edit',
  'Sort',
  'Seams',
  'Combine',
  'Toolpath',
  'Milling',
];

export const OPERATOR_ICONS = {
  combine: 'combine_0',
  create: 'shapes_0',
  edit: 'edit_0',
  filter: 'filter_alt_0',
  load: 'draft_0',
  optimise: 'optimise_0',
  seams: 'seam_0',
  slice: 'texture_0',
  sort: 'sort_ascending_0',
  subtract: 'subtract_0',
  toolpath: 'toolpath_1',
  transform: 'drag_pan_0',
};

export const CATEGORY_ICONS = {
  analysis: 'query_stats_0',
  core: 'label_0',
  custom: 'discover_tune_0',
  default: 'close_0',
  legacy: 'Dot_1',
  mesh: 'mesh_0',
  plane: 'plane_0',
  point: 'point_0',
  polyline: 'travel_0',
  design: 'measure_0',
};

export default function useOperatorList() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const lastAddedOperatorId = useSelector(getLastAddedOperatorId);
  const { showSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  const { getIsWorkflowEditable, getSelectedWorkflow } = useWorkflow();
  const { refetchProjectFiles } = useFile();
  const {
    getDefaultOperators: _getDefaultOperators,
    getTemplates,
    getOperatorVisibleInputsAndOutputs,
    getIsOperatorExplodable,
    getOperatorGeometryOutputs,
    getIsOperatorOutputFrozen,
    selectOperatorOutput,
    getSelectedOperator,
    explodeOperator,
    getIsTemplateOperator,
    getWorkflowRecommendedOperators: getRecommendedOperators,
  } = useOperator();
  const { getIsCanvasSelectionActiveForOperator } = useCanvasSelection();
  const {
    favouriteCustomOperatorMutation,
    deleteCustomOperatorMutation,
    cloneOperatorMutation,
    deleteOperatorMutation,
  } = useOperatorMutations();

  const defaultOperators = _getDefaultOperators();
  const templates = getTemplates();

  const getVisibleOperators = useCallback(
    () => (defaultOperators || []).filter((operator) => operator?.visible),
    [defaultOperators],
  );

  const getOperatorCategories = useCallback(
    () => getVisibleOperators().map((operator) => operator.category),
    [getVisibleOperators],
  );

  const getOperatorsByCategories = useCallback(() => {
    const operatorCategories = getOperatorCategories();
    const visibleOperators = getVisibleOperators();

    return operatorCategories.reduce(
      (acc, categoryName) => ({
        ...acc,
        [categoryName]: visibleOperators.filter(
          (operator) => operator.category === categoryName,
        ),
      }),
      {},
    );
  }, [getOperatorCategories, getVisibleOperators]);

  const getUserFriendlyCategoryName = useCallback(
    (categoryName = '') =>
      startCase(categoryName?.split(/(_|\s)/)?.[0]?.toLocaleLowerCase() || ''),
    [],
  );

  const getCategoryIcon = useCallback(
    (categoryName = '') =>
      CATEGORY_ICONS?.[categoryName?.toLowerCase()] || CATEGORY_ICONS?.default,
    [],
  );

  const getOperatorIcon = useCallback(
    (operatorName = '') =>
      OPERATOR_ICONS?.[operatorName?.toLowerCase()] || OPERATOR_ICONS?.default,
    [],
  );

  const getCategories = useCallback(() => {
    const operatorsByCategories = getOperatorsByCategories();
    const operatorCategyNames = Object.keys(operatorsByCategories);

    return operatorCategyNames.map((originalCategoryName) => {
      const userFriendlyCategoryName =
        getUserFriendlyCategoryName(originalCategoryName);

      return {
        id: originalCategoryName,
        name: userFriendlyCategoryName,
        iconName: getCategoryIcon(userFriendlyCategoryName),
      };
    });
  }, [getOperatorsByCategories, getUserFriendlyCategoryName, getCategoryIcon]);

  const sortOperatorsByOrder = useCallback(
    (operators = [], operatorsOrder = []) =>
      operators.sort((a, b) => {
        const aIndex = operatorsOrder.indexOf(a?.name);
        const bIndex = operatorsOrder.indexOf(b?.name);

        return aIndex - bIndex;
      }),
    [],
  );

  const getCategoriesWithOperators = useCallback(() => {
    const operatorsByCategories = getOperatorsByCategories();
    const operatorCategyNames = Object.keys(operatorsByCategories);

    return operatorCategyNames.reduce((acc, categoryName) => {
      const userFriendlyCategoryName =
        getUserFriendlyCategoryName(categoryName);
      let sortedOperators = operatorsByCategories[categoryName];

      if (userFriendlyCategoryName === CORE_OPERATORS_CATEGORY_NAME) {
        sortedOperators = sortOperatorsByOrder(
          sortedOperators,
          CORE_OPERATORS_ORDER,
        );
      }

      return {
        ...acc,
        [userFriendlyCategoryName]: sortedOperators.map((operator) => {
          const templateId = operator?.templateId;
          const template = templates?.find(({ id }) => id === templateId);
          const name =
            operator?.translatedName ||
            intl.formatMessage({
              id: operator?.nameKey,
              defaultMessage: operator?.name,
            });
          const nextOperator = {
            id: operator?.name,
            draggableId: operator?.name,
            category: userFriendlyCategoryName,
            translatedName: operator?.translatedName,
            label: templateId ? operator?.name : name,
            name: operator?.name,
            nameKey: operator?.nameKey,
            summaryKey: operator?.operatorDescriptor?.summaryKey,
            summary: operator?.operatorDescriptor?.summary,
            favourite: template?.favourite,
            templateId: templateId,
            isTemplate: !!templateId,
            iconName:
              getOperatorIcon(operator?.name) ||
              getCategoryIcon(userFriendlyCategoryName),
          };

          return nextOperator;
        }),
      };
    }, {});
  }, [
    intl,
    templates,
    getOperatorsByCategories,
    getUserFriendlyCategoryName,
    getOperatorIcon,
    getCategoryIcon,
    sortOperatorsByOrder,
  ]);

  const getCategoryOperators = useCallback(
    (userFriendlyCategoryName = '') => {
      const categoriesWithOperators = getCategoriesWithOperators();

      const matchedCategoryName = Object.keys(
        categoriesWithOperators || [],
      )?.find(
        (categoryName) =>
          categoryName?.toLocaleLowerCase() ===
          userFriendlyCategoryName?.toLocaleLowerCase(),
      );

      if (!matchedCategoryName) return [];

      return categoriesWithOperators[matchedCategoryName];
    },
    [getCategoriesWithOperators],
  );

  const getWorkflowRecommendedOperators = useCallback(
    (workflow = {}) => {
      const recommendationsData = getRecommendedOperators(workflow?.id);
      const recommendations = pick(recommendationsData, [
        'suggestion1',
        'suggestion2',
        'suggestion3',
      ]);
      const hasRecommendations = Object.values(recommendations).some(
        (value) => value,
      );

      if (!hasRecommendations) return [];

      return Object.keys(recommendations || {})?.reduce(
        (acc, operatorSuggestion) => {
          const isKeyOperatorSuggestion =
            operatorSuggestion?.includes('suggestion');

          if (!isKeyOperatorSuggestion) return acc;

          const operatorName = recommendations[operatorSuggestion];
          const visibleOperators = getVisibleOperators();
          const operator = visibleOperators?.find(
            (operator) => operator?.name === operatorName,
          );
          const operatorUserFriendlyCategoryName = getUserFriendlyCategoryName(
            operator?.category,
          );

          return [
            ...acc,
            {
              id: `reccomened-operator-${operatorName}`,
              draggableId: operatorName,
              name: operatorName,
              label: operatorName,
              isRecommended: true,
              category: intl.formatMessage({
                id: 'recommendations.operator.title',
                defaultMessage: 'Ai Recommends',
              }),
              iconName:
                getOperatorIcon(operatorName) ||
                getCategoryIcon(operatorUserFriendlyCategoryName),
            },
          ];
        },
        [],
      );
    },
    [
      intl,
      getRecommendedOperators,
      getVisibleOperators,
      getOperatorIcon,
      getCategoryIcon,
      getUserFriendlyCategoryName,
    ],
  );

  const getDefaultOperators = useCallback(
    () => Object.values(getCategoriesWithOperators() || []).flat(),
    [getCategoriesWithOperators],
  );

  const getDefaultOperator = useCallback(
    (operatorName) =>
      getDefaultOperators().find((operator) => operator.name === operatorName),
    [getDefaultOperators],
  );

  const getCustomOperatorDropDownMenuActions = useCallback(
    (customOperator = {}) => {
      const templateId = customOperator?.templateId;
      const isFavourite = customOperator?.favourite;

      if (!templateId) return [];

      return [
        {
          leadingIconName: 'language_0',
          label: intl.formatMessage({
            id: 'general.favorite',
            defaultMessage: 'Favorite',
          }),
          endingButton: {
            type: 'switch',
            enabled: isFavourite,
            onChange: () => {
              favouriteCustomOperatorMutation.mutate({
                templateId,
                favourite: !isFavourite,
              });
            },
          },
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-custom-operator-dialog',
              title: intl.formatMessage({
                id: 'operatorslibrary.custom_operator.delete_dialog.title',
                defaultMessage: 'Delete custom Operator',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'operatorslibrary.custom_operator.delete_dialog.subtitle',
                  defaultMessage:
                    '<b>{operatorName}</b> will be permanently deleted. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  operatorName: customOperator?.label,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: () =>
                deleteCustomOperatorMutation.mutateAsync(templateId),
            });
          },
        },
      ];
    },
    [
      intl,
      showDialog,
      favouriteCustomOperatorMutation,
      deleteCustomOperatorMutation,
    ],
  );

  const getOperatorDropDownMenuActions = useCallback(
    (operator = {}) => {
      let actions = [];
      const operatorOutputs = getOperatorGeometryOutputs(operator);
      const operatorVisibleInputsAndOutputs =
        getOperatorVisibleInputsAndOutputs(operator);
      const isTemplateOperator = getIsTemplateOperator(operator);
      const isWorkflowEditable = getIsWorkflowEditable();

      if (!isEmpty(operatorOutputs)) {
        actions = [
          ...actions,
          ...operatorOutputs.map((output) => {
            const outputIsSelected = selectedOperatorOutputId === output?.id;
            const outputIsFrozen = getIsOperatorOutputFrozen(output.id);

            return {
              leadingIconName: outputIsSelected
                ? 'radio_button_checked_0'
                : 'radio_button_unchecked_0',
              selected: outputIsSelected,
              leadingIconColor: outputIsSelected ? 'primary' : undefined,
              label: output?.name,
              onClick: () => selectOperatorOutput(output),
              ...(output?.isGeometricOutput
                ? {
                    endingButton: {
                      type: MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
                      iconName: outputIsFrozen
                        ? 'visibility_lock_1'
                        : 'visibility_lock_0',
                      selected: outputIsFrozen,
                      title: intl.formatMessage({
                        id: outputIsFrozen
                          ? 'operator.actions.output.unlock_visibility'
                          : 'operator.actions.output.lock_visibility',
                        defaultMessage: outputIsFrozen
                          ? 'Unlock visibility'
                          : 'Lock visibility',
                      }),
                      onClick: () =>
                        outputIsFrozen
                          ? dispatch(unfreezeOperatorOutput([output?.id]))
                          : dispatch(freezeOperatorOutput(output)),
                    },
                  }
                : {}),
            };
          }),
        ];
      }

      if (!isTemplateOperator) {
        actions = [
          ...actions,
          {
            leadingIconName: 'info_0',
            label: intl.formatMessage({
              id: 'general.info',
              defaultMessage: 'Info',
            }),
            withDivider: !!actions?.length,
            onClick: () =>
              showDialog(ModalDataTypes.PROMPT, {
                dataTestId: 'operator-descriptor-dialog',
                title: operator?.translatedName || operator?.tag,
                secondaryButtonLabel: '',
                primaryButtonLabel: intl.formatMessage({
                  id: 'general.close',
                  defaultMessage: 'Close',
                }),
                subtitle: (
                  <>
                    {operator?.operatorDescriptor?.detail && (
                      <span style={{ display: 'block', marginBottom: '16px' }}>
                        {operator.operatorDescriptor.detail}
                      </span>
                    )}
                    {operatorVisibleInputsAndOutputs.map((operatorValue) => {
                      const valueDescriptor =
                        operator.operatorDescriptor.operatorValuesDescriptor[
                          operatorValue.name
                        ];
                      if (!valueDescriptor) return null;
                      const detail =
                        valueDescriptor.detail ||
                        intl.formatMessage({
                          id: valueDescriptor.detailKey,
                          defaultMessage: '',
                        });

                      if (valueDescriptor.detail) {
                        return (
                          <span
                            key={`operator-descriptor-dialog-${operator.tag}-${operatorValue.name}`}
                            style={{ display: 'block', marginBottom: '16px' }}
                          >
                            <b>{operatorValue.name}: </b>

                            {detail}
                          </span>
                        );
                      }

                      return null;
                    })}
                  </>
                ),
              }),
          },
        ];
      }

      if (!isWorkflowEditable) {
        return actions;
      }

      if (getIsOperatorExplodable(operator)) {
        actions = [
          ...actions,
          {
            leadingIconName: 'format_list_bulleted_0',
            label: intl.formatMessage({
              id: 'operator.actions.explode',
              defaultMessage: 'Explode',
            }),
            onClick: () => explodeOperator(operator),
          },
        ];
      }

      actions = [
        ...actions,
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.rename',
            defaultMessage: 'Rename',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.EDIT_OPERATOR, {
              workflowId: operator.conceptId,
              operatorId: operator.id,
              operatorName: operator.tag,
            }),
        },
      ];

      if (!isTemplateOperator) {
        actions = [
          ...actions,
          {
            leadingIconName: 'content_copy_0',
            label: intl.formatMessage({
              id: 'general.duplicate',
              defaultMessage: 'Duplicate',
            }),
            onClick: async () => {
              try {
                const duplicatedOperator =
                  await cloneOperatorMutation.mutateAsync({
                    workflowId: operator.conceptId,
                    operatorId: operator.id,
                  });

                const selecteWorkflow = getSelectedWorkflow();

                refetchProjectFiles(selecteWorkflow?.workspaceId);

                showSnackbar({
                  text: intl.formatMessage({
                    id: 'workflow.operator.duplicate.success.snackbar.text',
                    defaultMessage: 'Operator successfully duplicated',
                  }),
                });

                const observer = new MutationObserver((_, observer) => {
                  const operatorElement = document.getElementById(
                    `operator-${duplicatedOperator.id}`,
                  );

                  if (operatorElement) {
                    observer.disconnect();
                    operatorElement.scrollIntoView();
                  }
                });

                const workflowSettingBar = document.getElementById(
                  WORKFLOW_SETTING_BAR_ID,
                );

                observer.observe(workflowSettingBar, {
                  childList: true,
                  subtree: true,
                });
              } catch (e) {
                logError(e);
              }
            },
          },
        ];
      }

      actions = [
        ...actions,
        {
          isDeleteAction: true,
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-operator-dialog',
              title: intl.formatMessage({
                id: 'deleteoperator.dialog.title',
                defaultMessage: 'Delete operator',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'deleteoperator.dialog.subtitle',
                  defaultMessage:
                    '<b>{operatorName}</b> will be permanently deleted. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  operatorName: operator?.tag,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: async () => {
                const selectedOperator = getSelectedOperator();
                const operatorIsSelected = selectedOperator?.id === operator.id;

                await deleteOperatorMutation.mutateAsync({
                  workflowId: operator.conceptId,
                  operatorId: operator.id,
                });

                if (lastAddedOperatorId === operator?.id) {
                  dispatch(resetLastAddedOperator());
                }

                if (getIsCanvasSelectionActiveForOperator(operator)) {
                  dispatch(resetActiveCanvasSelectionInput());
                }

                if (
                  operator.name.includes(TOOLPATH_GENERATOR_OPERATOR_NAME) &&
                  operatorIsSelected
                ) {
                  dispatch(exitToolpathSimulation());
                }
              },
            });
          },
        },
      ];

      return actions;
    },
    [
      intl,
      dispatch,
      selectedOperatorOutputId,
      getOperatorGeometryOutputs,
      getIsOperatorOutputFrozen,
      getOperatorVisibleInputsAndOutputs,
      getIsOperatorExplodable,
      getIsTemplateOperator,
      selectOperatorOutput,
      explodeOperator,
      showDialog,
      deleteOperatorMutation,
      lastAddedOperatorId,
      getSelectedOperator,
      getIsWorkflowEditable,
      getIsCanvasSelectionActiveForOperator,
      cloneOperatorMutation,
      showSnackbar,
      refetchProjectFiles,
      getSelectedWorkflow,
    ],
  );

  return {
    getCategories,
    getCategoriesWithOperators,
    getCategoryIcon,
    getCategoryOperators,
    getCustomOperatorDropDownMenuActions,
    getOperatorCategories,
    getOperatorIcon,
    getOperatorsByCategories,
    getUserFriendlyCategoryName,
    getVisibleOperators,
    getDefaultOperators,
    getDefaultOperator,
    getWorkflowRecommendedOperators,
    getOperatorDropDownMenuActions,
  };
}
