import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dismissErrorDialog } from '@actions/errorActions';
import { injectIntl } from 'react-intl';
import { formatStringParams } from '@utils/commonFunctions';
import { trackError } from '@utils/GaTracker';
import { ROUTES } from '@constants/router';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import DialogPortal from '@app/components/2-molecules/DialogPortal';

class ErrorDialogWrapperComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    window.electron?.send(
      'log-error',
      `Message: ${error?.message} \n Stack: ${
        error?.stack || error?.error?.stack
      } \n Info: ${JSON.stringify(
        errorInfo,
      )} \n StringifiedError: ${JSON.stringify(error)}`,
    );

    trackError(error, true);
    /* eslint-disable no-console */
    console.error(error);
    console.error(errorInfo);
    /* eslint-enable no-console */
  }

  renderErrorMessages() {
    const { intl } = this.props;
    const GENERAL_ERROR_MESSAGE = 'Something went wrong';
    return this.props.errors.map((error, idx) => {
      const preTranslatedMessage = error.originalMessage;
      let messageToShow = error.message || '';
      if (error.messageKey) {
        if (messageToShow.length > 1) {
          messageToShow += '\n\n';
        }
        if (!preTranslatedMessage) {
          messageToShow += formatStringParams(
            intl.formatMessage({
              id: error.messageKey,
              defaultMessage: GENERAL_ERROR_MESSAGE,
            }),
            error.arguments,
          );
        }
      }

      return (
        <DialogPortal
          key={idx}
          dialogId={`errorDialog-${idx}`}
          dialogData={{ hideOnRouteChange: false }}
          onClose={this.props.dismissErrorDialog}
          autoShow
          errorType
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={
              error.title ||
              intl
                .formatMessage({
                  id: 'errordialogwrapper.title.error',
                  defaultMessage: 'Error',
                })
                .toUpperCase()
            }
            subtitle={
              messageToShow ? (
                <span dangerouslySetInnerHTML={{ __html: messageToShow }} />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'errordialogwrapper.message.somethingwentwrong',
                      defaultMessage: GENERAL_ERROR_MESSAGE,
                    }),
                  }}
                />
              )
            }
          />
        </DialogPortal>
      );
    });
  }

  getGeneralErrorDialog() {
    const { intl } = this.props;
    return (
      <DialogPortal
        dialogId="generalErrorDialog"
        dialogData={{ hideOnRouteChange: false }}
        onClose={this.props.dismissErrorDialog}
        onPrimaryButtonClick={() => {
          window.location.replace(ROUTES.HOME);
        }}
        autoShow
        errorType
      >
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={intl
            .formatMessage({
              id: 'errordialogwrapper.title.error',
              defaultMessage: 'Error',
            })
            .toUpperCase()}
          subtitle={intl.formatMessage({
            id: 'errordialogwrapper.message.somethingwentwrong',
            defaultMessage: 'Something went wrong',
          })}
        />
      </DialogPortal>
    );
  }

  render() {
    return (
      <>
        {this.props.errors?.length > 0 && this.renderErrorMessages()}
        {this.state.hasError &&
          (!this.props.errors || this.props.errors.length === 0) &&
          this.getGeneralErrorDialog()}
        {!this.state.hasError && this.props.children}
      </>
    );
  }
}

ErrorDialogWrapperComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      message: PropTypes.string,
      messageKey: PropTypes.string,
    }),
  ).isRequired,
  dismissErrorDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors.errors,
});

const mapDispatchToProps = (dispatch) => ({
  dismissErrorDialog: () => dispatch(dismissErrorDialog()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ErrorDialogWrapperComponent),
);
