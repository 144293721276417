import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const printerQueryKeys = {
  printers: ['printers'],
  printersMonitor: ['printersMonitor'],
  printersRecording: ['printersRecording'],
  printer: (printerId) => ['showLoader', 'printer', printerId],
  printerInUseWorkflows: (printerId) => [
    'showLoader',
    'printerInUseWorkflows',
    printerId,
  ],
  nozzleInUseWorkflows: (nozzleId) => [
    'showLoader',
    'nozzleInUseWorkflows',
    nozzleId,
  ],
  allNozzles: ['allNozzles'],
  nozzles: (nozzlePrinterId) => ['nozzleLibrary', nozzlePrinterId],
  materials: ['materials'],
  machineDefinitions: ['showLoader', 'machineDefinitions'],
  extruderDefinitions: ['showLoader', 'extruderDefinitions'],
  printingBedDefinitions: ['showLoader', 'printingBedDefinitions'],
  programTypeDefinitions: ['showLoader', 'programTypeDefinitions'],
  plinthDefinitions: ['showLoader', 'plinthDefinitions'],
  enclosureDefinitionsQuery: ['showLoader', 'enclosureDefinitionsQuery'],
  allPrinterDefinitions: [
    'showLoader',
    'machineDefinitions',
    'extruderDefinitions',
    'printingBedDefinitions',
    'programTypeDefinitions',
    'plinthDefinitions',
    'enclosureDefinitions',
  ],
  extruderSettingsTypes: ['showLoader', 'extruderSettingsTypes'],
  printingBedSettingsTypes: ['showLoader', 'printingBedSettingsTypes'],
  robotBrandDefinitions: ['showLoader', 'robotBrandDefinitions'],
  programFileType: (programType) => [
    'showLoader',
    'programFileType',
    programType,
  ],
  printerCameras: (printerId) => ['printerCameras', printerId],
  printerControls: (printerId) => ['printerControls', printerId],
  printerSensorData: (printerId, printerRecordingId) => [
    'printerSensorData',
    printerId,
    printerRecordingId,
  ],
};

export default function usePrinterQueries({
  nozzlePrinterId,
  printerId,
  nozzleId,
  programType,
  printerRecordingId,
  printerSensorDataQueryParams,
} = {}) {
  const getPrintersQueryOptions = useCallback((showLoader) => {
    let queryKey = printerQueryKeys.printers;

    if (showLoader) {
      queryKey = ['showLoader', ...queryKey];
    }

    return {
      queryKey,
      queryFn: () => client.get(endpoints.printers).then((res) => res.data),
      initialData: [],
      enabled: false,
    };
  }, []);

  const printersQuery = useQuery(getPrintersQueryOptions());

  const printersMonitorQuery = useQuery({
    queryKey: printerQueryKeys.printersMonitor,
    queryFn: () =>
      client
        .get(endpoints.printers, {
          params: {
            onlyWithCameras: true,
          },
        })
        .then((res) => res.data),
    enabled: false,
  });

  const printersRecordingQuery = useQuery({
    queryKey: printerQueryKeys.printersRecording,
    queryFn: () => client.get(endpoints.recordings).then((res) => res.data),
    enabled: false,
  });

  const printerQuery = useQuery({
    queryKey: printerQueryKeys.printer(printerId),
    queryFn: () =>
      client
        .get(endpoints.printer.replace(':printerId', printerId))
        .then((res) => res.data),
    enabled: !!printerId,
  });

  const printerInUseWorkflows = useQuery({
    queryKey: printerQueryKeys.printerInUseWorkflows(printerId),
    queryFn: () =>
      client
        .get(endpoints.printerWorkflows.replace(':printerId', printerId))
        .then((res) => res.data),
    enabled: false,
  });

  const nozzleInUseWorkflows = useQuery({
    queryKey: printerQueryKeys.nozzleInUseWorkflows(nozzleId),
    queryFn: () =>
      client
        .get(endpoints.printerNozzleWorkflows.replace(':nozzleId', nozzleId))
        .then((res) => res.data),
    enabled: false,
  });

  const allNozzlesQuery = useQuery({
    queryKey: printerQueryKeys.allNozzles,
    queryFn: () =>
      client
        .get(endpoints.printersNozzles, {
          //TODO: this one is added as the API is flaky due to hibernate issues with printer entity. Remove this once the API is fixed
          ignoreGlobalErrorHandler: true,
        })
        .then((res) => res.data),
    initialData: [],
    retry: 2,
    enabled: false,
  });

  const nozzlesQuery = useQuery({
    queryKey: printerQueryKeys.nozzles(nozzlePrinterId),
    queryFn: () =>
      client
        .get(endpoints.printerNozzles.replace(':printerId', nozzlePrinterId))
        .then((res) => res.data),
    initialData: [],
    enabled: !!nozzlePrinterId,
  });

  const materialsQuery = useQuery({
    queryKey: printerQueryKeys.materials,
    queryFn: () => client.get(endpoints.materials).then((res) => res.data),
    initialData: [],
    enabled: false,
  });

  const machineDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.machineDefinitions,
    queryFn: () =>
      client.get(endpoints.machineDefinitions).then((res) => res.data),
    enabled: false,
  });

  const extruderDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.extruderDefinitions,
    queryFn: () =>
      client.get(endpoints.extruderDefinitions).then((res) => res.data),
    enabled: false,
  });

  const printingBedDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.printingBedDefinitions,
    queryFn: () =>
      client.get(endpoints.printingBedDefinitions).then((res) => res.data),
    enabled: false,
  });

  const programTypeDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.programTypeDefinitions,
    queryFn: () =>
      client.get(endpoints.programTypeDefinitions).then((res) => res.data),
    enabled: false,
  });

  const plinthDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.plinthDefinitions,
    queryFn: () =>
      client.get(endpoints.plinthDefinitions).then((res) => res.data),
    enabled: false,
  });

  const enclosureDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.enclosureDefinitions,
    queryFn: () =>
      client.get(endpoints.enclosureDefinitions).then((res) => res.data),
    enabled: false,
  });

  const allPrinterDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.allPrinterDefinitions,
    queryFn: async () => {
      const [
        machineDefinitions,
        extruderDefinitions,
        printingBedDefinitions,
        programTypeDefinitions,
        plinthDefinitions,
        enclosureDefinitions,
      ] = await Promise.all([
        client.get(endpoints.machineDefinitions).then((res) => res.data),
        client.get(endpoints.extruderDefinitions).then((res) => res.data),
        client.get(endpoints.printingBedDefinitions).then((res) => res.data),
        client.get(endpoints.programTypeDefinitions).then((res) => res.data),
        client.get(endpoints.plinthDefinitions).then((res) => res.data),
        client.get(endpoints.enclosureDefinitions).then((res) => res.data),
      ]);

      return {
        machineDefinitions,
        extruderDefinitions,
        printingBedDefinitions,
        programTypeDefinitions,
        plinthDefinitions,
        enclosureDefinitions,
      };
    },
    initialData: {
      machineDefinitions: [],
      extruderDefinitions: [],
      printingBedDefinitions: [],
      programTypeDefinitions: [],
      plinthDefinitions: [],
      enclosureDefinitions: [],
    },
    enabled: false,
  });

  const extruderSettingsTypesQuery = useQuery({
    queryKey: printerQueryKeys.extruderSettingsTypes,
    queryFn: () =>
      client.get(endpoints.extruderSettingsTypes).then((res) => res.data),
  });

  const printingBedSettingsTypesQuery = useQuery({
    queryKey: printerQueryKeys.printingBedSettingsTypes,
    queryFn: () =>
      client.get(endpoints.printingBedSettingsTypes).then((res) => res.data),
  });

  const robotBrandDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.robotBrandDefinitions,
    queryFn: () =>
      client.get(endpoints.robotBrandDefinitions).then((res) => res.data),
  });

  const programFileTypeQuery = useQuery({
    queryKey: printerQueryKeys.programFileType(programType),
    queryFn: () =>
      client
        .get(endpoints.programFileType, { params: { programType } })
        .then((res) => res.data),
    enabled: !!programType,
  });

  const printerCamerasQuery = useQuery({
    queryKey: printerQueryKeys.printerCameras(printerId),
    queryFn: () =>
      client
        .get(endpoints.printerCameras.replace(':printerId', printerId))
        .then((res) => res.data),
    initialData: [],
    enabled: !!printerId,
  });

  const printerControlsQuery = useQuery({
    queryKey: printerQueryKeys.printerControls(printerId),
    queryFn: () =>
      client
        .get(endpoints.printerControls.replace(':printerId', printerId))
        .then((res) => res.data),
    initialData: [],
    enabled: !!printerId,
  });

  const printerSensorDataQuery = useQuery({
    queryKey: printerQueryKeys.printerSensorData(printerId, printerRecordingId),
    queryFn: () =>
      client
        .get(
          endpoints.sensorsData
            .replace(':printerId', printerId)
            .replace(':recordingId', printerRecordingId),
          {
            params: printerSensorDataQueryParams,
          },
        )
        .then((res) => res.data),
    initialData: [],
    enabled: !!printerId && !!printerRecordingId,
  });

  return {
    getPrintersQueryOptions,
    printersQuery,
    printerQuery,
    printersMonitorQuery,
    printersRecordingQuery,
    allNozzlesQuery,
    nozzlesQuery,
    printerInUseWorkflows,
    nozzleInUseWorkflows,
    materialsQuery,
    machineDefinitionsQuery,
    extruderDefinitionsQuery,
    printingBedDefinitionsQuery,
    programTypeDefinitionsQuery,
    plinthDefinitionsQuery,
    enclosureDefinitionsQuery,
    allPrinterDefinitionsQuery,
    extruderSettingsTypesQuery,
    programFileTypeQuery,
    printingBedSettingsTypesQuery,
    robotBrandDefinitionsQuery,
    printerCamerasQuery,
    printerControlsQuery,
    printerSensorDataQuery,
  };
}
