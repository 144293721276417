import React, { useCallback, useMemo } from 'react';
import { getDefaultPrinterSettingsByCategory } from '@constants/defaultPrinterSettings';
import { useDispatch, useSelector } from 'react-redux';
import usePrinter from '@hooks/printers/usePrinter';
import { getCurrentUser } from '@selectors/loginSelectors';
import { checkCustomOption } from '@components/Printers/SettingsUtils';
import {
  changePrinterSetting,
  deletePrinterSetting,
  showGeneralPrinterSetting,
  showPostProcessorConfig,
} from '@actions/printerActions';

import { useIntl } from 'react-intl';
import { QuaternionSettings } from './QuaternionSettings';
import { getBedKinematics } from '@app/lib/beds/BedUtils';
import {
  ROTARY_BED_KINEMATICS,
  TWO_AXIS_POSITIONER_BED_KINEMATICS,
} from '@constants/machineConstants';
import {
  printerConstants as constants,
  printerSettingTypes,
} from '@constants/printers/printerConstants';
import { HardwareSelection } from './HardwareSelection';
import { NozzleLibrary } from '@components/Printers/NozzleLibrary';
import { Category } from '@components/Printers/Editor/Category';
import PropTypes from 'prop-types';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { ValueTypes } from '@constants/valueTypes';
import useDialog from '@hooks/useDialog';
import { isEmpty } from 'lodash';

const Settings = ({ printer, printerCameras = [], errors }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showDialog } = useDialog();
  const {
    getAllPrinterDefinitions,
    getExtruderDefinition,
    getExtruderSettingsTypes,
    getPrintingBedSettingsTypes,
  } = usePrinter();

  const { programTypeDefinitions, printingBedDefinitions } =
    getAllPrinterDefinitions();

  const extruderSettingsTypes = getExtruderSettingsTypes();
  const printingBedSettingsTypes = getPrintingBedSettingsTypes();

  const extruderDefinition = getExtruderDefinition(printer);
  const enableNozzleLibrary =
    constants.METAL_WIRE !== extruderDefinition?.extrusionType;

  const bedKinematics = useMemo(
    () => getBedKinematics(printer, printingBedDefinitions),
    [printer, printingBedDefinitions],
  );
  const showQuaternionSettings = useMemo(() => {
    return (
      bedKinematics == ROTARY_BED_KINEMATICS ||
      bedKinematics == TWO_AXIS_POSITIONER_BED_KINEMATICS
    );
  }, [bedKinematics]);

  const currentUser = useSelector(getCurrentUser());

  const handleChange = useCallback(
    (changedSetting, value) => {
      if (checkCustomOption(value, changedSetting, dispatch, printer)) {
        return;
      }

      // check camera setting
      const cameraSettingChangedToInitialValue =
        changedSetting.type === printerSettingTypes.camera &&
        printerCameras.some(
          (camera) =>
            camera.id === changedSetting.settingName && camera.name === value,
        );

      //check machine setting
      const machineSettingChangedToInitialValue = !!(
        (changedSetting.settingName === constants.robotType &&
          printer?.machine['modelName'] == value) ||
        printer?.machine[changedSetting?.settingName] == value
      );

      //check setting from printer?.printerSettings list
      const settingFromPrinterSettingsChangedToInitialValue =
        printer?.printerSettings?.some((setting) => {
          return (
            setting.type === changedSetting?.type && setting.value == value
          );
        });

      //check printer setting
      const printerSettingChangedToInitialValue =
        printer[changedSetting?.settingName] == value;

      if (
        printerSettingChangedToInitialValue ||
        settingFromPrinterSettingsChangedToInitialValue ||
        machineSettingChangedToInitialValue ||
        cameraSettingChangedToInitialValue
      ) {
        dispatch(deletePrinterSetting(printer?.id, changedSetting));
        return;
      }

      dispatch(changePrinterSetting(printer?.id, changedSetting, value));
    },
    [dispatch, printer, printerCameras],
  );

  const actionHandler = useCallback(
    (settingName, settingDisplayName, visibility) => {
      switch (settingName) {
        case constants.postProcessor:
          dispatch(showPostProcessorConfig());
          break;

        case constants.robotType:
        case constants.extruderType:
        case constants.plinthType:
        case constants.enclosureType:
        case constants.printingBedType:
        case constants.basePlinthType:
        case constants.programType:
          if (visibility) {
            showDialog(ModalDataTypes.MANAGE_VISIBILITY, {
              settingName: settingName,
            });
          } else {
            dispatch(
              showGeneralPrinterSetting(settingName, settingDisplayName),
            );
          }
          break;

        default:
        // Handle other cases if needed
      }
    },
    [showDialog, dispatch],
  );

  const defaultPrinterSettingsByCategory = useMemo(
    () =>
      getDefaultPrinterSettingsByCategory(
        programTypeDefinitions,
        currentUser,
        extruderSettingsTypes,
        printingBedSettingsTypes,
      ),
    [
      programTypeDefinitions,
      currentUser,
      extruderSettingsTypes,
      printingBedSettingsTypes,
    ],
  );

  const printerSettingsByCategory = useMemo(
    () =>
      printer.printerSettings.reduce(
        (acc, setting) => ({
          ...acc,
          [setting.category]: [...(acc[setting.category] || []), setting],
        }),
        {},
      ),
    [printer.printerSettings],
  );

  const settingsList = (settings) => {
    return settings || [];
  };

  const toolpathSettings = [
    ...settingsList(
      defaultPrinterSettingsByCategory['PRINTER_TOOLPATH_SETTINGS'],
    ),
    ...settingsList(printerSettingsByCategory['PRINTER_TOOLPATH_SETTINGS']),
  ];
  const toolpathSettingsUserCanSee = toolpathSettings.filter(
    (setting) =>
      !setting.authorizedRoles ||
      setting.authorizedRoles.includes(currentUser?.role),
  );

  return (
    <>
      <Category
        onChange={handleChange}
        actionHandler={actionHandler}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.generalSettings',
          defaultMessage: 'General Settings',
        })}
        isOpen={true}
        errors={errors}
        settingsInCategory={[
          ...settingsList(defaultPrinterSettingsByCategory['PRINTER_GENERAL']),
          ...settingsList(printerSettingsByCategory['GENERAL_SETTINGS']),
        ]}
        selectedPrinter={printer}
      />
      <HardwareSelection
        onChange={handleChange}
        actionHandler={actionHandler}
        printer={printer}
      />
      {/* Note that the below categories are just to allow the extruder-dependent settings to be visible - there are no defaults other than those depndent on the machine type */}
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(printerSettingsByCategory['KINEMATICS']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.kinematics',
          defaultMessage: 'Kinematics',
        })}
        selectedPrinter={printer}
      />
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(printerSettingsByCategory['AXIS_LIMITS']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.axislimits',
          defaultMessage: 'Axis Limits',
        })}
        selectedPrinter={printer}
      />
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(printerSettingsByCategory['HOME_POSITION']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.homePosition',
          defaultMessage: 'Home Position',
        })}
        selectedPrinter={printer}
      />
      {/* ---------------------------------------------------------------------- */}
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(defaultPrinterSettingsByCategory['PRINTER_MATERIAL']),
          ...settingsList(printerSettingsByCategory['MATERIAL']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.material',
          defaultMessage: 'Material',
        })}
        selectedPrinter={printer}
      />
      {showQuaternionSettings ? (
        <QuaternionSettings
          additionalSettings={settingsList(
            printerSettingsByCategory['BASE_CALIBRATION'],
          )}
          selectedPrinter={printer}
        />
      ) : (
        <Category
          onChange={handleChange}
          settingsInCategory={[
            ...settingsList(defaultPrinterSettingsByCategory['PRINTER_BASE']),
            ...settingsList(printerSettingsByCategory['BASE_CALIBRATION']),
          ]}
          categoryTitle={intl.formatMessage({
            id: 'printers.category.baseCalibration',
            defaultMessage: 'Base Calibration',
          })}
          selectedPrinter={printer}
        />
      )}
      {enableNozzleLibrary && <NozzleLibrary selectedPrinter={printer} />}
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(defaultPrinterSettingsByCategory['PRINTER_TOOL']),
          ...settingsList(printerSettingsByCategory['TOOL_CALIBRATION']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.toolCalibration',
          defaultMessage: 'Tool Calibration',
        })}
        selectedPrinter={printer}
      />
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(
            defaultPrinterSettingsByCategory['PRINTER_WORKSPACE'],
          ),
          ...settingsList(printerSettingsByCategory['WORKSPACE']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.workspace',
          defaultMessage: 'Workspace',
        })}
        selectedPrinter={printer}
      />
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(
            defaultPrinterSettingsByCategory['PRINTER_FILAMENT_EXTRUSION'],
          ),
          ...settingsList(printerSettingsByCategory['EXTRUSION']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.extrusion',
          defaultMessage: 'Extrusion',
        })}
        selectedPrinter={printer}
      />
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(
            defaultPrinterSettingsByCategory['PRINTER_FILAMENT_TEMPERATURE'],
          ),
          ...settingsList(
            printerSettingsByCategory['PRINTER_FILAMENT_TEMPERATURE'],
          ),
          ...settingsList(printerSettingsByCategory['TEMPERATURE']),
          ...settingsList(defaultPrinterSettingsByCategory['PRINTER_BED']),
          ...settingsList(printerSettingsByCategory['PRINTER_BED']),
          ...settingsList(defaultPrinterSettingsByCategory['PRINTER_CELL']),
          ...settingsList(printerSettingsByCategory['PRINTER_CELL']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.temperature',
          defaultMessage: 'Temperature',
        })}
        selectedPrinter={printer}
      />
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(
            defaultPrinterSettingsByCategory['PRINTER_FILAMENT_IO'],
          ),
          ...settingsList(printerSettingsByCategory['IO']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.io',
          defaultMessage: 'I/O',
        })}
        selectedPrinter={printer}
      />
      {toolpathSettingsUserCanSee.length > 0 && (
        <Category
          onChange={handleChange}
          settingsInCategory={toolpathSettings}
          categoryTitle={intl.formatMessage({
            id: 'printers.category.toolpathSettings',
            defaultMessage: 'Toolpath Settings',
          })}
          selectedPrinter={printer}
        />
      )}
      <Category
        onChange={handleChange}
        settingsInCategory={[
          ...settingsList(printerSettingsByCategory['GENERIC']),
        ]}
        categoryTitle={intl.formatMessage({
          id: 'printers.category.generic',
          defaultMessage: 'Generic',
        })}
        selectedPrinter={printer}
      />

      {!isEmpty(printerCameras) && (
        <Category
          onChange={handleChange}
          settingsInCategory={printerCameras.map((camera, i) => ({
            settingName: camera.id,
            displayName: `Camera ${i + 1}`,
            valueType: ValueTypes.STRING,
            type: printerSettingTypes.camera,
            value: camera.name,
          }))}
          categoryTitle={intl.formatMessage({
            id: 'printers.category.cameraMonitoring',
            defaultMessage: 'Camera Monitoring',
          })}
          selectedPrinter={printer}
        />
      )}
    </>
  );
};

Settings.propTypes = {
  printer: PropTypes.object.isRequired,
  printerCameras: PropTypes.array,
  errors: PropTypes.object,
};

export default Settings;
