import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  ${flexColumn}
  flex: 1;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing?.level4};
    padding-bottom: ${spacing?.level5};
  `};
`;

export const Footer = styled.div`
  margin-top: auto;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing?.level6} 0;
  `};
`;
