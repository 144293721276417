import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useModal from '@hooks/useModal';
import { ModalDataTypes, MODAL_IDS } from '@constants/modalDataTypes';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import { ContentWrapper, Wrapper, BannerWrapper } from './Home.styled';
import { ICON_BUTTON_VARIANT_PRIMARY } from '@components/1-atoms/IconButton';
import WorkflowExamples from '@containers/WorkflowExamples';
import Banner from '@app/components/1-atoms/Banner';
import RecentWorkflows from '@components/RecentWorkflows';
import { getFromLocalStorage } from '@utils/localStorage';
import { HIDE_WELCOME_MODAL_KEY } from '@constants/utilityConstants';
import useLeanUpon from '@hooks/learnUpon/useLeanUpon';

const Home = () => {
  const intl = useIntl();
  const { onLearnUponLinkClick } = useLeanUpon();
  const { showDialog } = useDialog();
  const { showModal } = useModal();
  const isDesktop = DESKTOP_APP;

  const getAddButtonDropDownMenuActions = useCallback(() => {
    return [
      {
        leadingIconName: 'category_0',
        label: intl.formatMessage({
          id: 'createproject.project.header.title',
          defaultMessage: 'Create new project',
        }),
        onClick: () => showDialog(ModalDataTypes.CREATE_FLOW),
      },
      {
        leadingIconName: 'spool_0',
        label: intl.formatMessage({
          id: 'materials.create_material_dialog.header.title',
          defaultMessage: 'Add new material',
        }),
        onClick: () => showDialog(ModalDataTypes.CREATE_MATERIAL),
      },
      {
        leadingIconName: 'precision_manufacturing_0',
        label: intl.formatMessage({
          id: 'homepage.add_button.add.printer.label',
          defaultMessage: 'Add new printer',
        }),
        onClick: () => showDialog(ModalDataTypes.CREATE_PRINTER),
      },
    ];
  }, [showDialog, intl]);

  useEffect(() => {
    if (!isDesktop && !getFromLocalStorage(HIDE_WELCOME_MODAL_KEY)) {
      showModal(MODAL_IDS.WELCOME);
    }
  }, [isDesktop, showModal]);

  return (
    <Wrapper>
      <PageHeader
        size={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'homepage.title',
          defaultMessage: 'Home',
        })}
        endingIconButtonIconName={'add_0'}
        endingIconButtonDropDownProps={{
          dropDownMenuItems: getAddButtonDropDownMenuActions(),
        }}
        endingIconButtonVariant={ICON_BUTTON_VARIANT_PRIMARY}
      />

      <ContentWrapper>
        <RecentWorkflows />

        {!isDesktop && (
          <BannerWrapper>
            <Banner
              iconName="school_0"
              title={intl.formatMessage({
                id: 'homepage.banner.title',
                defaultMessage: 'Tutorials',
              })}
              description={intl.formatMessage({
                id: 'homepage.banner.description',
                defaultMessage:
                  'Discover more about the Aibuild platform. Learn how to get started and manage your workflows',
              })}
              buttonLabel={intl.formatMessage({
                id: 'homepage.banner.button_title',
                defaultMessage: 'View all tutorials',
              })}
              onButtonClick={onLearnUponLinkClick}
              imageSrc="/img/banner-background.png"
            />
          </BannerWrapper>
        )}

        <WorkflowExamples />
      </ContentWrapper>
    </Wrapper>
  );
};

Home.propTypes = {};

export default Home;
