import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { Formik, Field as FormikField } from 'formik';
import Field from '@components/1-atoms/Field';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { Fields } from '@containers/Dialogs/CreateUserDialog/CreateUserDialog.styled';
import DropDownField from '@components/1-atoms/DropDownField';
import { UserRoles } from '@constants/userRoles';
import RulesMatcher from '@components/1-atoms/RulesMatcher';
import { isPasswordComplexEnough } from '@utils/validation';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import {
  createNameRoleAndUsernameValidationSchema,
  passwordValidationRules,
} from '@utils/validationHelper';
import DatePickerField from '@components/1-atoms/DatePickerField';
import { logError } from '@utils/logs';

const MODAL_ID = ModalDataTypes.CREATE_USER;

const CreateUserDialog = () => {
  const intl = useIntl();
  const { hideDialog } = useDialog();
  const [revealNewPassword, setRevealNewPassword] = useState(false);

  const currentUser = useSelector(getCurrentUser());
  const { createUserMutation } = useOrganizationMutations();

  const createValidationSchema = (intl) => {
    const createUserFieldsSchema = Yup.object().shape({
      newPassword: Yup.string()
        .required(
          intl.formatMessage({
            id: 'createuser.new.password.validation.required',
            defaultMessage: 'Password field cannot be empty',
          }),
        )
        .test('password-complex-enough', '', (p) => isPasswordComplexEnough(p)),
      expiryDate: Yup.date()
        .nullable()
        .typeError(
          intl.formatMessage({
            id: 'createuser.expirydate.validation.format',
            defaultMessage:
              'Invalid date format. Please enter in YYYY-MM-DD format',
          }),
        )
        .test(
          'is-future-date',
          intl.formatMessage({
            id: 'createuser.expirydate.validation.min',
            defaultMessage: 'Expiry date must be in the future',
          }),
          (value) => {
            if (value) {
              const expiryDate = Yup.date().nullable().isValidSync(value)
                ? new Date(value)
                : null;
              return expiryDate && expiryDate > new Date();
            }
            return true;
          },
        ),
    });

    return Yup.object().shape({
      ...createNameRoleAndUsernameValidationSchema(intl).fields,
      ...createUserFieldsSchema.fields,
    });
  };

  const validationSchema = useMemo(() => createValidationSchema(intl), [intl]);

  const formInitialValues = useMemo(
    () => ({
      name: '',
      username: '',
      role: {
        label: UserRoles.ADMIN,
        value: UserRoles.ADMIN,
      },
      expiryDate: '',
      newPassword: '',
    }),
    [],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    async ({ newPassword, name, role, expiryDate, username }) => {
      try {
        await createUserMutation.mutateAsync({
          user: {
            displayName: name,
            role: role.value,
            organizationId: currentUser?.organizationId,
            expiryDate: expiryDate,
            username: username,
            password: newPassword,
          },
        });
      } catch (e) {
        logError(e);
      }
      hideDialog(MODAL_ID);
    },
    [hideDialog, currentUser, createUserMutation],
  );

  const getUserRolesDropDownMenuItems = useCallback((values) => {
    return [
      {
        label: UserRoles.ADMIN,
        selected: values?.role.value === UserRoles.ADMIN,
        formFieldValue: {
          label: UserRoles.ADMIN,
          value: UserRoles.ADMIN,
        },
      },
    ];
  }, []);

  const passwordRules = useMemo(() => {
    return (newPassword) => passwordValidationRules(intl, newPassword);
  }, [intl]);

  const onPasswordRevealClick = useCallback(
    (setter) => () => {
      setter?.((currentValue) => !currentValue);
    },
    [],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <DialogPortal
          renderAsForm
          dataTestId="create-user"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'createuser.header.title',
              defaultMessage: 'Add new user',
            })}
          />

          <Fields>
            <FormikField
              component={Field}
              dataTestId="create-user__name__field"
              disabled={isSubmitting}
              name="name"
              label={intl.formatMessage({
                id: 'createuser.name.label',
                defaultMessage: 'Name',
              })}
            />
            <FormikField
              component={Field}
              dataTestId="create-user__username__field"
              disabled={isSubmitting}
              name="username"
              label={intl.formatMessage({
                id: 'createuser.username.label',
                defaultMessage: 'Email address',
              })}
            />
            <FormikField
              component={DropDownField}
              dataTestId="create-user__role__dropdown-field"
              disabled={isSubmitting}
              name="role"
              value={values?.role.value}
              label={intl.formatMessage({
                id: 'createuser.role.label',
                defaultMessage: 'Select Role',
              })}
              dropDownMenuItems={getUserRolesDropDownMenuItems(values)}
            />
            <FormikField
              component={DatePickerField}
              onChange={setFieldValue}
              dataTestId="create-user__expiration-date__field"
              disabled={isSubmitting}
              name="expiryDate"
              label={intl.formatMessage({
                id: 'createuser.expiration.date.label',
                defaultMessage: 'Expiration date',
              })}
            />
            <FormikField
              component={Field}
              type={revealNewPassword ? 'text' : 'password'}
              dataTestId="create-user__new-password-field"
              disabled={isSubmitting}
              name="newPassword"
              label={intl.formatMessage({
                id: 'createuser.new.password.label',
                defaultMessage: 'Set Password',
              })}
              endingButtonIconName={
                revealNewPassword ? 'visibility_on_1' : 'visibility_off_0'
              }
              onEndingButtonClick={onPasswordRevealClick(setRevealNewPassword)}
            />
            <RulesMatcher
              dataTestId={`create-user__password-rules-matcher`}
              rules={passwordRules(values?.newPassword)}
              successMessages={[
                intl.formatMessage({
                  id: 'loginpage.resetpassword_newpassword.newpassword.rules.success',
                  defaultMessage: 'Your password is secure',
                }),
              ]}
            />
          </Fields>
        </DialogPortal>
      )}
    </Formik>
  );
};

CreateUserDialog.propTypes = {};

export default CreateUserDialog;
