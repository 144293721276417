export const PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP = 'generationTimestamp';
export const NON_GEO_OUTPUTS_COLOUR = '-xcolour-red';
export const HIDDEN_INPUT_TYPES = [
  'PRINTER',
  'EXTRUDER_TYPE',
  'EXTRUSION_TYPE',
  'EXTRUDER_SETTINGS_TYPE',
  'PROGRAM_TYPE',
];
export const DEFAULT = 'DEFAULT';
export const CURRENT_USER_KEY_LOCAL_STORAGE = 'currentUser';
export const USER_PREFERENCES_KEY = 'userPreferences';
export const USER_SESSION_PREFERENCES_KEY = 'userSessionPreferences';
export const THEME_PREFERENCE_KEY = 'themePreference';
export const HIDE_WALKTHROUGH_BANNER_KEY = 'hideWalkthroughBanner';
export const HIDE_UPGRADE_BANNER_KEY = 'hideUpgradeBanner';
export const CURRENT_APP_VERSION_KEY = 'currentAppVersion';
export const HIDE_WELCOME_MODAL_KEY = 'hideWelcomeModal';
export const LAST_UPGRADE_VERSION_DISMISSED_KEY = 'lastVersionDismissed';
export const HIDE_UPDATE_PRINTER_BANNER_KEY = 'hideUpdatePrinterBanner';
export const LAST_UPDATE_PRINTER_TIMESTAMP_DISMISSED_KEY =
  'lastPrinterUpdateTimestampDismissed';

//TODO: move this one in a dedicated class/file when we have built
//a decent internal error code management system
export const TOKEN_EXPIRED_REASON_CODE = 0;
export const LICENSE_EXPIRED_RESPONSE_TEXT = 'Application license not valid';
export const SHOW_LINES_FOR_LINE_DATA = [
  'DEFAULT',
  'LAYERS',
  'SEQUENCE',
  'THICKNESS',
  'HEIGHT',
  'SPEED_MULTIPLIER',
];

export const PRINTER = 'PRINTER';
export const EXTRUDER_TYPE = 'EXTRUDER_TYPE';
export const EXTRUSION_TYPE = 'EXTRUSION_TYPE';
export const EXTRUDER_SETTINGS_TYPE = 'EXTRUDER_SETTINGS_TYPE';
export const PROGRAM_TYPE = 'PROGRAM_TYPE';
export const GEOMETRY_TRIANGLE_MESH = 'GEOMETRY_TRIANGLE_MESH';
export const GEOMETRY_CLASSIFIED_POLYLINES = 'GEOMETRY_CLASSIFIED_POLYLINES';
export const LIST_GEOMETRY_CLASSIFIED_POLYLINES =
  'LIST_GEOMETRY_CLASSIFIED_POLYLINES';
export const GEOMETRY_PLANES = 'GEOMETRY_PLANES';
export const GEOMETRY_POINTS = 'GEOMETRY_POINTS';
export const FILE_CLASSIFIED_POLYLINES = 'FILE_CLASSIFIED_POLYLINES';
export const FILE_GCODE = 'FILE_GCODE';
export const START_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET = ' [';
export const END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET = ']';

export const WAIT_FOR_PROGRESS_INFO_STATUS =
  'Computing - waiting for progress info';
export const PROGRESS_BAR_IDLE_STATUS = 'Idle';
