import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useSafetyCheck from '@hooks/operators/useSafetyCheck';
import {
  ContentWrapper,
  SafetyCheckItem,
} from '@components/Printers/SafetyCheckResults/SafetyCheckResults.styled';
import { removeWhitespace } from '@components/Printers/SettingsUtils';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';

const STATUS = {
  NOT_SET: 0,
  SUCCESS: 1,
  FAILED: 2,
};

export const SafetyCheckResults = () => {
  const intl = useIntl();
  const { getSelectedWorkflow } = useWorkflow();
  const { getSafetyCheck } = useSafetyCheck();
  const selectedWorkflow = getSelectedWorkflow();
  const safetyCheck = getSafetyCheck();
  const safetyCheckArray = Object.values(safetyCheck);

  const getResultStatus = useCallback((result) => {
    if (result.input.value === 'false') {
      return STATUS.NOT_SET;
    }
    return result.output.value === '0' || result.output.value === ''
      ? STATUS.SUCCESS
      : STATUS.FAILED;
  }, []);

  const getIcon = useCallback((status) => {
    const icons = {
      [STATUS.NOT_SET]: 'health_and_safety_0',
      [STATUS.SUCCESS]: 'done_0',
      [STATUS.FAILED]: 'error_0',
    };
    return icons[status] || '';
  }, []);

  const getIconColor = useCallback((status) => {
    const colors = {
      [STATUS.SUCCESS]: 'success',
      [STATUS.FAILED]: 'error',
    };
    return colors[status] || '';
  }, []);

  const getIconDescription = useCallback(
    (status, result) => {
      const descriptions = {
        [STATUS.FAILED]: [result.warning, result.error, result.recommendation]
          .filter(Boolean)
          .map((msg) =>
            intl.formatMessage(
              {
                id: msg.key,
                defaultMessage: msg.defaultMessage,
              },
              { ...msg.values },
            ),
          )
          .join('\n\n'),
        [STATUS.NOT_SET]: intl.formatMessage(
          {
            id: 'workflows.safety_check_results.enable_to_see_results.label',
            defaultMessage:
              'Enable the {inputName} option in the Toolpath operator to view the results here.',
          },
          { inputName: result.input.name },
        ),
      };
      return descriptions[status] || '';
    },
    [intl],
  );

  const getFormattedText = useCallback((selectedWorkflow) => {
    const date = new Date(selectedWorkflow.lastComputationTime);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  }, []);

  const lastComputationTime = getFormattedText(selectedWorkflow);

  return (
    <ContentWrapper>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        dataTestId={`workflows-safety_check_results__page-header`}
        title={intl.formatMessage({
          id: 'workflows.safety_check_results.title',
          defaultMessage: 'Safety Check',
        })}
        subtitle={intl.formatMessage(
          {
            id: 'workflows.safety_check_results.last_computation_time',
            defaultMessage: 'Latest check completed on {lastComputationTime}.',
          },
          { lastComputationTime },
        )}
      />

      {safetyCheckArray.map((result) => {
        const status = getResultStatus(result);
        const outputName = removeWhitespace(result.output.name);
        return (
          <SafetyCheckItem
            key={`workflows-page__safety_check__${outputName}`}
            leadingIconName={getIcon(status)}
            dataTestId={`workflows-page__safety_check__${outputName}`}
            label={intl.formatMessage({
              id: `workflows.safety_check_results.${outputName}.label`,
              defaultMessage: outputName,
            })}
            iconColor={getIconColor(status)}
            title={intl.formatMessage({
              id: `workflows.safety_check_results.${outputName}.label`,
              defaultMessage: outputName,
            })}
            withDivider
            description={
              status !== STATUS.SUCCESS && getIconDescription(status, result)
            }
          />
        );
      })}
    </ContentWrapper>
  );
};

export default SafetyCheckResults;
