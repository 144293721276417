import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import MessageBox, {
  MESSAGE_BOX_VARIANT_ERROR,
} from '@components/2-molecules/MessageBox';
import Field from '@components/1-atoms/Field';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Footer, Wrapper } from './LoginLicenseKey.styled';
import Text from '@components/1-atoms/Text';
import Link from '@components/1-atoms/Link';
import useSnackbar from '@hooks/useSnackbar';
import { SNACKBAR_POSITION_BOTTOM_RIGHT } from '@components/2-molecules/Snackbar';

const LoginLicenseKey = ({
  dataTestId = 'login-license-key',
  className,
  licenseExpired,
  showContactInfoDialog,
}) => {
  const intl = useIntl();
  const form = useFormContext();
  const { showSnackbar } = useSnackbar();
  const { isSubmitting, isSubmitted } = form.formState;

  const downloadEulaLink = useCallback(() => {
    const fileUrl = `${window.location.origin}/generalAssets/Aibuild_EULA.pdf`;

    window.electronDl // available because of context bridge in preloadjs
      .download(fileUrl, {
        filename: 'Aibuild_EULA.pdf',
      })
      .then((response) => {
        if (response.success) {
          showSnackbar({
            text: intl.formatMessage({
              id: 'desktop.download.dialog.success.text',
              defaultMessage: 'Download Completed.',
            }),
            position: SNACKBAR_POSITION_BOTTOM_RIGHT,
          });
        } else {
          showSnackbar({
            text: intl.formatMessage({
              id: 'desktop.download.dialog.failure.text',
              defaultMessage: 'Download failed.',
            }),
            position: SNACKBAR_POSITION_BOTTOM_RIGHT,
            variant: MESSAGE_BOX_VARIANT_ERROR,
          });
        }
      })
      .catch(() => {
        showSnackbar({
          text: intl.formatMessage({
            id: 'desktop.download.dialog.failure.text',
            defaultMessage: 'Download failed.',
          }),
          position: SNACKBAR_POSITION_BOTTOM_RIGHT,
          variant: MESSAGE_BOX_VARIANT_ERROR,
        });
      });
  }, [intl, showSnackbar]);

  const eulaLink = useCallback(
    (label) => {
      return (
        <Link onClick={downloadEulaLink} to="#">
          {label}
        </Link>
      );
    },
    [downloadEulaLink],
  );

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'login_license.header.title',
          defaultMessage: 'Welcome to Aibuild',
        })}
        subtitle={intl.formatMessage({
          id: 'login_license.header.subtitle',
          defaultMessage: 'login_license.header.subtitle',
        })}
      />

      {licenseExpired && (
        <MessageBox
          dataTestId={`${dataTestId}__license-failed-message-box`}
          variant={MESSAGE_BOX_VARIANT_ERROR}
          leadingIconName="error_0"
        >
          <FormattedMessage
            id="login_license.license_expired.message"
            defaultMessage="Your license key has expired or is not valid. Please request a new one to continue"
          />
        </MessageBox>
      )}

      <Controller
        name="licenseKey"
        rules={{
          required: intl.formatMessage({
            id: 'login_license.license_key_field.error.required',
            defaultMessage: 'License key is required',
          }),
        }}
        render={({ field, fieldState }) => {
          const error =
            (fieldState.isTouched || isSubmitted) && fieldState.error;

          return (
            <Field
              dataTestId={`${dataTestId}__licens-key-field`}
              disabled={isSubmitting}
              placeholder={intl.formatMessage({
                id: 'login_license.license_key_field.placeholder',
                defaultMessage: 'License Key',
              })}
              autoFocus
              error={!!error}
              supportingText={error?.message}
              {...field}
            />
          );
        }}
      />

      <Button
        dataTestId={`${dataTestId}__submit-button`}
        type="submit"
        stretch={false}
        loading={isSubmitting}
      >
        <FormattedMessage id="general.continue" defaultMessage="Continue" />
      </Button>
      <Text color={'outline'}>
        {intl.formatMessage(
          {
            id: 'login_license.license.agreement.text',
            defaultMessage:
              'By continuing, you acknowledge that you understand and agree to the Aibuild <a> End User License Agreement</a>',
          },
          {
            a: (str) => eulaLink(str),
          },
        )}
      </Text>

      <Footer>
        <Button
          disabled={isSubmitting}
          variant={BUTTON_VARIANT_TEXT}
          onClick={showContactInfoDialog}
        >
          <FormattedMessage
            id="login_license.no_license_key"
            defaultMessage="I do not have a license key"
          />
        </Button>
      </Footer>
    </Wrapper>
  );
};

LoginLicenseKey.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  licenseExpired: PropTypes.bool,
  showContactInfoDialog: PropTypes.func.isRequired,
};

export default LoginLicenseKey;
