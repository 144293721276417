import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  useQueryClient,
  useIsFetching,
  useIsMutating,
} from '@tanstack/react-query';
import { projectQueryKeys } from './useApplicationQueries';
import { getIsFetchesInProgress } from '@selectors/generalSelectors';
import { getIsAnalyticsLoading } from '@selectors/analyticsSelectors';

export default function useApplication() {
  const queryClient = useQueryClient();
  const isFetching = useSelector(getIsFetchesInProgress());
  const isQueryFetching = useIsFetching({
    queryKey: ['showLoader'],
    exact: false,
  });
  const isMutating = useIsMutating({
    mutationKey: ['showLoader'],
    exact: false,
  });
  const isAnalyticsLoading = useSelector(getIsAnalyticsLoading());
  const theme = useTheme();

  const getIsApplicationLoading = useCallback(
    () =>
      [
        isFetching,
        isAnalyticsLoading,
        !!isQueryFetching,
        !!isMutating,
      ].includes(true),
    [isFetching, isAnalyticsLoading, isQueryFetching, isMutating],
  );

  const getThemeData = useCallback(() => {
    return queryClient.getQueryData(projectQueryKeys.theme);
  }, [queryClient]);

  const getThemeLogoUrl = useCallback(() => {
    const themeData = getThemeData();

    return theme?.isLightTheme
      ? themeData?.lightThemeBigLogoUrl
      : themeData?.darkThemeBigLogoUrl;
  }, [getThemeData, theme]);

  const getThemeSmallLogoUrl = useCallback(() => {
    const themeData = getThemeData();

    return theme?.isLightTheme
      ? themeData?.lightThemeSmallLogoUrl
      : themeData?.darkThemeSmallLogoUrl;
  }, [getThemeData, theme]);

  const cleanQueryClient = useCallback(() => {
    queryClient.clear();
  }, [queryClient]);

  return {
    getIsApplicationLoading,
    getThemeData,
    getThemeLogoUrl,
    getThemeSmallLogoUrl,
    cleanQueryClient,
  };
}
