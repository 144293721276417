import React, { useMemo, useCallback } from 'react';
import { Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useFileMutations from '@hooks/files/useFileMutations';
import Dialog from '@components/Workspaces/Dialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Field from '@components/1-atoms/Field';
import useSnackbar from '@hooks/useSnackbar';
import { logError } from '@utils/logs';

const MODAL_ID = ModalDataTypes.EDIT_FILE;

const EditFileDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { updateFileMutation } = useFileMutations();
  const { showSnackbar } = useSnackbar();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const fileExtension = useMemo(
    () => dialogData?.fileName?.split('.').pop(),
    [dialogData?.fileName],
  );

  const formInitialValues = useMemo(
    () => ({
      name: dialogData?.fileName?.replace(/\.[^/.]+$/, ''),
    }),
    [dialogData?.fileName],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(
            256,
            intl.formatMessage({
              id: 'editfile.dialog.workflowname.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'editfile.dialog.workflowname.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          ),
      }),
    [intl],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    async (values) => {
      try {
        await updateFileMutation.mutateAsync({
          id: dialogData?.fileId,
          name: values.name,
          projectId: dialogData?.projectId,
          fileExtension,
        });
        showSnackbar({
          text: intl.formatMessage({
            id: 'publishfile.snackbar.label',
            defaultMessage: 'File successfully added',
          }),
        });
      } catch (e) {
        logError(e);
      }
      handleClose();
    },
    [
      intl,
      showSnackbar,
      dialogData?.fileId,
      dialogData?.projectId,
      fileExtension,
      handleClose,
      updateFileMutation,
    ],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          dataTestId="edit-file-dialog"
          dialogId={MODAL_ID}
          title={intl.formatMessage({
            id: 'editfile.dialog.title',
            defaultMessage: 'Rename File',
          })}
          confirmButtonText={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          loading={isSubmitting}
        >
          <FormikField
            autoFocus
            component={Field}
            dataTestId="edit-file-dialog__name-field"
            disabled={isSubmitting}
            name="name"
            placeholder={intl.formatMessage({
              id: 'editfile.dialog.form.field.name.placeholder',
              defaultMessage: 'Name',
            })}
          />
        </Dialog>
      )}
    </Formik>
  );
};

export default EditFileDialog;
