export default {
  development: {
    baseUrl: window.apiServerUrl || 'http://localhost:8080/',
    baseUrlws: window.apiWsServerUrl || 'ws://localhost:8080/',
  },
  test: {
    baseUrl: window.apiServerUrl || 'https://aisync-api-test.ai-build.com/',
    baseUrlws: window.apiWsServerUrl || 'wss://aisync-api-test.ai-build.com/',
  },
  production: {
    baseUrl: window.apiServerUrl || 'https://aisync-api.ai-build.com/',
    baseUrlws: window.apiWsServerUrl || 'wss://aisync-api.ai-build.com/',
  },
  onpremise: {
    baseUrl: 'http://ec2-54-73-102-136.eu-west-1.compute.amazonaws.com:30003/',
    baseUrlws: 'ws://ec2-54-73-102-136.eu-west-1.compute.amazonaws.com:30003/',
  },
};
