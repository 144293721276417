import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import SVG from '@components/1-atoms/SVG';
import { flexColumn, flexCenterVertical } from '@stylesheets/helpers';

export const RouterLink = styled(NavLink)`
  height: 48px;
  ${flexCenterVertical}
  text-decoration: none;
`;

export const Wrapper = styled.div`
  ${flexColumn}
  height: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level6} ${spacing.level6} 0 ${spacing.level6};
  `}
`;

export const Logo = styled(SVG).attrs(({ theme: { isLightTheme } }) => ({
  name: isLightTheme ? 'logo-white' : 'logo',
}))`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

export const ImgLogo = styled.img`
  max-height: 48px;
  flex-shrink: 0;
`;

export const Content = styled.div`
  ${flexColumn}
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    padding-inline: ${spacing.level6};
  `}
`;

export const TabsContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.level6};
  `}
`;
