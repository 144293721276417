import { useCallback, useEffect, useMemo } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { getConfig } from '@actions/AuthenticatedFetch';
import { getCurrentUser } from '@selectors/loginSelectors';
import {
  CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
  CONNECT_TO_TOOLPATH_SOCKET,
  EVENTS_WEBSOCKET_CREATED,
  UPLOADING_FILE,
} from '@constants/actionTypes';
import { tokenAuthentication } from '@actions/loginActions';
import { WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT } from '@constants/websocket';

const WS_MESSAGE_TYPE = {
  RUNNING_OPERATOR: 'RUNNING_OPERATOR',
  WORKFLOW_COMPUTATION_COMPLETED: 'WORKFLOW_COMPUTATION_COMPLETED',
  WORKFLOW_COMPUTATION_ERROR: 'WORKFLOW_COMPUTATION_ERROR',
  ALL_SENSORS_DATA: 'ALL_SENSORS_DATA',
  PRINTER_HEARTBEAT: 'PRINTER_HEARTBEAT',
  PRINT_STATUS: 'PRINT_STATUS',
  UPLOADING_FILE: 'UPLOADING_FILE',
};

export default function useFileSocket() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser());

  const username = currentUser?.username;
  const Config = getConfig();
  const initialBaseUrl = DESKTOP_APP ? window.apiWsServerUrl : Config.baseUrlws;
  const baseUrl = initialBaseUrl || 'ws://localhost/';

  const urlProvider = useCallback(async () => {
    const token = currentUser?.token;

    return baseUrl + 'ws/events' + '?token=' + encodeURIComponent(token);
  }, [baseUrl, currentUser?.token]);

  const wsOptions = useMemo(
    () => ({
      connectionTimeout: 2000,
      minReconnectionDelay: 500,
      maxReconnectionDelay: 3000,
    }),
    [],
  );

  const ws = useMemo(
    () => new ReconnectingWebSocket(urlProvider, [], wsOptions),
    [urlProvider, wsOptions],
  );

  const convertRange = useCallback(
    (value, r1, r2) =>
      ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0],
    [],
  );

  const handleFilesReceivedMessage = useCallback(
    (message) => {
      const event = JSON.parse(message.data);

      if (event.type === WS_MESSAGE_TYPE.UPLOADING_FILE) {
        if (username === event.data.username) {
          event.data.progress = convertRange(
            event.data.progress,
            [0, 100],
            [50, 100],
          );
          //avoid to flood with redux state updates
          if (event.data.progress >= 90 || Math.random() > 0.15) {
            dispatch({
              type: UPLOADING_FILE,
              payload: event.data,
            });
          }
        }
      }
    },
    [convertRange, dispatch, username],
  );

  useEffect(() => {
    ws.addEventListener('open', () => {
      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.log('WebSocket open');
      }

      dispatch({
        type: CONNECT_TO_TOOLPATH_SOCKET,
      });
    });

    dispatch({
      type: EVENTS_WEBSOCKET_CREATED,
      payload: ws,
    });
  }, [dispatch, ws]);

  useEffect(() => {
    ws.addEventListener('close', (closeEvent) => {
      const retryCount = closeEvent?.target?._retryCount || 0;
      const shouldDispatchCloseAction = retryCount <= 1;
      const shouldValidateToken =
        retryCount === WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT;

      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.log('WebSocket closed');
      }

      if (shouldDispatchCloseAction) {
        dispatch({
          type: CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
        });
      }

      if (shouldValidateToken) {
        dispatch(dispatch(tokenAuthentication()));
      }
    });
  }, [dispatch, ws]);

  // Files upload messages handler
  useEffect(() => {
    ws.removeEventListener('message', handleFilesReceivedMessage);
    ws.addEventListener('message', handleFilesReceivedMessage);

    return () => {
      ws.removeEventListener('message', handleFilesReceivedMessage);
    };
  }, [handleFilesReceivedMessage, ws]);

  useEffect(() => {
    return () => {
      ws.close();
    };
  }, [ws]);

  return ws;
}
