import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import usePrevious from '@hooks/usePrevious';
import useModal from '@hooks/useModal';
import useDialog from '@hooks/useDialog';
import useSteps from '@hooks/useSteps';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import useProjectFiles from '@hooks/files/useProjectFiles';
import useWorkflowList from '@hooks/workflows/useWorkflowList';
import useApplication from '@hooks/application/useApplication';
import { getNavigationBarHistory } from '@selectors/applicationSelectors';
import { setNavigationBarHistory } from '@actions/applicationActions';
import NavigationBar from '@components/NavigationBar';
import ProjectsPageContent from '@components/ProjectsPageContent';
import WorkflowsPageContent from '@components/WorkflowsPageContent';
import FilePageContent from '@components/File/FilePageContent';
import Tabs, { TABS_VARIANT_TEXT } from '@components/2-molecules/Tabs';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import getIntlProvider from '@utils/getIntlProvider';
import { ModalDataTypes, MODAL_IDS } from '@constants/modalDataTypes';
import {
  Wrapper,
  Header,
  RouterLink,
  Logo,
  ImgLogo,
  Content,
  TabsContainer,
} from './WorkflowDynamicNavigationBar.styled';

const intl = getIntlProvider();

const NAVIGATION_BAR_STEP = 'navigationBar';
const PROJECTS_STEP = 'projects';
const PROJECT_STEP = 'project';

const NAVIGATION_STEPS = [NAVIGATION_BAR_STEP, PROJECTS_STEP, PROJECT_STEP];

const dataTestId = 'workflow-dynamic-navigation-bar';

const PROJECT_STEP_TABS = [
  {
    id: 'workflows-tab-1',
    title: intl.formatMessage({
      id: 'filespage.files_list.tab_title.workflows',
      defaultMessage: 'Workflows',
    }),
  },
  {
    id: 'files-tab-2',
    title: intl.formatMessage({
      id: 'filespage.files_list.tab_title.files',
      defaultMessage: 'Files',
    }),
  },
];

const PROJECT_STEP_DEFAULT_ACTIVE_TAB_ID = PROJECT_STEP_TABS.at(0).id;

const WorkflowDynamicNavigationBar = () => {
  const navigationHistory = useSelector(getNavigationBarHistory);
  const dispatch = useDispatch();
  const [project, setProject] = useState(navigationHistory?.project);
  const navigationHistoryActiveTab =
    navigationHistory?.projectActiveTabId || PROJECT_STEP_DEFAULT_ACTIVE_TAB_ID;
  const [projectActiveTabId, setProjectActiveTabId] = useState(
    navigationHistoryActiveTab,
  );
  const intl = useIntl();
  const projectId = project?.id;
  const { showModal } = useModal();
  const { hideDialog } = useDialog();
  const { getThemeLogoUrl } = useApplication();
  const { getWorkflowsList } = useWorkflowList();
  const { projectsQuery } = useProjectQueries();
  const { projectWorkflowsQuery } = useWorkflowQueries({ projectId });
  const { fileListItems } = useProjectFiles({
    projectId,
    displayedInModal: true,
    action: (file) => {
      hideDialog(ModalDataTypes.NAVIGATION);
      showModal(MODAL_IDS.FILE_PREVIEW, { file });
    },
  });
  const wrapperRef = useRef(null);
  const logoUrl = getThemeLogoUrl();

  const { refetch: refetchProjectsQuery } = projectsQuery;

  const workflows = projectWorkflowsQuery.data;

  const initialStepNumber = useMemo(() => {
    if (navigationHistory?.project) {
      return NAVIGATION_STEPS.indexOf(PROJECT_STEP);
    }

    if (navigationHistory?.isProjectsStep) {
      return NAVIGATION_STEPS.indexOf(PROJECTS_STEP);
    }

    return NAVIGATION_STEPS.indexOf(NAVIGATION_BAR_STEP);
  }, [navigationHistory]);

  const { currentStepName, isStepActive, getStepIndex, goNext, goPrev } =
    useSteps({
      steps: NAVIGATION_STEPS,
      initialStepNumber: initialStepNumber,
    });
  const previousCurrentStepName = usePrevious(currentStepName);
  const currentStepIndex = getStepIndex(currentStepName);
  const navigationBarStepIndex = getStepIndex(NAVIGATION_BAR_STEP);
  const projectStepIndex = getStepIndex(PROJECT_STEP);
  const isNavigationBarStep = isStepActive(NAVIGATION_BAR_STEP);
  const isProjectsStep = isStepActive(PROJECTS_STEP);
  const isProjectStep = isStepActive(PROJECT_STEP);

  const updateNavigationHistory = useCallback(
    (nextHistory) =>
      dispatch(
        setNavigationBarHistory({
          ...navigationHistory,
          ...nextHistory,
        }),
      ),
    [dispatch, navigationHistory],
  );

  const projectsListItems = useMemo(
    () =>
      projectsQuery.data?.map((project) => ({
        id: project.id,
        label: project.name,
        leadingIconName: project.publicAccess ? 'language_0' : 'category_0',
        description: intl.formatMessage(
          {
            id: 'projectspage.projects_list.list_item.description',
            defaultMessage: 'Created: {date}',
          },
          {
            date: moment(project?.createdAt || moment()).format('DD/MM/YYYY'),
          },
        ),
        createdAt: project?.createdAt,
        onClick: () => {
          setProject(project);
          updateNavigationHistory({ project });
          goNext();
        },
      })),
    [intl, projectsQuery.data, updateNavigationHistory, goNext],
  );

  const workflowListItems = getWorkflowsList(workflows, {
    withMoreButton: false,
  });

  const handleProjectTabClick = useCallback(
    (tab) => {
      setProjectActiveTabId(tab.id);
      updateNavigationHistory({ projectActiveTabId: tab.id });
    },
    [updateNavigationHistory],
  );

  useGSAP(
    () => {
      const isMovingBackwards =
        getStepIndex(currentStepName) < getStepIndex(previousCurrentStepName);

      const startFrom = isMovingBackwards ? '-100%' : '100%';

      gsap.from('.animated-step', 0.35, { x: startFrom, opacity: 0 });
    },
    {
      scope: wrapperRef,
      dependencies: [currentStepName, getStepIndex, currentStepName],
    },
  );

  useEffect(() => {
    if (currentStepIndex > navigationBarStepIndex) {
      if (!navigationHistory?.isProjectsStep) {
        updateNavigationHistory({ isProjectsStep: true });
      }

      return;
    }

    if (navigationHistory?.isProjectsStep) {
      updateNavigationHistory({ isProjectsStep: false });
    }
  }, [
    currentStepIndex,
    navigationBarStepIndex,
    navigationHistory,
    updateNavigationHistory,
  ]);

  useEffect(() => {
    if (currentStepIndex < projectStepIndex && project) {
      setProject(null);
      updateNavigationHistory({ project: null, projectActiveTabId: null });
      setProjectActiveTabId(PROJECT_STEP_DEFAULT_ACTIVE_TAB_ID);
    }
  }, [currentStepIndex, projectStepIndex, project, updateNavigationHistory]);

  useEffect(() => {
    if (isProjectsStep && !projectsQuery.isFetched) {
      refetchProjectsQuery();
    }
  }, [isProjectsStep, projectsQuery.isFetched, refetchProjectsQuery]);

  return (
    <Wrapper ref={wrapperRef} data-testid={dataTestId}>
      <Header data-testid={`${dataTestId}__header`}>
        <RouterLink data-testid={`${dataTestId}__logo-link`} to="/">
          {logoUrl ? <ImgLogo src={logoUrl} /> : <Logo />}
        </RouterLink>
      </Header>

      {isNavigationBarStep && (
        <div className="animated-step">
          <NavigationBar onProjectsItemClick={goNext} withLogo={false} />
        </div>
      )}

      {!isNavigationBarStep && (
        <Content className="animated-step">
          {isProjectsStep && (
            <ProjectsPageContent
              headerLeadingIconButtonIconName="arrow_back_0"
              loading={!projectsQuery.isFetched || projectsQuery.isLoading}
              onHeaderLeadingIconButtonClick={goPrev}
              projectsListItems={projectsListItems}
            />
          )}

          {isProjectStep && (
            <>
              <PageHeader
                dataTestId={`${dataTestId}__header`}
                size={PAGE_HEADER_VARIANT_LARGE}
                title={project?.name || ''}
                leadingIconButtonIconName="arrow_back_0"
                onLeadingIconButtonClick={goPrev}
              />

              <TabsContainer>
                <Tabs
                  dataTestId={`${dataTestId}__tabs`}
                  variant={TABS_VARIANT_TEXT}
                  tabs={PROJECT_STEP_TABS.map((tab) => ({
                    ...tab,
                    active: tab.id === projectActiveTabId,
                  }))}
                  onTabClick={handleProjectTabClick}
                />
              </TabsContainer>
            </>
          )}

          {isProjectStep &&
            projectActiveTabId === PROJECT_STEP_TABS.at(0).id && (
              <WorkflowsPageContent
                loading={!projectWorkflowsQuery?.isFetched}
                project={project}
                workflowListItems={workflowListItems}
              />
            )}

          {isProjectStep &&
            projectActiveTabId === PROJECT_STEP_TABS.at(1).id && (
              <FilePageContent
                headerTitle=""
                fileListItems={fileListItems}
                projectId={projectId}
              />
            )}
        </Content>
      )}
    </Wrapper>
  );
};

WorkflowDynamicNavigationBar.propTypes = {};

export default WorkflowDynamicNavigationBar;
