import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Main from '@containers/Main';
import Providers from '@containers/Providers';
import {
  redefineConsoleMethods,
  initGlobalErrorHandlers,
} from '@utils/desktop';
import enableMockServer from './mocks/enableMockServer';

const appContainer = document.getElementById('ai-sync');

if (DESKTOP_APP) {
  redefineConsoleMethods();
  initGlobalErrorHandlers();
}

// Create a root.
const root = createRoot(appContainer);
let isMounted = false;

const renderApp = () => {
  root.render(
    <BrowserRouter>
      <Providers>
        <Main />
      </Providers>
    </BrowserRouter>,
  );
};

const waitForApiServerUrl = () => {
  return new Promise((resolve) => {
    const checkUrl = () => {
      if (window?.apiServerUrl) {
        // eslint-disable-next-line no-console
        console.log('window.apiServerUrl', window.apiServerUrl);
        resolve();
      } else {
        setTimeout(checkUrl, 50);
      }
    };

    checkUrl();
  });
};

document.addEventListener('DOMContentLoaded', async () => {
  if (DESKTOP_APP) {
    await waitForApiServerUrl();
  }
  isMounted = true;

  enableMockServer().then(() => {
    renderApp();
  });
});

if (module?.hot && isMounted) {
  module.hot.accept('@containers/Main', () => renderApp());
}
