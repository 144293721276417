import { useSelector } from 'react-redux';
import { clone } from 'lodash';
import useSystemTheme from '@hooks/useSystemTheme';
import {
  getIsLoggedIn,
  getUserAccountPreference,
} from '@selectors/loginSelectors';
import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
import { useEffect, useMemo, useState } from 'react';
import { defaultThemes } from '@stylesheets/theme';
import useApplication from '@hooks/application/useApplication';
import { THEME_PREFERENCE_KEY } from '@constants/utilityConstants';
import { themeTypes } from '@constants/accountSettings';

export default function useApplicationTheme() {
  const { getThemeData } = useApplication();
  const customThemeData = getThemeData();
  const isUserLoggedIn = useSelector(getIsLoggedIn());
  const userThemePreference = useSelector(
    getUserAccountPreference('USER_ACCOUNT_THEME'),
  );
  const systemTheme = useSystemTheme();
  const localStorageTheme = getFromLocalStorage(THEME_PREFERENCE_KEY);
  const [chosenThemeName, setChosenThemeName] = useState(systemTheme);
  const [theme, setTheme] = useState(defaultThemes);

  const selectedTheme = useMemo(() => {
    if (isUserLoggedIn && userThemePreference) {
      return userThemePreference;
    }

    if (
      [
        themeTypes.SYSTEM.value,
        themeTypes.DARK.value,
        themeTypes.LIGHT.value,
      ].includes(localStorageTheme)
    ) {
      return localStorageTheme;
    }

    return systemTheme;
  }, [systemTheme, isUserLoggedIn, userThemePreference, localStorageTheme]);

  const systemThemeIsSelected = useMemo(() => {
    if (isUserLoggedIn) {
      return selectedTheme === themeTypes.SYSTEM.value;
    }

    return localStorageTheme === themeTypes.SYSTEM.value;
  }, [isUserLoggedIn, selectedTheme, localStorageTheme]);

  useEffect(() => {
    if (customThemeData?.colors?.schemes) {
      const customTheme = clone(defaultThemes);

      const colorsSource = customThemeData?.colors?.palettes || {};

      customTheme.lightTheme.colors = {
        ...customThemeData.colors?.schemes?.light,
        colorsSource,
      };

      customTheme.darkTheme.colors = {
        ...customThemeData.colors?.schemes?.dark,
        colorsSource,
      };

      setTheme(customTheme);
    }
  }, [customThemeData]);

  useEffect(() => {
    setChosenThemeName(systemThemeIsSelected ? systemTheme : selectedTheme);
  }, [systemThemeIsSelected, systemTheme, selectedTheme]);

  useEffect(() => {
    if (isUserLoggedIn && userThemePreference) {
      addToLocalStorage(THEME_PREFERENCE_KEY, userThemePreference);
    }
  }, [isUserLoggedIn, userThemePreference]);

  return {
    applicationThemeName: chosenThemeName,
    applicationTheme: theme,
  };
}
