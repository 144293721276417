import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs, { TABS_VARIANT_TEXT } from '@components/2-molecules/Tabs';
import { TabsContainer } from '@components/3-organisms/PageList/PageList.styled';
import { SETTING_TABS } from '@constants/settingTabs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getHasAdminRights,
  getHasSuperAdminRights,
} from '@selectors/loginSelectors';
import useFeatureFlags from '@app/hooks/featureflags/useFeatureFlags';
import { FULL_SCREEN_OPERATOR_FEATURE } from '@app/constants/featureFlagConstants';

const SettingsTabs = ({ activeTab }) => {
  const history = useHistory();
  const superAdminRights = useSelector(getHasSuperAdminRights());
  const adminRights = useSelector(getHasAdminRights());
  const hideUsers = DESKTOP_APP;
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const isFullScreenOperatorEnabled = isFeatureFlagEnabled(
    FULL_SCREEN_OPERATOR_FEATURE,
  );

  const tabs = useMemo(() => {
    const allowedTabs = Object.entries(SETTING_TABS).filter(([tabName, _]) => {
      if (!isFullScreenOperatorEnabled && tabName === 'PREFERENCES') {
        return false;
      }

      if ((!adminRights || hideUsers) && tabName === 'USERS') {
        return false;
      }

      if (!superAdminRights && tabName === 'LICENSES') {
        return false;
      }

      return true;
    }, {});

    return allowedTabs;
  }, [hideUsers, isFullScreenOperatorEnabled, adminRights, superAdminRights]);

  const handleTabClick = useCallback(
    (tab) => history.push(tab?.link),
    [history],
  );

  return (
    <TabsContainer>
      <Tabs
        dataTestId={`settings-page__tabs`}
        variant={TABS_VARIANT_TEXT}
        tabs={tabs.map(([_, tab]) => ({
          ...tab,
          active: activeTab === tab.title,
        }))}
        onTabClick={handleTabClick}
      />
    </TabsContainer>
  );
};

SettingsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default SettingsTabs;
