import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWorkflow from '@hooks/workflows/useWorkflow';
import usePrevious from '@hooks/usePrevious';
import { getComputationProgressHandler } from '@selectors/computationProgressSelectors';
import { setComputationProgressHandler } from '@actions/computationProgressActions';
import { toolpathStages } from '@constants/toolpathStages';
import { capitalize, isUndefined } from 'lodash';
import useAwaitingComputationMessage from './useAwaitingComputationMessage';
import useOperator from '@hooks/operators/useOperator';
import { getPredecessorsRecursively } from '@utils/graphUtils';
import {
  resetDownloadStreamAction,
  resetSafetyCheckAction,
  selectDownloadStreamAction,
  selectSafetyCheckAction,
} from '@app/reducers/workflowSlice';
import useSnackbar from '../useSnackbar';
import { useIntl } from 'react-intl';
import { getSelectedOutputOperatorId } from '@app/selectors/conceptSelectors';
import useDownloadStreamToolpath from '../operators/useDownloadStreamToolpath';

const PROGRESS_SIMULATION_STEP_UPDATE_MAX = 1800;
const PROGRESS_SIMULATION_STEP_UPDATE_MIN = 350;
const PROGRESS_STEP_UPDATE = 1;

const removeProgressStages = [
  toolpathStages.COMPLETE,
  toolpathStages.CANCELLED,
  toolpathStages.FAILED,
];

let computationSimulationInterval;

export default function useWorkflowComputation() {
  const dispatch = useDispatch();
  const currentComputationProgress = useSelector(getComputationProgressHandler);
  const safetyCheckAction = useSelector(selectSafetyCheckAction);
  const downloadStreamAction = useSelector(selectDownloadStreamAction);
  const selectedOutputOperatorId = useSelector(getSelectedOutputOperatorId());
  const { downloadToolpath, streamToolpath } = useDownloadStreamToolpath();
  const {
    getSelectedWorkflow,
    setOperatorComputedFlag,
    setWorkflowComputingFlag,
    getIsWorkflowComputing,
    setPredecessorsAsComputed,
  } = useWorkflow();
  const { getOperatorGraphDependencies } = useOperator();
  const dependedGraph = useMemo(
    () => getOperatorGraphDependencies(),
    [getOperatorGraphDependencies],
  );
  const intl = useIntl();
  const { showSnackbar } = useSnackbar();
  const { clearAwaitingComputationMessage } = useAwaitingComputationMessage();
  const workflow = getSelectedWorkflow();
  const isWorkflowComputing = getIsWorkflowComputing();
  const previousIsWorkflowComputing = usePrevious(isWorkflowComputing);
  const formatProgress = useCallback(
    (number = 0) => parseInt((number || 0.0) * 100.0, 10),
    [],
  );

  const setProgressToProgressBar = useCallback(
    (progress = 0, withDecimals = true) => {
      const progressElementToUpdate = document.getElementById(
        'loader-progress-text',
      );

      progress = Math.min(progress, 100.0);

      if (!progressElementToUpdate) return;

      if (withDecimals) {
        progressElementToUpdate.textContent = `${progress.toFixed(2)}%`;

        return;
      }

      progressElementToUpdate.textContent = `${progress}%`;
    },
    [],
  );

  const handleComputationFinish = useCallback(() => {
    clearInterval(computationSimulationInterval);
    setProgressToProgressBar(100, false);
  }, [setProgressToProgressBar]);

  const getRandomWaitingTime = useCallback(() => {
    return (
      Math.floor(
        Math.random() *
          (PROGRESS_SIMULATION_STEP_UPDATE_MAX -
            PROGRESS_SIMULATION_STEP_UPDATE_MIN +
            1),
      ) + PROGRESS_SIMULATION_STEP_UPDATE_MIN
    );
  }, []);

  const manageComputeAndDownloadCompletion = useCallback(() => {
    if (downloadStreamAction) {
      if (downloadStreamAction === 'stream') {
        streamToolpath(selectedOutputOperatorId);
      } else {
        downloadToolpath(selectedOutputOperatorId);
      }
    }
    dispatch(resetDownloadStreamAction());
  }, [
    dispatch,
    downloadStreamAction,
    downloadToolpath,
    streamToolpath,
    selectedOutputOperatorId,
  ]);

  const manageSafetyCheckCompletion = useCallback(() => {
    if (safetyCheckAction) {
      showSnackbar(
        {
          endingButtonTitle: `${capitalize(safetyCheckAction)} Toolpath`,
          onEndingButtonClick: () => {
            if (safetyCheckAction === 'stream') {
              streamToolpath(selectedOutputOperatorId);
            } else {
              downloadToolpath(selectedOutputOperatorId);
            }
          },
          text: intl.formatMessage(
            {
              id: 'safetycheck.snackbar.complete',
              defaultMessage:
                'Safety check completed successfully. The toolpath is now available for {action}.',
            },
            { action: safetyCheckAction },
          ),
        },
        true,
      );
    }
    dispatch(resetSafetyCheckAction());
  }, [
    safetyCheckAction,
    dispatch,
    showSnackbar,
    intl,
    downloadToolpath,
    streamToolpath,
    selectedOutputOperatorId,
  ]);

  const manageSafetyCheckFailure = useCallback(() => {
    if (safetyCheckAction) {
      showSnackbar(
        {
          text: intl.formatMessage({
            id: 'safetycheck.snackbar.issue_detected',
            defaultMessage:
              'Safety issue detected. It is recommended to fix the issue and run the safety check again before proceeding with a safe print.',
          }),
        },
        true,
      );
      dispatch(resetSafetyCheckAction());
    }
  }, [safetyCheckAction, dispatch, showSnackbar, intl]);

  const runProgressSimulation = useCallback(
    (progress = 0) => {
      let nextProgress = progress;

      clearInterval(computationSimulationInterval);
      setProgressToProgressBar(progress);

      computationSimulationInterval = setInterval(() => {
        nextProgress = +nextProgress + 0.01;
        const isLastAllowedUpdate = `${nextProgress}`?.includes('.99');

        setProgressToProgressBar(nextProgress);

        if (isLastAllowedUpdate) {
          clearInterval(computationSimulationInterval);
        }
      }, getRandomWaitingTime());
    },
    [getRandomWaitingTime, setProgressToProgressBar],
  );

  const handleComputationMessage = useCallback(
    (event = {}) => {
      const nextComputationProgress = event?.data || {};
      const belongToSelectedWorkflow =
        nextComputationProgress?.conceptId === workflow?.id;

      let skipAction = !belongToSelectedWorkflow;
      if (!skipAction && currentComputationProgress?.id) {
        //check if nextComputation is in currentComputation successors
        //if YES than skipAction=true
        getPredecessorsRecursively(
          dependedGraph,
          currentComputationProgress?.id,
        ).forEach((predecessorId) => {
          if (predecessorId === nextComputationProgress?.id) {
            skipAction = true;
          }
          setOperatorComputedFlag(predecessorId, true);
        });
      }

      if (skipAction) return;

      clearAwaitingComputationMessage();

      if (!workflow.computing) {
        setWorkflowComputingFlag(workflow.id, true);
      }

      const currentProgressNumber = Math.floor(
        formatProgress(currentComputationProgress?.progress),
      );
      const nextProgressNumber = Math.ceil(
        formatProgress(nextComputationProgress?.progress),
      );

      const nextMinAllowedUpdateStep = Math.min(
        currentProgressNumber + PROGRESS_STEP_UPDATE,
        100.0,
      );

      const computationJustStarted =
        isUndefined(currentComputationProgress?.id) ||
        (nextProgressNumber < 100.0 && currentProgressNumber < 1);

      if (computationJustStarted) {
        dispatch(setComputationProgressHandler(nextComputationProgress));
        clearInterval(computationSimulationInterval);
        return;
      }

      const operatorComputationCompleted = removeProgressStages.includes(
        nextComputationProgress?.stage,
      );

      const computationCompleted =
        nextComputationProgress?.isLastOperatorToCompute &&
        operatorComputationCompleted;

      if (operatorComputationCompleted) {
        const computedOpeatorId = nextComputationProgress?.id;

        setOperatorComputedFlag(computedOpeatorId, true);
        clearInterval(computationSimulationInterval);

        if (!computationCompleted) return;
      }

      if (computationCompleted) {
        dispatch(setComputationProgressHandler(nextComputationProgress));

        handleComputationFinish();

        return;
      }

      const switchComputationStep =
        currentComputationProgress?.id !== nextComputationProgress?.id;

      if (switchComputationStep) {
        runProgressSimulation(nextProgressNumber);
        dispatch(setComputationProgressHandler(nextComputationProgress));
        setPredecessorsAsComputed(nextComputationProgress?.id, dependedGraph);
        return;
      }

      const canUpdateStep = nextProgressNumber >= nextMinAllowedUpdateStep;

      if (!canUpdateStep) return;

      runProgressSimulation(nextProgressNumber);
      dispatch(setComputationProgressHandler(nextComputationProgress));
    },
    [
      dependedGraph,
      dispatch,
      workflow,
      currentComputationProgress?.id,
      currentComputationProgress?.progress,
      formatProgress,
      runProgressSimulation,
      setOperatorComputedFlag,
      handleComputationFinish,
      setWorkflowComputingFlag,
      clearAwaitingComputationMessage,
      setPredecessorsAsComputed,
    ],
  );

  useEffect(() => {
    if (previousIsWorkflowComputing && !isWorkflowComputing) {
      clearInterval(computationSimulationInterval);
    }
  }, [isWorkflowComputing, previousIsWorkflowComputing]);

  return {
    handleComputationMessage,
    manageSafetyCheckCompletion,
    manageComputeAndDownloadCompletion,
    manageSafetyCheckFailure,
  };
}
