import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { organizationQueryKeys } from '@hooks/organization/useOrganizationQueries';
import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
import { CURRENT_USER_KEY_LOCAL_STORAGE } from '@constants/utilityConstants';
import { useDispatch } from 'react-redux';
import {
  setCurrentUser,
  setCurrentUserPreference,
} from '@actions/loginActions';

export const organizationMutationKeys = {
  createUser: ['showLoader', 'createUser'],
  updateUser: ['showLoader', 'updateUser'],
  updateUserPreference: ['showLoader', 'updateUserPreference'],
  deleteUser: ['showLoader', 'deleteUser'],
};

export default function useOrganizationMutations() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const createUserMutation = useMutation({
    mutationKey: organizationMutationKeys.createUser,
    mutationFn: ({ user } = {}) =>
      client.put(`${endpoints.users}`, user).then((res) => res.data),
    onSuccess: (addedUser) => {
      const updater = (data) => {
        return data ? [...data, addedUser] : [addedUser];
      };
      queryClient.setQueryData(organizationQueryKeys.users, updater);
    },
  });

  const updateUserMutation = useMutation({
    mutationKey: organizationMutationKeys.updateUser,
    mutationFn: ({ user, username } = {}) =>
      client
        .post(`${endpoints.users}/${username}`, user)
        .then((res) => res.data),
    onSuccess: (_, { user, id, currentUser } = {}) => {
      if (currentUser) {
        dispatch(setCurrentUser(currentUser));
      }

      const updater = (data) => {
        return data
          ? data?.map((userStored) => {
              if (userStored.id === id) {
                return {
                  ...userStored,
                  username: user.editedUsername,
                  name: user.displayName,
                  lastUpdate: new Date().toISOString(),
                  expiryDate: user.expiryDate,
                  role: user.role,
                };
              }
              return userStored;
            })
          : undefined;
      };
      queryClient.setQueryData(organizationQueryKeys.users, updater);
    },
  });

  const updateUserPreferenceMutation = useMutation({
    mutationKey: organizationMutationKeys.updateUserPreference,
    mutationFn: ({ userPreference } = {}) =>
      client
        .post(endpoints.userPreference, userPreference)
        .then((res) => res.data),
    onSuccess: (returnedData) => {
      if (returnedData?.preferenceKey) {
        const localStorageUser = getFromLocalStorage(
          CURRENT_USER_KEY_LOCAL_STORAGE,
        );
        const user = {
          ...localStorageUser,
          userPreferences: {
            ...localStorageUser.userPreferences,
            [returnedData.preferenceKey]: returnedData.preferenceValue,
          },
        };

        addToLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE, user);

        dispatch(
          setCurrentUserPreference(
            returnedData?.preferenceKey,
            returnedData.preferenceValue,
          ),
        );
      }
    },
  });

  const deleteUserMutation = useMutation({
    mutationKey: organizationMutationKeys.deleteUser,
    mutationFn: ({ username } = {}) =>
      client.delete(`${endpoints.users}/${username}`).then((res) => res.data),
    onSuccess: (_, { id }) => {
      const updater = (data) => {
        return data ? data.filter((user) => user.id !== id) : [];
      };
      queryClient.setQueryData(organizationQueryKeys.users, updater);
    },
  });

  return {
    createUserMutation,
    updateUserMutation,
    updateUserPreferenceMutation,
    deleteUserMutation,
  };
}
