import styled, { css } from 'styled-components';
import { Icon } from '@components/1-atoms/InfoRow/InfoRow.styled';
import { when } from '@stylesheets/helpers';
import InfoRow from '@components/1-atoms/InfoRow';

export const ContentWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-left: ${spacing.level6};
    padding-right: ${spacing.level6};
  `}
`;

export const SafetyCheckItem = styled(InfoRow)`
  white-space: pre-line;
  ${({ iconColor, theme: { colors } }) => css`
    ${Icon} {
      ${when(
        iconColor,
        `
          fill: ${colors?.[iconColor] || iconColor};
        `,
      )}
    }
  `}
`;
