import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import usePrintingStatus from '@hooks/printers/usePrintingStatus';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import { getProjectInstructionsURL } from '@selectors/conceptSelectors';
import Paper from '@components/1-atoms/Paper';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_TEXT,
  BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/Button';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';
import Loader, {
  LOADER_ICON_ANIMATION_VARIANT_PULSE,
} from '@components/2-molecules/Loader';
import {
  ColorInfoRow,
  Content,
  LoaderWrapper,
} from './WorkflowToolpathBar.styled';
import { setShowSafetyCheckResults } from '@app/actions/toolBarActions';
import { getShowSafetyCheckResults } from '@app/selectors/toolBarSelector';
import useDialog from '@app/hooks/useDialog';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useFeatureFlags from '@app/hooks/featureflags/useFeatureFlags';
import { DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE } from '@app/constants/featureFlagConstants';
import { useFormContext } from 'react-hook-form';
import useDownloadStreamToolpath from '@app/hooks/operators/useDownloadStreamToolpath';
import useSnackbar from '@app/hooks/useSnackbar';
import {
  setDownloadStreamAction,
  setSafetyCheckAction,
} from '@app/reducers/workflowSlice';
import { getDependentNodes } from '@utils/visualTool';
import useSafetyCheck from '@hooks/operators/useSafetyCheck';
import { br } from '@utils/render';

// this will be refactored once Toolpath categories are implemented, we can then find all Safety Check inputs from their category
const SAFETY_CHECK_VALUES = [
  'Check Axis Limits',
  'Check Workspace Limits',
  'Check Reach Limits',
  'Check Wrist Singularities',
  'Enable Collision Detection',
];
const WorkflowToolpathBar = () => {
  const [showPrintingProgress, setShowPrintingProgress] = useState(false);
  const projectInstructionsURL = useSelector(getProjectInstructionsURL);
  const {
    getSelectedWorkflow,
    getWorkflowPrinter,
    getIsWorkflowEditable,
    updateValuesAndComputeWorkflow,
  } = useWorkflow();
  const {
    getSelectedOperator,
    unselectSelectedOperator,
    getOperatorVisibleInputs,
    getIsDownloadStreamButtonAvailable,
    getOperatorGraphDependencies,
    getIsOperatorModified,
  } = useOperator();
  const {
    getSafetyCheckIconText,
    getSafetyCheckIconColor,
    getSafetyCheckPassed,
    getFailedSafetyCheckLabels,
  } = useSafetyCheck();
  const { showDialog } = useDialog();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { setValue, getValues, handleSubmit, formState } = useFormContext();
  const { showSnackbar } = useSnackbar();
  const intl = useIntl();
  const dispatch = useDispatch();

  const workflow = getSelectedWorkflow();
  const printer = getWorkflowPrinter(workflow);
  const printerId = printer?.id;
  const selectedOperator = getSelectedOperator();
  const safetyCheckIconColor = useSelector(getSafetyCheckIconColor);
  const safetyCheckIconText = useSelector(getSafetyCheckIconText);
  const selectedOperatorId = selectedOperator?.id;
  const isWorkflowEditable = getIsWorkflowEditable();
  const {
    isPrinting,
    printerStatus,
    formattedPrinterStatusName,
    printingProgress,
    stopPrint,
  } = usePrintingStatus(printerId);
  const operatorInputs = getOperatorVisibleInputs(selectedOperator);
  const { downloadToolpath, streamToolpath } = useDownloadStreamToolpath();

  usePrinterSocket();

  const enableSafetyChecksAndCompute = useCallback(
    (intentStreaming) => {
      const formFields = operatorInputs
        .filter((i) => SAFETY_CHECK_VALUES.includes(i.name))
        .map((i) => `${i.operatorId}.${i.id}`);
      formFields.forEach((f) =>
        setValue(f, 'true', { shouldTouch: true, shouldDirty: true }),
      );
      handleSubmit(async (formValues) => {
        await updateValuesAndComputeWorkflow(
          formValues,
          { safetyCheck: true, intentStreaming },
          selectedOperatorId,
        );
        const action = intentStreaming ? 'stream' : 'download';
        dispatch(setSafetyCheckAction(action));
        showSnackbar({
          text: intl.formatMessage(
            {
              id: 'safetycheck.snackbar.in_progress',
              defaultMessage:
                'Safety check in progress.\nYou will receive a confirmation email shortly with a link to {action} the toolpath.',
            },
            { action },
          ),
        });
      })();
    },
    [
      operatorInputs,
      handleSubmit,
      setValue,
      updateValuesAndComputeWorkflow,
      selectedOperatorId,
      showSnackbar,
      intl,
      dispatch,
    ],
  );

  const computeAndDownloadOrStream = useCallback(
    (download) => {
      const selectedOperator = getSelectedOperator();
      const dependedGraph = getOperatorGraphDependencies();
      const dependentNodes = getDependentNodes(
        dependedGraph,
        selectedOperator.id,
      );
      const dependentOperators = dependentNodes.map((op) => op.getContent());
      const selectedOperatorAndDependencies = [
        ...dependentOperators,
        selectedOperator,
      ];

      const hasModifiedOperators = selectedOperatorAndDependencies?.some(
        (operator) =>
          getIsOperatorModified(operator, {
            formState: formState,
            getValues: getValues,
          }),
      );

      if (hasModifiedOperators) {
        handleSubmit(async (formValues) => {
          await updateValuesAndComputeWorkflow(
            formValues,
            {},
            selectedOperatorId,
          );
          const action = download ? 'download' : 'stream';
          dispatch(setDownloadStreamAction(action));
        })();
      } else {
        const safetyCheckPassed = getSafetyCheckPassed();
        if (safetyCheckPassed) {
          if (download) {
            downloadToolpath(selectedOperatorId);
          } else {
            streamToolpath(selectedOperatorId);
          }
        } else {
          const issues = getFailedSafetyCheckLabels();
          const issuesList = issues.map((label) => (
            <span key={label}>
              • {intl.formatMessage({ id: label })}
              <br></br>
            </span>
          ));
          showDialog(ModalDataTypes.PROMPT, {
            dataTestId: 'download-stream-dialog',
            title: intl.formatMessage({
              id: 'general.warning',
              defaultMessage: 'Warning',
            }),
            subtitle: intl.formatMessage(
              {
                id: 'downloadstreamdialog.safetycheck.attention',
                defaultMessage:
                  'The safety check has identified the following issues <br></br><br></br> {issues} <br></br> Proceeding with the current Toolpath may result in collisions.<br></br> Do you still wish to continue?',
              },
              {
                issues: issuesList,
                br,
              },
            ),
            secondaryButtonLabel: intl.formatMessage({
              id: 'general.cancel',
              defaultMessage: 'Cancel',
            }),
            primaryButtonLabel: intl.formatMessage({
              id: 'general.confirm',
              defaultMessage: 'Confirm',
            }),
            onPrimaryButtonClick: () => {
              if (download) {
                downloadToolpath(selectedOperatorId);
              } else {
                streamToolpath(selectedOperatorId);
              }
            },
          });
        }
      }
    },
    [
      getFailedSafetyCheckLabels,
      intl,
      getSafetyCheckPassed,
      showDialog,
      dispatch,
      formState,
      getValues,
      getIsOperatorModified,
      getOperatorGraphDependencies,
      getSelectedOperator,
      handleSubmit,
      updateValuesAndComputeWorkflow,
      downloadToolpath,
      streamToolpath,
      selectedOperatorId,
    ],
  );

  const downloadStreamButtonAvailable = useMemo(
    () =>
      getIsDownloadStreamButtonAvailable({
        formState: formState,
        getValues: getValues,
      }),
    [getIsDownloadStreamButtonAvailable, formState, getValues],
  );

  const onDownloadClick = useCallback(() => {
    const safetyCheckValues = operatorInputs.filter((i) =>
      SAFETY_CHECK_VALUES.includes(i.name),
    );
    const completeSafetyCheckAlreadyExecuted = safetyCheckValues.every(
      (i) => i.value === true || i.value === 'true',
    );
    if (
      !completeSafetyCheckAlreadyExecuted &&
      isFeatureFlagEnabled(DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE)
    ) {
      showDialog(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH, {
        isStream: false,
        toolpathId: selectedOperatorId,
        onConfirm: (runSafetyChecks) =>
          runSafetyChecks
            ? enableSafetyChecksAndCompute(false)
            : computeAndDownloadOrStream(true),
      });
    } else {
      computeAndDownloadOrStream(true);
    }
  }, [
    selectedOperatorId,
    computeAndDownloadOrStream,
    operatorInputs,
    showDialog,
    isFeatureFlagEnabled,
    enableSafetyChecksAndCompute,
  ]);

  const openSafetyCheckResults = useCallback(() => {
    dispatch(setShowSafetyCheckResults(true));
  }, [dispatch]);

  const safetyCheckResults = useSelector(getShowSafetyCheckResults);

  const isComputationStreamable = useCallback(() => {
    if (!printerStatus) return false;

    const isIdle = printerStatus.status === 'IDLE';
    const currentDate = new Date();
    // TODO store the cutoff interval somewhere else
    const cutoffDate = new Date(currentDate.getTime() - 5000);
    const isStreamable = isIdle && new Date(printerStatus.time) > cutoffDate;

    return isStreamable;
  }, [printerStatus]);

  const onStreamClick = useCallback(async () => {
    const safetyCheckInputs = operatorInputs.filter((i) =>
      SAFETY_CHECK_VALUES.includes(i.name),
    );
    const completeSafetyCheckAlreadyExecuted = safetyCheckInputs.every(
      (i) => i.value === true || i.value === 'true',
    );
    if (
      !completeSafetyCheckAlreadyExecuted &&
      isFeatureFlagEnabled(DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE)
    ) {
      showDialog(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH, {
        isStream: true,
        toolpathId: selectedOperatorId,
        onConfirm: (runSafetyChecks) =>
          runSafetyChecks
            ? enableSafetyChecksAndCompute(true)
            : computeAndDownloadOrStream(false),
      });
    } else {
      computeAndDownloadOrStream(false);
    }
  }, [
    selectedOperatorId,
    computeAndDownloadOrStream,
    operatorInputs,
    isFeatureFlagEnabled,
    showDialog,
    enableSafetyChecksAndCompute,
  ]);

  const togglePrintingProgress = useCallback(() => {
    setShowPrintingProgress((prev) => !prev);
  }, []);

  const stopPrinting = useCallback(() => {
    stopPrint();
    togglePrintingProgress();
  }, [stopPrint, togglePrintingProgress]);

  if (showPrintingProgress) {
    return (
      <LoaderWrapper>
        <Loader
          endingIconButtonIconName="stop_player_1"
          endingIconButtonTitle="Stop Printing"
          iconColor="success"
          iconName="Dot_1"
          iconAnimationVariant={LOADER_ICON_ANIMATION_VARIANT_PULSE}
          leadingIconButtonIconName="chevron_left_0"
          onEndingIconButtonClick={stopPrinting}
          onLeadingIconButtonClick={togglePrintingProgress}
          progress={printingProgress}
          title={formattedPrinterStatusName}
        />
      </LoaderWrapper>
    );
  }

  return (
    <Paper dataTestId="workflow-toolpath-bar">
      <Content withContent={!!projectInstructionsURL}>
        {safetyCheckResults && (
          <ColorInfoRow
            noPadding
            color={safetyCheckIconColor}
            label={safetyCheckIconText}
            leadingIconName={
              safetyCheckIconColor === 'success' ? 'done_0' : 'error_0'
            }
          />
        )}
        {!safetyCheckResults && projectInstructionsURL && (
          <>
            <IconButton
              iconColor={safetyCheckIconColor}
              iconName="health_and_safety_0"
              onClick={
                safetyCheckIconColor === 'outlineVariant'
                  ? undefined
                  : openSafetyCheckResults
              }
            />
            <Button
              disabled={!isWorkflowEditable || !downloadStreamButtonAvailable}
              variant={BUTTON_VARIANT_TEXT}
              iconName="download_0"
              stretch={false}
              onClick={onDownloadClick}
            >
              <FormattedMessage
                id="operator.progress.download_toolpath"
                defaultMessage="Download Toolpath"
              />
            </Button>

            {isComputationStreamable() && (
              <Button
                variant={BUTTON_VARIANT_PRIMARY}
                stretch={false}
                onClick={onStreamClick}
                disabled={!downloadStreamButtonAvailable}
              >
                <FormattedMessage id="general.stream" defaultMessage="Stream" />
              </Button>
            )}

            {isPrinting && (
              <Button
                variant={BUTTON_VARIANT_PLAIN}
                iconName="Dot_1"
                iconColor="success"
                stretch={false}
                onClick={togglePrintingProgress}
              >
                {formattedPrinterStatusName}
              </Button>
            )}
          </>
        )}

        {!safetyCheckResults && (
          <IconButton
            variant={ICON_BUTTON_VARIANT_PLAIN}
            iconName="close_0"
            onClick={unselectSelectedOperator}
          />
        )}
      </Content>
    </Paper>
  );
};

WorkflowToolpathBar.propTypes = {};

export default WorkflowToolpathBar;
