import { isEmpty } from 'lodash';
import actionTypes from '@actions';

import {
  CURRENT_USER_KEY_LOCAL_STORAGE,
  USER_PREFERENCES_KEY,
  USER_SESSION_PREFERENCES_KEY,
} from '@constants/utilityConstants';
import { getFromLocalStorage } from '@utils/localStorage';
import { getFromSessionStorage } from '@utils/sessionStorage';

const initialUserPreferences = {
  isComputeAutomatically: true,
  showQuickStartOnInitalPage: true,
};

const initialUserSessionPreferences = {
  dontShowWizardForTheSession: false,
};

const getLocalStorageUser = () =>
  getFromLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE);

const getUserPreference = () => {
  const localStorageUser = getLocalStorageUser();
  const localStorageUserPreferences =
    (localStorageUser &&
      getFromLocalStorage(`${USER_PREFERENCES_KEY}.${localStorageUser.id}`)) ||
    {};

  const userPreferences = {
    ...initialUserPreferences,
    ...localStorageUserPreferences,
  };

  return userPreferences;
};

const geUserSessionPreferences = () => {
  const localStorageUser = getLocalStorageUser();
  const sessionStorageUserPreferences =
    (localStorageUser &&
      getFromSessionStorage(
        `${USER_SESSION_PREFERENCES_KEY}.${localStorageUser.id}`,
      )) ||
    {};

  const userSessionPreferences = {
    ...initialUserSessionPreferences,
    ...sessionStorageUserPreferences,
  };

  return userSessionPreferences;
};

const localStorageUser = getLocalStorageUser();
const loggedIn = !isEmpty(localStorageUser);
const emptyUser = {};

const getInitialState = () => ({
  loginTokenExpired: false,
  tokenAuthentificated: false,
  user: loggedIn ? localStorageUser : emptyUser,
  userPreferences: getUserPreference(),
  userSessionPreferences: geUserSessionPreferences(),
  loginFailed: false,
});

const loginReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER: {
      return {
        ...state,
        user: action.payload?.user || {},
      };
    }

    case actionTypes.SET_CURRENT_USER_PREFERENCE: {
      const { preferenceName, preferenceValue } = action.payload || {};
      if (!preferenceName) return state;

      return {
        ...state,
        user: {
          ...state.user,
          userPreferences: {
            ...state.user.userPreferences,
            [preferenceName]: preferenceValue,
          },
        },
      };
    }

    case actionTypes.TOKEN_AUTHENTIFICATION_SUCCESS: {
      return {
        ...state,
        loginTokenExpired: false,
        tokenAuthentificated: true,
      };
    }

    case actionTypes.TOKEN_EXPIRED:
      return {
        user: emptyUser,
        userPreferences: initialUserPreferences,
        userSessionPreferences: initialUserSessionPreferences,
        loginTokenExpired: true,
        tokenAuthentificated: true,
      };

    case actionTypes.LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        userPreferences: getUserPreference(),
        tokenAuthentificated: true,
        loginFailed: false,
      };

    case actionTypes.LOG_IN_REQUEST: {
      return {
        ...getInitialState(),
        user: emptyUser,
      };
    }

    case actionTypes.LOG_OUT_SUCCESS:
      return {
        ...getInitialState(),
        user: emptyUser,
        userPreferences: initialUserPreferences,
        userSessionPreferences: initialUserSessionPreferences,
        tokenAuthentificated: false,
      };

    case actionTypes.VALIDATE_MFA_CODE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          mfaEnabled: true,
        },
        loginFailed: false,
      };

    case actionTypes.DEACTIVATE_MFA_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          mfaEnabled: false,
        },
      };

    case actionTypes.LOG_IN_FAILURE:
      return {
        ...state,
        loginFailed: true,
      };

    case actionTypes.RESET_LOG_IN_FAILURE:
      return {
        ...state,
        loginFailed: false,
      };

    /* User preferences actions */

    case actionTypes.UPDATE_USER_PREFERENCE: {
      const { preferenceName, preferenceValue } = action?.payload || {};
      const preferenceExists = preferenceName in state.userPreferences;

      if (!preferenceName || !preferenceExists) return state;

      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          [preferenceName]: preferenceValue,
        },
      };
    }

    case actionTypes.UPDATE_USER_SESSION_PREFERENCE: {
      const { preferenceName, preferenceValue } = action?.payload || {};

      if (!preferenceName) return state;

      return {
        ...state,
        userSessionPreferences: {
          ...state.userSessionPreferences,
          [preferenceName]: preferenceValue,
        },
      };
    }

    case actionTypes.UPDATE_COMPUTE_AUTOMATICALLY:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          isComputeAutomatically:
            !state.userPreferences?.isComputeAutomatically,
        },
      };

    default:
      return state;
  }
};

export default loginReducer;
