import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const projectQueryKeys = {
  theme: ['theme'],
};

export default function useApplicationQueries() {
  const themeQuery = useQuery({
    queryKey: projectQueryKeys.theme,
    queryFn: () =>
      client
        .get(endpoints.theme, {
          ignoreGlobalErrorHandler: true,
        })
        .then((res) => res.data),
    enabled: false,
    initialData: {},
  });

  return {
    themeQuery,
  };
}
