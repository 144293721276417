import { isEmpty, pick } from 'lodash';
import { Directories } from '@constants/directories';
import {
  MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
  TOOLPATH_GENERATOR_OPERATOR_NAME,
} from '@constants/operatorsConstants';
import { getIsFetchesInProgress } from '@selectors/generalSelectors';
import { getCurrentUser } from '@selectors/loginSelectors';
import { getIsWorkflowComputing } from '@selectors/computationProgressSelectors';
import { isFetchInProgess } from '@selectors/fetchSelector';

const emptyArr = [];

export const getSelectedOperatorOutput =
  () =>
  ({ concepts }) =>
    concepts.ui.selectedOperatorOutput;

export const getSelectedOperatorOutputType =
  () =>
  ({ concepts }) =>
    concepts.ui.selectedOperatorOutputType;

export const getSelectedOperatorOutputDesignId =
  () =>
  ({ concepts }) =>
    concepts.ui.selectedOperatorOutput?.value;

export const getSelectedOperatorOutputId =
  () =>
  ({ concepts }) =>
    concepts.ui.selectedOperatorOutputId;
export const getSelectedOutputOperatorId =
  () =>
  ({ concepts }) =>
    concepts.ui.selectedOutputOperatorId;
export const getFrozenOutputIds =
  () =>
  ({ concepts }) =>
    concepts.ui.frozenOutputs?.map(({ id }) => id);
export const getFrozenOutputs =
  () =>
  ({ concepts }) =>
    concepts.ui.frozenOutputs;
export const getFocusmodel =
  () =>
  ({ concepts }) =>
    concepts.ui.focusmodel;
export const getLastFetchedModelId =
  () =>
  ({ concepts }) =>
    concepts.ui.lastFetchedModelId;
export const getFetchFailedModelIds =
  () =>
  ({ concepts }) =>
    concepts.ui.fetchFailedModelIds;
export const getSimulation =
  () =>
  ({ concepts }) =>
    concepts.ui.simulation;
export const getUnsavedModification =
  (id) =>
  ({ concepts }) =>
    concepts.ui.unsavedModifications[id];
export const getWorkflow =
  (workflowId) =>
  ({ concepts }) =>
    concepts.data.concepts.find(({ id }) => id === workflowId);
export const getIsUpdatingConcept =
  () =>
  ({ concepts }) =>
    concepts.ui.isUpdatingConcept;
export const getIsDeletingConcept =
  () =>
  ({ concepts }) =>
    concepts.ui.isDeletingConcept;
export const getConceptIdToDelete =
  () =>
  ({ concepts }) =>
    concepts.ui.conceptIdToDelete;
export const getIsOkayUpdatePrinter =
  () =>
  ({ concepts }) =>
    concepts.ui.isOkayUpdatePrinter;
export const getIsShowingUpgradeConfirmation =
  () =>
  ({ modal }) =>
    modal?.data?.type === 'upgradeOperators';
export const getIsShowingUpdatePrinterConfigConfirmation =
  () =>
  ({ modal }) =>
    modal?.data?.type === 'upgradeWorkflowPrinterConfiguration';
export const getCloningConcept =
  (directory) =>
  ({ concepts, designs }) => {
    const items = (() => {
      switch (directory) {
        case Directories.files:
          return designs.designMetadata;
        case Directories.workflows:
          return concepts.data.concepts;
        default:
          return emptyArr;
      }
    })();
    return items.find(({ id }) => id === concepts.ui.cloningConceptId);
  };
export const getDisplayMode =
  () =>
  ({ concepts }) =>
    concepts.ui.displayMode;
export const getIsShowingOuterWall =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingOuterWall;
export const getIsShowingInnerWall =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingInnerWall;
export const getIsShowingSupport =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingSupport;
export const getIsShowingSupportInterface =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingSupportInterface;
export const getIsShowingBrim =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingBrim;
export const getIsShowingMilling =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingMilling;
export const getIsShowingSkin =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingSkin;
export const getIsShowingInfill =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingInfill;
export const getIsShowingSeams =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingSeams;
export const getIsShowingSolid =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingSolid;
export const getIsShowingRotations =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingRotations;
export const getIsShowingVertices =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingVertices;
export const getIsShowingDirection =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingDirection;
export const getIsShowingDimensions =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingDimensions;
export const getIsShowingLinetypes =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingLinetypes;
export const getIsShowingGrid =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingGrid;
export const getIsShowingRobot =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingRobot;
export const getIsShowingWorkspace =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingWorkspace;
export const getIsShowingPrintingBed =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingPrintingBed;
export const getIsShowingEnclosure =
  () =>
  ({ concepts }) =>
    concepts.ui.isShowingEnclosure;

export const getLineData =
  () =>
  ({ concepts }) =>
    concepts.ui.lineData;

export const getCustomLineData =
  () =>
  ({ concepts }) =>
    concepts.ui.customLineData;
export const getCustomLineDataSelection =
  () =>
  ({ concepts }) =>
    concepts.ui.customLineDataSelection;
export const getLegendMinRange =
  () =>
  ({ concepts }) =>
    concepts.ui.legendMinRange;
export const getLegendMaxRange =
  () =>
  ({ concepts }) =>
    concepts.ui.legendMaxRange;

export const getSimulationMaxStepNumber =
  () =>
  ({ concepts }) =>
    concepts.ui.simulation.maxStepNumber;
export const getSimulationMaxTime =
  () =>
  ({ concepts }) =>
    concepts.ui.simulation.maxTime;
export const getSimulationStep =
  () =>
  ({ concepts }) =>
    concepts.ui.simulation.step;
export const getSimulationIsActive =
  () =>
  ({ concepts }) =>
    concepts.ui.simulation.isActive;
export const getSimulationPrinterSteps =
  () =>
  ({ concepts }) =>
    concepts.ui.simulation.printerSteps;
export const getTaskOperator =
  (conceptId, taskProgressId) =>
  ({ concepts }) => {
    const concept = concepts.data.concepts.find(({ id }) => id === conceptId);
    return concept?.operators?.find(({ id }) => id === taskProgressId);
  };
export const getConceptSelectedOperator =
  (conceptId) =>
  ({ concepts }) => {
    const concept = concepts.data.concepts.find(({ id }) => id === conceptId);

    return concept?.operators?.find(({ values }) =>
      values.some(({ id }) => id === concepts.ui.selectedOperatorOutputId),
    );
  };
export const getConceptSelectedOperatorValue =
  (conceptId) =>
  ({ concepts }) => {
    const concept = concepts.data.concepts.find(({ id }) => id === conceptId);

    const operator = concept?.operators?.find(({ values }) =>
      values.some(({ id }) => id === concepts.ui.selectedOperatorOutputId),
    );

    return operator?.values?.find(
      (value) => value.id === concepts.ui.selectedOperatorOutputId,
    );
  };
export const getTaskSelectedOperator =
  (conceptId, selectedOperatorId) =>
  ({ concepts }) => {
    const concept = concepts.data.concepts.find(({ id }) => id === conceptId);

    return concept?.operators?.find(
      (operator) => operator.id === selectedOperatorId,
    );
  };

export const getConceptById =
  (conceptId) =>
  ({ concepts }) =>
    concepts.data.concepts.find(({ id }) => id === conceptId);

export const getHiddenInputs =
  () =>
  ({ concepts }) =>
    concepts.ui.hiddenInputs;
export const getHiddenOutputs =
  () =>
  ({ concepts }) =>
    concepts.ui.hiddenOutputs;
export const getOperatorInputModifications =
  () =>
  ({ concepts }) =>
    concepts.ui.operatorInputModifications;
export const getDefaultOperators = (version) => (state) => {
  return state.concepts.data.defaultOperators[version] ?? emptyArr;
};
export const getOperatorDescriptorPopup =
  () =>
  ({ concepts }) =>
    concepts.ui.operatorDescriptorPopup;

export const getMissingInputOperators = ({ concepts }) =>
  concepts.ui.missingInputOperators;

export const getConcepts = ({ concepts }) => concepts.data.concepts;

export const getProjectInstructionsURL = ({ concepts }) =>
  concepts?.ui?.projectInstructionsURL;

export const getIsSharedWorkflow = (workflowId) => (state) => {
  const user = getCurrentUser()(state);
  const workflow = getWorkflow(workflowId)(state);

  const sameOrganizationUser =
    workflow?.organizationId === user?.organizationId;

  const worflowIsShared = workflow?.public && !sameOrganizationUser;

  return worflowIsShared;
};

export const getIsWorkflowComputed = (workflowId) => (state) => {
  const workflow = getWorkflow(workflowId)(state);
  const workflowModifications = getOperatorInputModifications()(state);

  const computedOperators = workflow?.operators?.filter(
    ({ computed }) => computed,
  );
  const computedOperatorIds = computedOperators?.map?.(({ id }) => id) || [];
  const workflowIsComputed =
    workflow?.operators?.length === computedOperatorIds?.length &&
    isEmpty(workflowModifications);

  return workflowIsComputed;
};

export const getIsMultiToolpathWorkflow = (workflowId) => (state) => {
  const workflow = getWorkflow(workflowId)(state);

  const toolpathOperators = workflow?.operators?.filter(({ name }) =>
    [
      TOOLPATH_GENERATOR_OPERATOR_NAME,
      MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
    ].includes(name),
  );
  const multiOperators = toolpathOperators?.length > 1;

  return multiOperators;
};

export const getIsWorkflowWithTemplate = (workflowId) => (state) => {
  const workflow = getWorkflow(workflowId)(state);

  const hasTemplate = workflow?.operators?.some(
    ({ templateId }) => !!templateId,
  );

  return hasTemplate;
};

export const getIsWorkflowDisabled = (workflowId) => (state) => {
  const areFetchesInProgress = getIsFetchesInProgress()(state);
  const workflowIsDisabled = getIsSharedWorkflow(workflowId)(state);
  const workflowIsComputing = getIsWorkflowComputing(state);
  const isFetchingOperators = isFetchInProgess('defaultOperators')(state);
  const isAddingOperator = isFetchInProgess('addOperator')(state);
  const reorderingOperators = isFetchInProgess('reorderingOperators')(state);

  return (
    workflowIsDisabled ||
    workflowIsComputing ||
    areFetchesInProgress ||
    isFetchingOperators ||
    isAddingOperator ||
    reorderingOperators
  );
};

export const getLastAddedOperatorId = ({ concepts }) =>
  concepts?.ui?.lastAddedOperatorId;

export const getIsWorkflowWithModifications = ({ concepts }) =>
  !!concepts?.ui?.operatorInputModifications?.length;

export const getToolpathSimulationVisibility = ({ concepts }) =>
  concepts?.ui?.toolpathSimulationIsVisible;

export const getDraggingOperatorId = ({ concepts }) =>
  concepts?.ui?.operatorsSelector?.draggingOperatorId;

export const getSceneSettings = ({ concepts }) => {
  const settingProps = pick(concepts.ui, [
    'isShowingDimensions',
    'isShowingOuterWall',
    'isShowingInnerWall',
    'isShowingSupport',
    'isShowingSupportInterface',
    'isShowingBrim',
    'isShowingSkin',
    'isShowingInfill',
    'isShowingMilling',
    'isShowingSeams',
    'isShowingSolid',
    'isShowingRotations',
    'isShowingVertices',
    'isShowingDirection',
    'lineData',
    'customLineData',
    'customLineDataSelection',
    'displayMode',
    'isShowingGrid',
    'isShowingRobot',
    'isShowingPrintingBed',
    'isShowingEnclosure',
    'isShowingWorkspace',
  ]);

  return settingProps;
};

export const getCameraPosition = ({ concepts }) => {
  const position = pick(concepts.ui, ['cameraX', 'cameraY', 'cameraZ']);

  return position;
};

export const getInvalidOperators = ({ concepts }) =>
  concepts?.ui?.invalidOperators || [];
