import 'three';
import fileDownload from 'js-file-download';
import { getConfig } from './AuthenticatedFetch.js';
import 'three/examples/jsm/loaders/OBJLoader.js';

import {
  CREATE_DESIGN,
  CREATE_DESIGN_SUCCEEDED,
  DISMISS_ERROR_DIALOG,
  UPLOADING_FILE,
  UPLOAD_STOPPED,
  DOWNLOAD_DESIGN,
  DOWNLOAD_DESIGN_SUCCEEDED,
  DOWNLOAD_DESIGN_FAILED,
  LOAD_MODEL,
  LOAD_MODEL_SUCCEEDED,
  RESET_DESIGN_METADATA,
} from '../constants/actionTypes.js';
import { showErrorDialog } from './errorActions.js';
import { getAllAllowedExtensions } from '../utils/model';
import { doUpload } from '@utils/filesUpload';

export const downloadDesign =
  (designURL, designName, onSuccess) => (dispatch) => {
    dispatch({
      type: DOWNLOAD_DESIGN,
    });
    return fetch(designURL)
      .catch((_e) => {
        dispatch({
          type: DOWNLOAD_DESIGN_FAILED,
        });
      })
      .then((response) => {
        dispatch({
          type: DOWNLOAD_DESIGN_SUCCEEDED,
        });
        if (response.status !== 200) {
          return dispatch(
            showErrorDialog(
              'Design download failed',
              'Something went wrong...',
            ),
          );
        } else {
          response.blob().then((data) => {
            fileDownload(data, designName);

            if (onSuccess) onSuccess();
          });
        }
      });
  };

export const startLoading3DModel = () => ({
  type: LOAD_MODEL,
});

export const stopLoading3DModel = () => ({
  type: LOAD_MODEL_SUCCEEDED,
});

export const resetDesignMetadata = () => ({
  type: RESET_DESIGN_METADATA,
});

function uploadDesign(file, filename, filetype, workspaceId, dispatch) {
  const form = new FormData();
  form.set('file', file);
  form.set('filename', filename);
  form.set('filetype', filetype);
  form.set('workspaceId', workspaceId);

  return doUpload(form, getConfig(), 'designs', UPLOADING_FILE, dispatch)
    .then((response) => {
      if (response.status === 201) {
        return JSON.parse(response.response);
      } else if (response.status === 409) {
        dispatch({
          type: UPLOAD_STOPPED,
        });
        dispatch(
          showErrorDialog(
            'Design creation failed',
            `The design ${filename} already exists`,
          ),
        );
      } else {
        dispatch({
          type: UPLOAD_STOPPED,
        });
        dispatch(
          showErrorDialog('Design creation failed', 'Something went wrong...'),
        );
      }

      return null;
    })
    .catch(() => {
      dispatch({
        type: UPLOAD_STOPPED,
      });
      dispatch(
        showErrorDialog('Design creation failed', 'Something went wrong...'),
      );
    })
    .then((createdDesignJson) => {
      if (createdDesignJson) {
        dispatch({
          type: CREATE_DESIGN_SUCCEEDED,
          design: createdDesignJson,
        });
      }
      return createdDesignJson;
    });
}

export const createDesign =
  (filename, fileReference, workspaceId) => (dispatch) => {
    dispatch({
      type: CREATE_DESIGN,
    });
    const re = /(?:\.([^.]+))?$/;
    const filetype = re.exec(filename)[1].toLowerCase();
    if (!getAllAllowedExtensions().includes(filetype)) {
      dispatch({
        type: UPLOAD_STOPPED,
      });
      return dispatch(
        showErrorDialog(
          'Design creation failed',
          'This filetype is not supported',
        ),
      );
    }
    return uploadDesign(
      fileReference,
      filename,
      filetype,
      workspaceId,
      dispatch,
    );
  };

export const dismissErrorDialog = () => ({
  type: DISMISS_ERROR_DIALOG,
});
