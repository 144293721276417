import styled, { css } from 'styled-components';
import IconComponent from '@components/1-atoms/SpriteSVG';
import Text from '@components/1-atoms/Text';
import { Wrapper as Button } from '@components/1-atoms/Button/Button.styled';
import {
  ACTION_FOOTER_VARIANT_FULL_WIDTH,
  ACTION_FOOTER_VARIANT_DEFAULT,
  ACTION_FOOTER_MESSAGE_VARIANT_CAUTION,
} from './ActionFooter';
import { when, flexCenterVertical } from '@stylesheets/helpers';

export const Actions = styled.div`
  display: flex;
  gap: 8px;

  ${Button} {
    min-width: min-content;
  }
`;

export const Wrapper = styled.div`
  gap: 8px;

  ${({ variant, withDivider, theme: { spacing, colors } }) => css`
    background-color: ${colors.surfaceContainerLow};
    padding: ${spacing.level6};

    ${when(
      variant === ACTION_FOOTER_VARIANT_DEFAULT,
      `
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: 'message actions';

        ${Actions} {
          grid-area: actions;
          align-items: flex-start;
        }
      `,
    )}

    ${when(
      variant == ACTION_FOOTER_VARIANT_FULL_WIDTH,
      `
        ${flexCenterVertical}}

        ${Actions} {
          width: 100%;
        }
      `,
    )}

    ${when(
      withDivider,
      `
        border-top: 1px solid ${colors.outlineVariant};
      `,
    )}
  `}
`;

export const MessageContainer = styled.div`
  grid-area: message;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  word-break: break-all;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level3} 0;
  `}
`;

export const Message = styled(Text)`
  place-self: center;
`;

export const Icon = styled(IconComponent)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${({ messageVariant, theme: { colors } }) => css`
    fill: ${colors.secondary};

    ${when(
      messageVariant === ACTION_FOOTER_MESSAGE_VARIANT_CAUTION,
      `
        fill: ${colors.caution};
      `,
    )}
  `}
`;
