import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { omit } from 'lodash';
import useActiveItems from '@app/hooks/useActiveItems';
import useOperator from '@hooks/operators/useOperator';
import {
  CHECKBOX_FIELD_TYPES,
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
  INPUT_FIELD_TYPES,
} from '@constants/operatorFieldTypes';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import SettingTextField from '@components/2-molecules/SettingTextField';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Wrapper } from './WorkflowReadOnlyOperatorFields.styled';
import ReadOnlyCanvasSelectionChip from '@app/components/ReadOnlyCanvasSelectionChip';
import useCanvasSelection from '@app/hooks/canvasselection/useCanvasSelection';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import { OPERATOR_CATEGORIES } from '@constants/featureFlagConstants';

const WorkflowReadOnlyOperatorFields = ({ operator = {} }) => {
  const intl = useIntl();
  const isOperatorCategoriesEnabled = useFeatureFlagValue(OPERATOR_CATEGORIES);

  const { toggleActiveItems, isItemActive } = useActiveItems();
  const {
    getOperatorVisibleInputs,
    getOperatorFieldOptions,
    getCategorizedVisibleOperatorInputs,
  } = useOperator();

  const { doesFieldSupportCanvasSelection, getReferencedCanvasSelectionField } =
    useCanvasSelection();

  const operatorValues = useMemo(
    () => getOperatorVisibleInputs(operator),
    [getOperatorVisibleInputs, operator],
  );

  const operatorInputCategories = getCategorizedVisibleOperatorInputs(
    operator,
    { comparisonAware: true },
  );
  const operatorDefaultInputCategory = operatorInputCategories?.default || [];
  const operatorCategoryInputs = omit(operatorInputCategories, 'default');

  const getDropDownPlaceholder = useCallback(
    (fieldInput = {}) => {
      const { type } = fieldInput;
      let placeholder = intl.formatMessage({
        id: 'editorpanel.selector.select_value',
        defaultMessage: 'Select value',
      });

      if (DROP_DOWN_INPUT_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_input',
          defaultMessage: 'Select input',
        });
      }

      if (DROP_DOWN_FILE_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_file',
          defaultMessage: 'Select file',
        });
      }

      if (DROP_DOWN_DATA_FIELD_TYPES.includes(type)) {
        placeholder = intl.formatMessage({
          id: 'editorpanel.selector.select_data',
          defaultMessage: 'Select data',
        });
      }

      return placeholder;
    },
    [intl],
  );

  const renderDropDownField = useCallback(
    (fieldInput = {}) => {
      const fieldName = fieldInput?.name || '';
      const options = getOperatorFieldOptions(operator, fieldInput);
      const selectedOption = options.find(
        (option) => option.value === fieldInput?.value,
      ) || { label: '', value: '' };

      return (
        <SettingDropDown
          label={fieldName}
          dropDownField={{
            disabled: true,
            value: selectedOption?.label,
            placeholder: getDropDownPlaceholder(fieldInput),
            diffRemoved: fieldInput?.diffRemoved,
            diffAdded: fieldInput?.diffAdded,
            diffModified: fieldInput?.diffModified,
            fullWidthDropDownMenu: false,
            transparentWhenDisabled: false,
          }}
        />
      );
    },
    [getDropDownPlaceholder, getOperatorFieldOptions, operator],
  );

  const renderCanvasSelectionField = useCallback(
    (fieldInput = {}, referencedInputs = {}) => {
      return (
        <ReadOnlyCanvasSelectionChip
          fieldInput={fieldInput}
          referencedShapeIdField={referencedInputs?.idInput}
          renderDropDownField={renderDropDownField}
        />
      );
    },
    [renderDropDownField],
  );

  const renderCanvasSelectionDropDownField = useCallback(
    (fieldInput = {}) => {
      const referencedInputs = getReferencedCanvasSelectionField(
        fieldInput,
        operator,
      );
      return renderCanvasSelectionField(fieldInput, referencedInputs);
    },
    [renderCanvasSelectionField, operator, getReferencedCanvasSelectionField],
  );

  const renderInputField = useCallback(
    (fieldInput = {}) => {
      const fieldName = fieldInput?.name || '';
      const fieldInputType = fieldInput?.type || '';
      const intlId = `operatorinput.placeholder.${fieldInputType?.toLowerCase()}`;

      return (
        <SettingTextField
          label={fieldName}
          field1={{
            disabled: true,
            type: fieldInputType,
            placeholder: intl.formatMessage({
              id: intlId,
              defaultMessage: fieldInputType,
            }),
            value: fieldInput?.value,
            diffRemoved: fieldInput?.diffRemoved,
            diffAdded: fieldInput?.diffAdded,
            diffModified: fieldInput?.diffModified,
            transparentWhenDisabled: false,
          }}
        />
      );
    },
    [intl],
  );

  const renderCheckboxField = useCallback((fieldInput = {}) => {
    const fieldName = fieldInput?.name || '';
    const checked = fieldInput.value === 'true';

    return (
      <SettingCheckbox
        label={fieldName}
        checkbox={{
          disabled: true,
          checked: checked,
          diffRemoved: fieldInput?.diffRemoved,
          diffAdded: fieldInput?.diffAdded,
          diffModified: fieldInput?.diffModified,
          transparentWhenDisabled: false,
        }}
      />
    );
  }, []);

  const renderField = useCallback(
    (fieldInput = {}) => (
      <Fragment key={fieldInput.id}>
        {DROP_DOWN_FIELD_TYPES.includes(fieldInput?.type) &&
          (doesFieldSupportCanvasSelection(operator, fieldInput)
            ? renderCanvasSelectionDropDownField(fieldInput)
            : renderDropDownField(fieldInput))}

        {INPUT_FIELD_TYPES.includes(fieldInput?.type) &&
          renderInputField(fieldInput)}

        {CHECKBOX_FIELD_TYPES.includes(fieldInput?.type) &&
          renderCheckboxField(fieldInput)}
      </Fragment>
    ),
    [
      doesFieldSupportCanvasSelection,
      operator,
      renderCanvasSelectionDropDownField,
      renderDropDownField,
      renderInputField,
      renderCheckboxField,
    ],
  );

  return (
    <Wrapper>
      {isOperatorCategoriesEnabled ? (
        <>
          {operatorDefaultInputCategory.map(renderField)}

          {Object.entries(operatorCategoryInputs).map(
            ([categoryName, inputs]) => (
              <SettingsCategory
                key={categoryName}
                dataTestId={`workflow-operator-${operator.id}-category-${categoryName}`}
                title={categoryName}
                withDividerTop
                subcategory
                expand={isItemActive(categoryName)}
                onHeaderClick={() => toggleActiveItems([categoryName])}
              >
                {inputs.map(renderField)}
              </SettingsCategory>
            ),
          )}
        </>
      ) : (
        operatorValues.map(renderField)
      )}
    </Wrapper>
  );
};

WorkflowReadOnlyOperatorFields.propTypes = {
  operator: PropTypes.object,
};

export default WorkflowReadOnlyOperatorFields;
