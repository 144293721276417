import { FeatureFlagContext } from '@app/containers/Providers/FeatureFlagProvider';
import { useMemo, useContext } from 'react';

/**
 * A hook that, given a feature flag name and optional default value, returns
 * the value of the feature flag for the current user.
 *
 * Note: Use of this hook will cause the flag to be evaluated immediately
 * on mount. Use useFeatureFlags to control the evaluation time more specifically.
 * @param {string} featureEnum the feature flag enum
 * @returns the flag value: true or false
 */
export default function useFeatureFlagValue(featureEnum) {
  const client = useContext(FeatureFlagContext);

  return useMemo(() => {
    if (!client) return featureEnum.defaultValue;
    return client.checkGate(featureEnum.flagName);
  }, [client, featureEnum.defaultValue, featureEnum.flagName]);
}
